import type { Directive } from 'vue';

const setFocus = (el: HTMLInputElement) => {
  el.focus();
};

const focus: Directive<HTMLInputElement> = {
  mounted: setFocus,
  updated: setFocus,
};

export default focus;
