import type { RouteRecordRaw } from 'vue-router';

import { PERMISSION } from '~/constants';

import { makeKeyBuilder, makeOrganisationRoute } from '~/features/useRouteMeta';

const OrganisationShipmentsNotifiedIndex = () =>
  import('~/pages/OrganisationShipmentsNotifiedIndex.vue');
const OffsetOrganisationShipmentsEmissions = () =>
  import('~/pages/OffsetOrganisationShipmentsEmissions.vue');
const OffsetOrganisationShipmentsEmissionsIndex = () =>
  import('~/pages/OffsetOrganisationShipmentsEmissionsIndex.vue');
const OffsetOrganisationShipmentsEmissionsOrdersIndex = () =>
  import('~/pages/OffsetOrganisationShipmentsEmissionsOrdersIndex.vue');
const OrganisationShipmentsIndex = () =>
  import('~/pages/OrganisationShipmentsIndex.vue');
const OrganisationShipmentsSharedShipment = () =>
  import('~/pages/OrganisationShipmentsSharedShipment.vue');
const OrganisationShipmentsVoyageShow = () =>
  import('~/pages/OrganisationShipmentsVoyageShow.vue');
const OrganisationShipmentsShow = () =>
  import('~/pages/OrganisationShipmentsShow.vue');
const OrganisationShipmentsShowDetails = () =>
  import('~/pages/OrganisationShipmentsShowDetails.vue');
const OrganisationShipmentsShowDocuments = () =>
  import('~/pages/OrganisationShipmentsShowDocuments.vue');
const OrganisationShipmentsShowMilestones = () =>
  import('~/pages/OrganisationShipmentsShowMilestones.vue');
const OrganisationShipmentsShowPortContacts = () =>
  import('~/pages/OrganisationShipmentsShowPortContacts.vue');

export const organisationShipmentsRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    path: '/shipments-offset',
    component: OffsetOrganisationShipmentsEmissions,
    permission: PERMISSION.SHIPMENT_VIEW_OWN,
    keyBuilder: makeKeyBuilder('OffsetOrganisationShipmentsEmissions'),
    children: [
      {
        name: 'OffsetOrganisationShipmentsEmissions',
        path: '',
        redirect: { name: 'OffsetOrganisationShipmentsEmissionsIndex' },
      },
      {
        name: 'OffsetOrganisationShipmentsEmissionsIndex',
        path: 'all',
        component: OffsetOrganisationShipmentsEmissionsIndex,
        props: true,
      },
      {
        name: 'OffsetOrganisationShipmentsEmissionsOrdersIndex',
        path: 'in-drafts',
        component: OffsetOrganisationShipmentsEmissionsOrdersIndex,
        props: true,
      },
    ],
  }),
  makeOrganisationRoute({
    name: 'OrganisationShipmentsIndex',
    path: '/shipments',
    component: OrganisationShipmentsIndex,
    permission: PERMISSION.SHIPMENT_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationShipmentsNotifiedIndex',
    path: '/my-shipments',
    component: OrganisationShipmentsNotifiedIndex,
    permission: PERMISSION.SHIPMENT_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationShipmentsVoyageShow',
    path: '/voyages/:voyageId',
    component: OrganisationShipmentsVoyageShow,
    permission: PERMISSION.SHIPMENT_VIEW_OWN,
    props: (route) => ({
      voyageId: route.params.voyageId as string,
      from: route.query.from as string,
    }),
  }),
  makeOrganisationRoute({
    path: '/shipments/:bookingNo/lp/:loadPortId/dp/:dischargePortId',
    component: OrganisationShipmentsShow,
    permission: PERMISSION.SHIPMENT_VIEW_OWN,
    keyBuilder: makeKeyBuilder('OrganisationShipmentsShow'),
    props: (route) => ({
      bookingNo: route.params.bookingNo as string,
      loadPortId: route.params.loadPortId as string,
      dischargePortId: route.params.dischargePortId as string,
      from: route.query.from as string,
    }),
    children: [
      {
        name: 'OrganisationShipmentsShow',
        path: '',
        redirect: { name: 'OrganisationShipmentsShowDetails' },
      },
      {
        name: 'OrganisationShipmentsShowDetails',
        path: 'details',
        component: OrganisationShipmentsShowDetails,
        props: true,
      },
      {
        name: 'OrganisationShipmentsShowMilestones',
        path: 'milestones',
        component: OrganisationShipmentsShowMilestones,
        props: true,
      },
      {
        name: 'OrganisationShipmentsShowDocuments',
        path: 'documents',
        component: OrganisationShipmentsShowDocuments,
        props: true,
      },
      {
        name: 'OrganisationShipmentsShowPortContacts',
        path: 'ports-contacts',
        component: OrganisationShipmentsShowPortContacts,
        props: true,
      },
    ],
  }),
  makeOrganisationRoute({
    name: 'OrganisationShipmentsSharedShipment',
    path: '/shipments/:bookingNo/lp/:loadPortId/dp/:dischargePortId/preview',
    component: OrganisationShipmentsSharedShipment,
    permission: PERMISSION.SHIPMENT_VIEW_OWN,
    layout: null,
    props: (route) => ({
      bookingNo: route.params.bookingNo as string,
      loadPortId: route.params.loadPortId as string,
      dischargePortId: route.params.dischargePortId as string,
    }),
  }),
];
