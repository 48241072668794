import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

const logout = `${baseEndpoint}/api/logout`;

export default [
  http.post(logout, async () => {
    return HttpResponse.json({
      message: 'success message',
    });
  }),
  http.post(`${baseEndpoint}/api/confirm-email/:token*`, async () => {
    return HttpResponse.json(
      { type: 'cargo_owner' },
      {
        status: 200,
      },
    );
  }),
] satisfies HttpHandler[];
