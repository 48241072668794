<script lang="ts" setup name="ComponentErrorCard">
import BaseButton from '~/components/BaseButton.vue';
import BaseCardServerError from '~/components/BaseCardServerError.vue';

import type { BaseCardStateSpacingType } from '~/types';

const { spacing = 'card-lg' } = defineProps<{
  spacing?: BaseCardStateSpacingType;
  styled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'retry'): void;
}>();
</script>

<template>
  <BaseCardServerError
    title="We Are Sorry. Something Went Wrong."
    message="Try to refresh the page."
    textSize="card-text-subtitle-1"
    illustration="chart-error"
    illustrationSize="card-img-sm"
    :spacing="spacing"
    :styled="styled"
  >
    <BaseButton variant="btn-tertiary" @click="emit('retry')">
      refresh
    </BaseButton>
  </BaseCardServerError>
</template>
