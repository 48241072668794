<script setup lang="ts" name="FilterMenuSelectMultipleTrades">
import { useVModel } from '@vueuse/core';

import { useFilterMenu, useMultipleSelect } from '~/features/useFilterMenu';

import FilterCheckboxInput from '~/components/FilterCheckboxInput.vue';
import FilterMenu from '~/components/FilterMenu.vue';
import FilterMenuOptions from '~/components/FilterMenuOptions.vue';
import SearchField from '~/components/SearchField.vue';

import type { IconType, IStatisticsTradeLane } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue: IStatisticsTradeLane[];
    options: IStatisticsTradeLane[];
    label: string;
    icon?: IconType;
    searchable?: boolean;
    placeholder?: string;
    sorted?: boolean;
    displayKey?: string;
  }>(),
  {
    displayKey: 'name',
    placeholder: 'Search',
  },
);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'selected'): void;
  (e: 'update:modelValue'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const { search, menuOptions, onUpdate, onClickOutside } =
  useFilterMenu<IStatisticsTradeLane>(props);

const { isActive, isOptionSelected, getLabel } = useMultipleSelect();
</script>

<template>
  <FilterMenu
    :icon="icon"
    :label="getLabel(props.label, props.options, inputValue)"
    :highlight="isOptionSelected(props.options, inputValue)"
    @click-outside="onClickOutside(() => emit('close'))"
  >
    <template #default="{ close }">
      <SearchField
        v-if="searchable"
        v-model="search"
        focus
        size="small"
        :placeholder="placeholder"
      />

      <FilterMenuOptions :hasOptions="menuOptions.length > 0">
        <FilterCheckboxInput
          v-for="option in menuOptions"
          :key="option.id"
          v-model="inputValue"
          :native-value="option"
          :title="option.name"
          :active="isActive(option, inputValue)"
          @select="() => onUpdate(close)"
        >
          <p
            data-test="tradelane-name"
            class="text-secondary-9 group-hover:text-primary-8"
          >
            {{ option.name }}
          </p>
          <p class="truncate text-charcoal-6 group-hover:text-primary-8">
            {{ option.continents }}
          </p>
        </FilterCheckboxInput>
      </FilterMenuOptions>
    </template>
  </FilterMenu>
</template>
