import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;
const base = `${baseEndpoint}/api/customer/organisation/1/charts/laytime`;

export const filters = `${base}/filters`;
export const table = `${base}/table`;

export default [
  http.get(`${base}/1`, async () => {
    return HttpResponse.json({
      data: {
        tradeDeskEmail: 'myemail@gmail.com',
        voyage: {
          name: 'Great Woods',
          port: 'Singapore',
          reasonForCall: 'L',
          trade: 'ESA',
        },
        laytime: {
          totalLaytime: 1,
          totalAging: 2,
          status: {
            id: 'in_progress',
            name: 'Calculation in progress',
          },
          sentAt: '20 Oct, 2022',
        },
      },
      table: [
        {
          date: '20 Oct, 2022',
          status: {
            id: 'in_progress',
            name: 'Calculation in progress',
          },
        },
        {
          date: '20 Oct, 2022',
          status: {
            id: 'settled',
            name: 'Settled',
          },
        },
      ],
    });
  }),
  http.get(filters, async () => {
    return HttpResponse.json({
      data: {
        tradelanes: [
          {
            id: 'FE-WSA',
            name: 'FE-WSA',
          },
        ],
        statuses: [
          {
            id: 'Calculations in Progress',
            name: 'Calculations in Progress',
          },
        ],
      },
    });
  }),
  http.get(table, async () => {
    return HttpResponse.json({
      data: {
        data: [
          {
            id: '123',
            vesselVoyage: 'Great Woods',
            trade: 'ESA',
            port: 'Singapore',
            reasonForCall: 'Laytime',
            status: {
              id: 'calculationInProgress',
              name: 'Calculation in progress',
            },
            totalAging: 1,
            totalLaytime: 2,
          },
        ],
        meta: {
          total: 1,
          per_page: 10,
          last_page: 1,
          current_page: 1,
          from: 1,
          to: 1,
        },
        links: {
          first: '',
          last: '',
          prev: '',
          next: '',
        },
      },
    });
  }),
  http.get(base, async () => {
    return HttpResponse.json({
      data: {
        widgets: [
          {
            type: 'amountByStatus',
            data: [
              {
                label: 'Total Laytime',
                value: 1102,
              },
              {
                label: 'Under Query',
                value: 102,
              },
            ],
          },
          {
            type: 'avgAgingByDays',
            value: 20303,
          },
          {
            type: 'laytimeByCount',
            value: 1203,
          },
          {
            type: 'amountByTrade',
            data: [
              {
                label: 'ESA-FE',
                value: 1102,
              },
              {
                label: 'ENA-ESA',
                value: 102,
              },
            ],
          },
          {
            type: 'countByTrade',
            data: [
              {
                label: 'ESA-FE',
                value: 79,
                max: 121,
              },
              {
                label: 'ENA-FE',
                value: 65,
                max: 100,
              },
            ],
          },
        ],
      },
    });
  }),
] satisfies HttpHandler[];
