<script setup lang="ts" name="TheSnackbarPosition">
import { computed } from 'vue';

import type { ISnackbar } from '~/types';

const { snackbar } = defineProps<{
  snackbar: ISnackbar;
}>();

const transition = computed(() =>
  snackbar.withTransition ? 'snackbar-fade' : '',
);
</script>

<template>
  <Transition :name="transition" mode="out-in">
    <div
      v-if="snackbar.visible"
      data-test="snackbar"
      class="fixed bottom-[50px] left-1/2 z-50 -translate-x-1/2 transform md:left-[71px] md:-translate-x-0"
    >
      <slot />
    </div>
  </Transition>
</template>
