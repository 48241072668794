<script setup lang="ts" name="VesselsIndex">
import { computed, h } from 'vue';
import { useRouter } from 'vue-router';
import { useVModel } from '@vueuse/core';

import { formatNumber } from '~/features/useNumbers';
import type { useService } from '~/features/useService.js';

import BasePage from '~/components/BasePage.vue';
import DataTable from '~/components/DataTable.vue';
import DataTableActions from '~/components/DataTableActions.vue';
import DataTableLoading from '~/components/DataTableLoading.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import VesselsLayout from '~/components/VesselsLayout.vue';

import type { IDataTableSettings, IVesselsShowTableRow } from '~/types';

import TableColumnNumberUnit from './TableColumnNumberUnit.vue';

const props = defineProps<{
  modelValue: string | null;
  serviceState: ReturnType<typeof useService>;
  vessels: IVesselsShowTableRow[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const router = useRouter();
const inputValue = useVModel(props, 'modelValue', emit);

const tableSettings = computed<IDataTableSettings<IVesselsShowTableRow>>(
  () => ({
    editable: false,
    pagination: true,
    onRowClick: (row: IVesselsShowTableRow) => {
      router.push(row.path);
    },
    columns: [
      {
        header: 'Vessel Name',
        key: 'name',
        visible: true,
      },
      {
        header: 'IMO',
        key: 'imo',
        align: 'right',
        visible: true,
      },
      {
        header: 'DWT',
        key: 'dwt',
        align: 'right',
        render: () => h(TableColumnNumberUnit, { unit: 'MT' }),
      },
      {
        header: 'GRT',
        key: 'grt',
        align: 'right',
        valueFormatter: (value: number) => formatNumber(value),
      },
      {
        header: 'NRT',
        key: 'nrt',
        align: 'right',
        valueFormatter: (value: number) => formatNumber(value),
      },
      {
        header: 'LOA',
        key: 'loa',
        align: 'right',
        render: () => h(TableColumnNumberUnit, { unit: 'm', decimal: 1 }),
      },
      {
        header: 'Bale Capacity',
        key: 'bale',
        align: 'right',
        render: () => h(TableColumnNumberUnit, { unit: 'cbm' }),
      },
      {
        header: 'Crane Capacity',
        key: 'craneCapacity',
        align: 'right',
      },
      {
        header: 'Draft Summer',
        key: 'draftSummer',
        align: 'right',
        render: () => h(TableColumnNumberUnit, { unit: 'm', decimal: 2 }),
      },
    ],
    actions: (row?: IVesselsShowTableRow) => [
      {
        key: 'view',
        icon: 'mdi:eye',
        dataTestId: 'row-view',
        tooltipDescription: 'View',
        variant: 'btn-tertiary',
        to: row?.path,
      },
    ],
  }),
);

const onClearSearch = () => {
  inputValue.value = '';
};
</script>

<template>
  <BasePage title="Vessels">
    <VesselsLayout>
      <template #search>
        <SearchField
          v-model="inputValue"
          placeholder="Search by the Vessel Name"
          focus
        />
      </template>

      <ServiceCard
        v-bind="serviceState"
        no-results-message="Please amend the search criteria or clear the search field to retrieve all available vessels."
        @clear="onClearSearch"
      >
        <DataTableLoading
          v-if="serviceState.stateIsLoading"
          :columns="tableSettings.columns.length"
        />
        <DataTable v-else :data="vessels" :settings="tableSettings">
          <template #actions="{ item }">
            <DataTableActions :actions="tableSettings.actions!(item)" />
          </template>
        </DataTable>
      </ServiceCard>
    </VesselsLayout>
  </BasePage>
</template>
