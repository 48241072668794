import type { LatLngTuple } from 'leaflet';
import { Control, featureGroup, geoJson, icon, LatLng, marker } from 'leaflet';

import type {
  IGeoJson,
  IGeoLocation,
  IVesselLocation,
  IVesselVoyageData,
} from '~/types';

import 'leaflet-fullscreen';

const getDistance = (start: number, end: number): number =>
  Math.abs(start - end);

const getMeridianLongitude = (wrap: boolean, lng: number) => {
  const AROUND_THE_WORLD = 360;
  const longitude = Number(lng);
  return wrap && longitude < 0 ? longitude + AROUND_THE_WORLD : longitude;
};

const getShouldWrapMeridian = (geoData: IGeoJson) => {
  const ANTIMERIDIAN = 180;
  const pathBounds = geoJson(geoData).getBounds();
  const east = pathBounds.getEast();
  const west = pathBounds.getWest();

  return getDistance(east, ANTIMERIDIAN) < 1 && west < 0;
};

const getGeoLayer = (wrap: boolean, geoData: IGeoJson) =>
  geoJson(geoData, {
    coordsToLatLng(coords) {
      const [longitude, latitude] = coords;

      return new LatLng(latitude, getMeridianLongitude(wrap, longitude));
    },
    style: {
      color: '#F3925C',
    },
  });

export const useLiveTracker = (data: IVesselVoyageData) => {
  const vesselVoyage = new VesselVoyage(data);
  const geoJson = vesselVoyage.getGeoJson();
  const wrap = geoJson ? getShouldWrapMeridian(geoJson) : false;

  const startMarker = marker(vesselVoyage.getLoadingPortLocation(wrap), {
    icon: icon({
      iconUrl: '/images/leaflet/start-marker.svg',
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    }),
  });

  const endMarker = marker(vesselVoyage.getDischargePortLocation(wrap), {
    icon: icon({
      iconUrl: '/images/leaflet/end-marker.svg',
      iconAnchor: [7, 21],
    }),
  });

  const leafLetMarkers = [startMarker, endMarker];

  const vesselLocation = vesselVoyage.getVesselLocation(wrap);

  if (vesselLocation !== null) {
    const currentLocationMarker = marker(vesselLocation, {
      icon: icon({
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        popupAnchor: [0, -10],
        iconUrl: '/images/leaflet/ship.svg',
      }),
    })
      .bindPopup(vesselVoyage.getVesselInfoHtml())
      .openPopup();

    leafLetMarkers.push(currentLocationMarker);
  }

  const fullScreen = new (Control as any).Fullscreen();

  return {
    fullScreen,
    geoJsonLayer: geoJson ? getGeoLayer(wrap, geoJson) : null,
    featureGroup: featureGroup(leafLetMarkers),
  };
};

export class VesselVoyage {
  vessel?: IVesselLocation;
  loadingPort: IGeoLocation;
  dischargePort: IGeoLocation;
  voyageGeoJson?: IGeoJson;

  constructor({
    vessel,
    loadingPort,
    dischargePort,
    voyageGeoJson,
  }: IVesselVoyageData) {
    this.vessel = vessel;
    this.loadingPort = loadingPort;
    this.dischargePort = dischargePort;
    this.voyageGeoJson = voyageGeoJson;
  }

  getLoadingPortLocation(wrap: boolean): LatLngTuple {
    const { lat, lng } = this.loadingPort;
    return [lat, getMeridianLongitude(wrap, lng)];
  }

  getDischargePortLocation(wrap: boolean): LatLngTuple {
    const { lat, lng } = this.dischargePort;
    return [lat, getMeridianLongitude(wrap, lng)];
  }

  getVesselLocation(wrap: boolean): LatLngTuple | null {
    if (!this.vessel) return null;

    const { lat, lng } = this.vessel;
    return [lat, getMeridianLongitude(wrap, lng)];
  }

  getGeoJson() {
    return this.voyageGeoJson;
  }

  getVesselInfoHtml() {
    if (!this.vessel) return '';

    return `
    <div class="text-body-2">
      <strong>${this.vessel.name}</strong>
      <br><strong>Next Port:</strong> ${this.vessel.nextPortName}
      <br><strong>Next Port ETA:</strong> ${this.vessel.eta}
      <br><strong>Speed:</strong> ${this.vessel.speed}
    </div>`;
  }
}
