<script setup lang="ts" name="SailingScheduleMobileFiltersOverlay">
import { computed } from 'vue';
import { useVModels } from '@vueuse/core';

import { useSailingScheduleMobileFilters } from '~/features/useSailingScheduleMobileFilters';
import { useScreenStack } from '~/features/useScreenStack';
import { isEmpty } from '~/features/useUtilities';

import BaseOverlay from '~/components/BaseOverlay.vue';
import FilterMobile from '~/components/FilterMobile.vue';
import FiltersButtonMobile from '~/components/FiltersButtonMobile.vue';

import type {
  ISailingScheduleOption,
  ISailingScheduleQuery,
  IStatisticsTradeLane,
} from '~/types';

const props = defineProps<{
  query: ISailingScheduleQuery;
  overlay: boolean;
  tradelanes: IStatisticsTradeLane[];
  loadingPorts: ISailingScheduleOption[];
  dischargePorts: ISailingScheduleOption[];
  vessels: ISailingScheduleOption[];
}>();

const emit = defineEmits<{
  (e: 'update:query'): void;
  (e: 'update:overlay'): void;
  (e: 'applyTradesSelection'): void;
  (e: 'clearFilters'): void;
}>();

const { query, overlay } = useVModels(props, emit);

const {
  screenStack,
  screenIs,
  overlayVisible,
  currentScreen,
  popScreen,
  pushScreen,
} = useScreenStack<
  'filters' | 'loadPorts' | 'dischargePorts' | 'trades' | 'vessels'
>(
  [
    {
      name: 'Filter by',
      screen: 'filters',
    },
    {
      name: 'Load Port',
      screen: 'loadPorts',
    },
    {
      name: 'Discharge Port',
      screen: 'dischargePorts',
    },
    {
      name: 'Trade',
      screen: 'trades',
    },
    {
      name: 'Vessels',
      screen: 'vessels',
    },
  ],
  overlay,
);

const { activeQuery, applyQuery, resetQuery } =
  useSailingScheduleMobileFilters(query);

const getActionName = (name: string, count: number) =>
  count ? `${name} (${count})` : name;

const filtersActions = computed(() => [
  {
    name: getActionName('Trade', activeQuery.tradelanes.length),
    active: !isEmpty(activeQuery.tradelanes),
    click: () => pushScreen('trades'),
  },
  {
    name: getActionName('Load Port', activeQuery.loadingPorts.length),
    active: !isEmpty(activeQuery.loadingPorts),
    click: () => pushScreen('loadPorts'),
  },
  {
    name: getActionName('Discharge Port', activeQuery.dischargePorts.length),
    active: !isEmpty(activeQuery.dischargePorts),
    click: () => pushScreen('dischargePorts'),
  },
  {
    name: getActionName('Vessels', activeQuery.vessels.length),
    active: !isEmpty(activeQuery.vessels),
    click: () => pushScreen('vessels'),
  },
]);

const onApply = () => {
  applyQuery();
  popScreen();
};

const onBack = () => {
  resetQuery();
  popScreen();
};
</script>

<template>
  <BaseOverlay
    v-model="overlayVisible"
    :screen="currentScreen?.screen"
    :screenName="currentScreen?.name"
    :backTo="screenStack.length > 1 ? 'Filters' : 'Sailing Schedule'"
    @back="onBack"
  >
    <div v-if="screenIs('filters')">
      <div class="space-y-2">
        <FiltersButtonMobile
          v-for="action in filtersActions"
          :key="action.name"
          :active="action.active"
          @click="action.click"
        >
          {{ action.name }}
        </FiltersButtonMobile>
      </div>
    </div>

    <div v-if="screenIs('loadPorts')">
      <FilterMobile
        v-model="activeQuery.loadingPorts"
        :options="loadingPorts"
        @apply="onApply"
      />
    </div>

    <div v-if="screenIs('dischargePorts')">
      <FilterMobile
        v-model="activeQuery.dischargePorts"
        :options="dischargePorts"
        @apply="onApply"
      />
    </div>

    <div v-if="screenIs('vessels')">
      <FilterMobile
        v-model="activeQuery.vessels"
        :options="vessels"
        @apply="onApply"
      />
    </div>

    <div v-if="screenIs('trades')">
      <FilterMobile
        v-model="activeQuery.tradelanes"
        :options="tradelanes"
        @apply="onApply"
      >
        <template #default="{ item }">
          <p
            data-test="tradelane-name"
            class="text-secondary-9 group-hover:text-primary-8"
          >
            {{ item.name }}
          </p>

          <p class="truncate text-charcoal-6 group-hover:text-primary-8">
            {{ item.continents }}
          </p>
        </template>
      </FilterMobile>
    </div>
  </BaseOverlay>
</template>
