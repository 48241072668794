import type { InjectionKey, Ref } from 'vue';
import { provide } from 'vue';

import { useStrictInject } from '~/features/useStrictInject';

import type { IShipmentShow } from '~/types';

export const OrganisationIdKey: InjectionKey<string> =
  Symbol('OrganisationIdKey');
export const BookingNoKey: InjectionKey<string> = Symbol('BookingNoKey');
export const LoadPortIdKey: InjectionKey<string> = Symbol('LoadPortIdKey');
export const DischargePortIdKey: InjectionKey<string> =
  Symbol('DischargePortIdKey');

export const ShipmentKey: InjectionKey<Ref<IShipmentShow | undefined>> =
  Symbol('ShipmentKey');

export const provideOrganisationId = (organisationId: string) =>
  provide(OrganisationIdKey, organisationId);
export const injectOrganisationId = () => useStrictInject(OrganisationIdKey);

export const provideShipment = (shipment: Ref<IShipmentShow | undefined>) =>
  provide(ShipmentKey, shipment);
export const injectShipment = () => useStrictInject(ShipmentKey);
export const injectBookingNo = () => useStrictInject(BookingNoKey);
export const injectLoadPortId = () => useStrictInject(LoadPortIdKey);
export const injectDischargePortId = () => useStrictInject(DischargePortIdKey);

export const injectOrganisationShipmentProps = () => ({
  organisationId: injectOrganisationId(),
  bookingNo: injectBookingNo(),
  loadPortId: injectLoadPortId(),
  dischargePortId: injectDischargePortId(),
});

export const provideOrganisationShipment = ({
  organisationId,
  bookingNo,
  loadPortId,
  dischargePortId,
}: {
  organisationId: OrganisationId;
  bookingNo: string;
  loadPortId: string;
  dischargePortId: string;
}) => {
  provideOrganisationId(organisationId);
  provide(BookingNoKey, bookingNo);
  provide(LoadPortIdKey, loadPortId);
  provide(DischargePortIdKey, dischargePortId);
};
