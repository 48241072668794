<script setup lang="ts" name="NavBarSidebar">
import Sidebar from '~/components/Sidebar.vue';

defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{ setOpen: [value: boolean] }>();
</script>

<template>
  <div
    class="sticky bottom-0 top-0 z-10 flex-shrink-0 bg-navy-blue-4"
    :class="{
      'hidden lg:block': !isOpen,
      block: isOpen,
    }"
  >
    <div
      v-if="isOpen"
      class="fixed inset-0 z-10 bg-black opacity-75 lg:hidden"
      @click.stop="emit('setOpen', false)"
    />

    <Sidebar :isOpen="isOpen" @close="emit('setOpen', false)" />
  </div>
</template>
