import type { RouteLocationNormalized } from 'vue-router';

import { useOrganisationsStore, useProfileStore } from '~/store';

import { useGooglePageView } from '~/features/useAnalytics';
import { useBeforeResolveRoute } from '~/features/useBeforeResolveRoute';

import type { UserModule } from '~/types';

export const install: UserModule = ({ router }) => {
  router.beforeResolve(useBeforeResolveRoute);
  router.afterEach((to: RouteLocationNormalized) => {
    const profileStore = useProfileStore();
    const organisationsStore = useOrganisationsStore();
    const organisation = organisationsStore.getById(
      to.params.organisationId as string,
    );

    useGooglePageView({
      to,
      isAuthenticated: profileStore.isAuthenticated,
      isG2OEmployee: profileStore.isG2OEmployee,
      isPublicUser: profileStore.isPublic,
      name: organisation?.name,
    });

    return true;
  });
};
