import type { IFileDownloadResponse } from '~/types';

export const setDownloadAnchor = ({
  type,
  file,
  name,
}: IFileDownloadResponse) => {
  const linkSource = `data:${type};base64,${file}`;
  const downloadLink = document.createElement('a');
  downloadLink.download = name;
  downloadLink.href = linkSource;
  downloadLink.click();
};

export const formatFileSize = (size: number): string => {
  if (size <= 0) return '0 Bytes';

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(size) / Math.log(1024));

  return `${(size / Math.pow(1024, i)).toFixed(2)} ${units[i]}`;
};
