import type { RouteRecordRaw } from 'vue-router';

import { PERMISSION } from '~/constants';

import { makePrivateRoute } from '~/features/useRouteMeta';
import { getPublicSailingScheduleFilters } from '~/features/useSailingScheduleFilters';

import LayoutPublicUser from '~/components/LayoutPublicUser.vue';

import AuthProfileEdit from '~/pages/AuthProfileEdit.vue';
import CookiePolicy from '~/pages/CookiePolicy.vue';
import ProfileChangePassword from '~/pages/ProfileChangePassword.vue';
import PublicSailingScheduleIndex from '~/pages/PublicSailingScheduleIndex.vue';
import PublicUserCargoTracker from '~/pages/PublicUserCargoTracker.vue';
import PublicUserMyDetails from '~/pages/PublicUserMyDetails.vue';
import PublicUserProfile from '~/pages/PublicUserProfile.vue';
import PublicVesselsIndex from '~/pages/PublicVesselsIndex.vue';
import PublicVesselsShow from '~/pages/PublicVesselsShow.vue';
import SupportIndex from '~/pages/SupportIndex.vue';

export const publicUserRoutes: Readonly<RouteRecordRaw[]> = [
  makePrivateRoute({
    name: 'PublicSailingScheduleIndex',
    path: '/external/sailing-schedule',
    component: PublicSailingScheduleIndex,
    layout: LayoutPublicUser,
    beforeEnter: async () => {
      return await getPublicSailingScheduleFilters();
    },
  }),
  makePrivateRoute({
    name: 'PublicVesselsIndex',
    path: '/external/vessels',
    component: PublicVesselsIndex,
    layout: LayoutPublicUser,
    permission: PERMISSION.PUBLIC_VESSELS_VIEW_OWN,
  }),
  makePrivateRoute({
    name: 'PublicVesselsShow',
    path: '/external/vessels/:id/name/:name',
    component: PublicVesselsShow,
    layout: LayoutPublicUser,
    permission: PERMISSION.PUBLIC_VESSELS_VIEW_OWN,
    props: (route) => ({
      id: route.params.id as string,
      name: route.params.name as string,
    }),
  }),
  makePrivateRoute({
    name: 'PublicUserCookiePolicy',
    path: '/external/support/cookie-policy',
    component: CookiePolicy,
    layout: LayoutPublicUser,
  }),
  makePrivateRoute({
    name: 'PublicUserCargoTracker',
    path: '/external/cargo-tracker',
    component: PublicUserCargoTracker,
    layout: LayoutPublicUser,
  }),
  makePrivateRoute({
    name: 'PublicSupportIndex',
    path: '/external/support',
    component: SupportIndex,
    layout: LayoutPublicUser,
  }),
  makePrivateRoute({
    path: '/external/profile',
    component: PublicUserProfile,
    layout: LayoutPublicUser,
    children: [
      {
        path: '',
        name: 'PublicUserProfile',
        redirect: { name: 'PublicUserMyDetails' },
      },
      {
        name: 'PublicUserMyDetails',
        path: 'details',
        component: PublicUserMyDetails,
        props: true,
      },
      {
        name: 'PublicUserChangePassword',
        path: 'password',
        component: ProfileChangePassword,
        props: true,
      },
    ],
  }),
  makePrivateRoute({
    name: 'PublicProfileEdit',
    path: '/external/profile/edit',
    component: AuthProfileEdit,
    layout: LayoutPublicUser,
  }),
];
