import { ORG_FEATURES } from '~/constants';

import { featureIsEnabled } from '~/features/useFeatures';
import { hasOneOfPermissions } from '~/features/usePermissions';

import G2oTourFinish from '~/components/G2oTourFinish.vue';
import G2oTourStart from '~/components/G2oTourStart.vue';
import G2oTourStep from '~/components/G2oTourStep.vue';
import G2oTourSwitchOrganisation from '~/components/G2oTourSwitchOrganisation.vue';

import type { IG2OTourStep, IOrganisation, IOrgFeature } from '~/types';

const OFFSET = {
  SIDEBAR: [0, -50],
  SUPPORT: [20, -60],
  USER_MENU: [20, -30],
} as const;

const onlyVisible = (step: IG2OTourStep) => step.visible !== false;

interface IUseCustomerStepsParams {
  isG2OEmployee: boolean;
  kpisAreVisible: boolean;
  organisation?: IOrganisation | null;
}

export const useCustomerSteps = ({
  isG2OEmployee,
  kpisAreVisible,
  organisation,
}: IUseCustomerStepsParams): IG2OTourStep[] => {
  const featureIsOn = (feature: IOrgFeature) =>
    featureIsEnabled(feature, organisation);

  const steps: IG2OTourStep[] = [
    {
      visible: !isG2OEmployee,
      component: G2oTourStart,
      id: 'start',
      type: 'dialog',
    },
    {
      visible: !isG2OEmployee,
      id: 'organisation-switcher',
      title: 'Organisation Switcher:',
      description:
        'Select Organisation of interest to see information related to the selected Organisation.',
      component: G2oTourStep,
      selector: 'organisation-select',
      offset: OFFSET.SIDEBAR,
    },
    {
      id: 'home',
      title: 'Home:',
      description: isG2OEmployee
        ? 'View recent activity and quick links of selected Organisation.'
        : 'View your recent shipments and find quick links.',
      component: G2oTourStep,
      selector: 'home-nav-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      visible: featureIsOn(ORG_FEATURES.SAILING_SCHEDULE),
      id: 'sailing-schedule',
      title: 'Sailing Schedule:',
      description: isG2OEmployee
        ? 'View G2 Ocean Sailing Schedule.'
        : 'Access G2 Ocean Sailing Schedule and plan your shipments.',
      component: G2oTourStep,
      selector: 'sailing-schedule-nav-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      visible: featureIsOn(ORG_FEATURES.BOOKINGS),
      id: 'cargo-bookings',
      title: 'Cargo Bookings:',
      description: isG2OEmployee
        ? 'Receive cargo declarations. Track changes, and status and communicate with the Customer.'
        : 'Declare your cargo to G2 Ocean. Track changes, and status and communicate with our Chartering team.',
      component: G2oTourStep,
      selector: 'cargo-bookings-nav-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      id: 'shipments',
      title: 'Shipments:',
      description: isG2OEmployee
        ? 'Find and track shipments and access cargo documentation of selected Organisation.'
        : 'Find and track your shipments and access cargo documentation.',
      component: G2oTourStep,
      selector: 'shipments-nav-item-v2',
      offset: OFFSET.SIDEBAR,
    },
    {
      visible:
        featureIsOn(ORG_FEATURES.KPIS) && (isG2OEmployee || kpisAreVisible),
      id: 'kpis',
      title: 'KPIs:',
      description: isG2OEmployee
        ? 'View contractual KPIs and monitor their performance for the selected Organisation.'
        : 'View contractual KPIs and monitor their performance.',
      component: G2oTourStep,
      selector: 'kpis-nav-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      visible: featureIsOn(ORG_FEATURES.STATISTICS_AVAILABLE),
      id: 'statistics',
      title: 'Statistics:',
      description: isG2OEmployee
        ? 'View historical performance in charts and data tables for the selected Organisation.'
        : 'View historical performance in charts and data tables.',
      component: G2oTourStep,
      selector: 'organisation-statistics-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      visible: featureIsOn(ORG_FEATURES.VESSELS),
      id: 'vessels',
      title: 'Vessels:',
      description: 'Check all MyG2 Vessels descriptions and characteristics',
      component: G2oTourStep,
      selector: 'vessels-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      id: 'organisation',
      title: 'Organisation:',
      description: isG2OEmployee
        ? 'Manage selected Organisation. Admin users can add or remove users and companies.'
        : 'Manage your Organisation. Admin users can add or remove users.',
      component: G2oTourStep,
      selector: 'organisation-nav-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      id: 'support',
      title: 'Support:',
      description:
        'Contact support team to get help or give feedback. Access legal information related to privacy policy and terms of service.',
      component: G2oTourStep,
      selector: 'support-link',
      offset: OFFSET.SUPPORT,
    },
    {
      id: 'user-menu',
      title: 'User Menu:',
      description:
        'Access your personal information, see all your organisations and manage the way you will get shipments related notifications.',
      component: G2oTourStep,
      selector: 'profile-dropdown-button',
      offset: OFFSET.USER_MENU,
    },
    {
      id: 'finish',
      type: 'dialog',
      component: G2oTourFinish,
    },
  ];

  return steps.filter(onlyVisible);
};

export const useG2OSteps = (): IG2OTourStep[] => {
  const steps: IG2OTourStep[] = [
    {
      id: 'start',
      type: 'dialog',
      component: G2oTourStart,
    },
    {
      id: 'organisation-list',
      title: 'Organisation List:',
      description:
        'Manage Organisations. Admin users can create or delete Organisations, add or remove companies and users.',
      component: G2oTourStep,
      selector: 'organisation-list-nav-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      visible: hasOneOfPermissions(['user.view.any']),
      id: 'user-list',
      title: 'User List:',
      description:
        'Manage Users. G2O Admin users can create and edit G2O users or remove all Users. Add and remove organizations for the G2O Users.',
      component: G2oTourStep,
      selector: 'users-nav-item',
      offset: OFFSET.SIDEBAR,
    },
    {
      id: 'organisation-switcher',
      title: 'Organisation Switcher:',
      description:
        'Select Organisation of interest to see information related to the specific Organisation.',
      component: G2oTourStep,
      selector: 'organisation-select',
      offset: OFFSET.SIDEBAR,
    },
    {
      id: 'switch-organisation',
      type: 'dialog',
      component: G2oTourSwitchOrganisation,
    },
  ];

  return steps.filter(onlyVisible);
};
