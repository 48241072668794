import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;
export const shipmentsV2 = `${baseEndpoint}/api/customer/organisation/1/shipments-v2`;

export default [
  http.get(shipmentsV2, async () => {
    return HttpResponse.json({
      data: [
        {
          id: 3867,
          vesselName: 'Star Majesty',
          vesselVoyageNumber: 2303,
          tradeLane: 'EU-ESA',
          status: 'upcoming',
          dateString: '11 Apr 2023',
          notify: false,
          totals: [
            {
              unit: 'MT',
              quantity: 43550,
            },
          ],
          shipments: [
            {
              id: '3752-Puerto Madryn',
              bookingNo: '3752', // cargoRef
              total: 43550,
              unit: 'MT',
              commodity: 'Alumina',
              notify: false,
              loadingPort: {
                id: '4c3432b5-d796-11ed-ad8a-0242ac130005',
                name: 'Alumar',
                type: 'L',
              },
              dischargePort: {
                id: '4c3435ea-d796-11ed-ad8a-0242ac130005',
                name: 'Puerto Madryn',
                type: 'D',
              },
              status: 'upcoming',
              arrived: true,
              date: '11 Apr 2023',
              visible: false,
            },
          ],
        },
      ],
    });
  }),
] satisfies HttpHandler[];
