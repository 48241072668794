import type { RouteRecordRaw } from 'vue-router';

import { ORIGIN_EXTERNAL, PERMISSION } from '~/constants';
import { useOrganisationsStore } from '~/store';

import { makeKeyBuilder, makePrivateRoute } from '~/features/useRouteMeta';
import { storage } from '~/features/useStorage';

import Service from '~/services/Service.js';

import type { IAdminUserListPendingUser } from '~/types';

import AdminOrganisationNew from '~/pages/AdminOrganisationNew.vue';
import AdminOrganisationsIndex from '~/pages/AdminOrganisationsIndex.vue';
import AdminUserAcceptRequest from '~/pages/AdminUserAcceptRequest.vue';
import AdminUserEdit from '~/pages/AdminUserEdit.vue';
import AdminUserNew from '~/pages/AdminUserNew.vue';
import AdminUserRejectRequest from '~/pages/AdminUserRejectRequest.vue';
import AdminUserRequest from '~/pages/AdminUserRequest.vue';
import AdminUsersIndex from '~/pages/AdminUsersIndex.vue';
import AdminUserView from '~/pages/AdminUserView.vue';
import AdminUserViewDetails from '~/pages/AdminUserViewDetails.vue';
import AdminUserViewNotifications from '~/pages/AdminUserViewNotifications.vue';

const switchOrganisationBeforeEnter = async () => {
  useOrganisationsStore().unsetCurrentOrganisation();
  return true;
};

export const adminRoutes: Readonly<RouteRecordRaw[]> = [
  makePrivateRoute({
    name: 'AdminUserAcceptRequest',
    path: '/internal/user-request/accept/:userId',
    component: AdminUserAcceptRequest,
    permission: PERMISSION.USERS_VIEW_ANY,
    props: (route) => ({
      userId: route.params.userId as string,
    }),
    beforeEnter: switchOrganisationBeforeEnter,
  }),
  makePrivateRoute({
    name: 'AdminUserRejectRequest',
    path: '/internal/user-request/reject/:userId',
    component: AdminUserRejectRequest,
    permission: PERMISSION.USERS_VIEW_ANY,
    props: (route) => ({
      userId: route.params.userId as string,
    }),
    beforeEnter: switchOrganisationBeforeEnter,
  }),
  makePrivateRoute({
    name: 'AdminUsersIndex',
    path: '/internal/user-list',
    component: AdminUsersIndex,
    permission: PERMISSION.USERS_VIEW_ANY,
    beforeEnter: async (_, __, next) => {
      const filters = new Promise((resolve) => {
        Service.users()
          .filters()
          .onSuccess(({ data }) => {
            storage.setUserListQuery(data);
            resolve(true);
          })
          .onError(() => {
            storage.setUserListQuery([]);
            resolve(false);
          })
          .execute();
      });

      const requests = new Promise((resolve) => {
        Service.users()
          .publicRequests()
          .index()
          .onSuccess(({ data }: { data: IAdminUserListPendingUser[] }) => {
            storage.setPendingUserRequests(data);
            resolve(true);
          })
          .onNoData(() => {
            storage.setPendingUserRequests([]);
            resolve(false);
          })
          .onError(() => {
            storage.setPendingUserRequests([]);
            resolve(false);
          })
          .execute();
      });

      return await Promise.all([filters, requests]).then(() => {
        switchOrganisationBeforeEnter();
        next();
      });
    },
  }),
  makePrivateRoute({
    name: 'AdminUserNew',
    path: '/internal/user-list/new',
    component: AdminUserNew,
    permission: PERMISSION.USERS_VIEW_ANY,
    beforeEnter: switchOrganisationBeforeEnter,
  }),
  makePrivateRoute({
    path: '/internal/user-list/:userId/type/:type',
    permission: PERMISSION.USERS_VIEW_ANY,
    component: AdminUserView,
    beforeEnter: switchOrganisationBeforeEnter,
    keyBuilder: makeKeyBuilder('AdminUserView'),
    props: (route) => ({
      userId: parseInt(route.params.userId as string, 10),
      type: route.params.type as string,
    }),
    children: [
      {
        path: '',
        name: 'AdminUserView',
        redirect: { name: 'AdminUserViewDetails' },
      },
      {
        name: 'AdminUserViewDetails',
        path: 'details',
        component: AdminUserViewDetails,
        props: true,
      },
      {
        name: 'AdminUserViewNotifications',
        path: 'notifications',
        component: AdminUserViewNotifications,
        props: true,
      },
    ],
  }),
  makePrivateRoute({
    name: 'AdminUserEdit',
    path: '/internal/user-list/:userId/edit',
    permission: PERMISSION.USERS_VIEW_ANY,
    component: AdminUserEdit,
    beforeEnter: switchOrganisationBeforeEnter,
    props: (route) => ({
      userId: parseInt(route.params.userId as string, 10),
      type: route.params.type as string,
    }),
  }),
  makePrivateRoute({
    name: 'AdminUserRequest',
    path: '/internal/user-request/:requestToken',
    component: AdminUserRequest,
    permission: PERMISSION.USERS_VIEW_ANY,
    beforeEnter: async (to, _, next) => {
      const { requestToken } = to.params as { requestToken: string };

      return await Service.users()
        .publicRequests()
        .status(requestToken)
        .onSuccess(() => {
          switchOrganisationBeforeEnter();
          next({
            name: 'AdminUsersIndex',
            query: {
              origin: ORIGIN_EXTERNAL,
            },
          });
        })
        .onErrorConflict(() => {
          switchOrganisationBeforeEnter();
          next();
        })
        .onErrorNotFound(() => next('/404'))
        .onError(() => {
          next({
            name: 'AuthLogin',
            query: {
              redirect: to.fullPath,
            },
          });
        })
        .execute();
    },
  }),
  makePrivateRoute({
    name: 'AdminOrganisationsIndex',
    path: '/internal/organisation-list',
    component: AdminOrganisationsIndex,
    permission: PERMISSION.ORGANISATION_VIEW_ANY,
    beforeEnter: switchOrganisationBeforeEnter,
  }),
  makePrivateRoute({
    name: 'AdminOrganisationNew',
    path: '/internal/organisation-list/new',
    component: AdminOrganisationNew,
    permission: PERMISSION.ORGANISATION_CREATE_ANY,
    beforeEnter: switchOrganisationBeforeEnter,
  }),
];
