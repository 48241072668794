<script setup name="ServerErrorCard">
import BaseButton from '~/components/BaseButton.vue';

defineProps({
  title: {
    type: String,
    default: 'Sorry. It’s not you, it’s us...',
  },
  message: {
    type: String,
    default: 'Try to refresh the page.',
  },
});

const emit = defineEmits(['retry']);
</script>

<template>
  <div>
    <div class="flex flex-col items-center justify-center py-14">
      <img src="/images/illustrations/server_error.svg" alt="" />
      <h2 class="mt-4 text-2xl text-charcoal-6">
        {{ title }}
      </h2>
      <p class="mt-1 text-sm text-charcoal-6">
        {{ message }}
      </p>
      <BaseButton class="mt-2" @click="emit('retry')">Refresh</BaseButton>
    </div>
  </div>
</template>
