<script lang="ts" setup name="SailingScheduleTradeLaneModal">
import BaseModal from '~/components/BaseModal.vue';
import SailingScheduleDownload from '~/components/SailingScheduleDownload.vue';
import SailingScheduleTradeLaneDescription from '~/components/SailingScheduleTradeLaneDescription.vue';
import SailingScheduleTradeLaneVoyages from '~/components/SailingScheduleTradeLaneVoyages.vue';

import type {
  BaseTagContrastType,
  ITradeLanePort,
  ITradeLaneVessel,
  PromiseResolvePayload,
} from '~/types';

const { organisationId, tradeLane, tagVariant, vessels, ports, closeModal } =
  defineProps<{
    organisationId?: OrganisationId;
    vessels: ITradeLaneVessel[];
    ports: ITradeLanePort[];
    tradeLane: string;
    downloadable: boolean;
    tagVariant: BaseTagContrastType;
    closeModal: (data: PromiseResolvePayload<'CLOSE'>) => void;
  }>();

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};
</script>

<template>
  <BaseModal @close="onClose">
    <template #title>
      <SailingScheduleTradeLaneDescription
        :trade-lane="tradeLane"
        :tagVariant="tagVariant"
      />
      <SailingScheduleDownload
        v-if="downloadable"
        :organisation-id="organisationId"
        :trade-lane="tradeLane"
        :vessels="vessels"
        class="ml-auto"
      />
    </template>
    <div class="overflow-y-auto">
      <SailingScheduleTradeLaneVoyages
        :service-state="{ stateIsIdle: true }"
        :vessels="vessels"
        :ports="ports"
      />
    </div>
  </BaseModal>
</template>
