<script setup lang="ts" name="OrganisationHomeAllShipmentsLayout"></script>

<template>
  <div class="space-y-2">
    <div class="rounded-t-md bg-white p-6 drop-shadow">
      <p class="text-subtitle-1 text-charcoal-9">All Shipments</p>
    </div>
    <slot />

    <div class="flex justify-center rounded-b-md bg-white p-6 drop-shadow">
      <slot name="footer" />
    </div>
  </div>
</template>
