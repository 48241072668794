<script lang="ts" setup name="NotificationsMenuContainer">
import { RouterLink } from 'vue-router';

defineProps<{
  totalUnread?: number | null;
  emptyNotifications: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>

<template>
  <div class="z-50 h-auto w-96 rounded bg-white shadow-md">
    <div class="flex border-b border-charcoal-2 p-6">
      <p class="mr-1 text-lg font-bold">Notifications</p>
      <p v-if="totalUnread" class="text-lg font-bold">
        ({{ totalUnread }} unread)
      </p>
    </div>
    <slot />
    <div
      v-if="!emptyNotifications"
      class="flex justify-end border-t border-charcoal-3 p-2"
    >
      <RouterLink
        class="mx-2 inline-flex items-center rounded py-3 pr-1 text-xs font-bold uppercase tracking-widest text-primary-8"
        :to="{ name: 'ArchiveNotificationsIndex' }"
        @click="emit('close')"
      >
        see archive
      </RouterLink>
    </div>
  </div>
</template>
