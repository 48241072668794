<script
  setup
  lang="ts"
  name="OrganisationHomeStatisticsWidgetSkeleton"
></script>

<template>
  <div class="flex h-full flex-col rounded bg-white p-6 shadow">
    <div class="text-loading h-5 w-11/12"></div>
    <div class="text-loading mt-3 h-5 w-9/12"></div>

    <div class="text-loading my-8 h-5 w-1/6"></div>
    <div class="text-loading h-5 w-full"></div>
  </div>
</template>
