<script lang="ts" setup name="FiltersButtonMobile">
import { computed } from 'vue';

import Icon from '~/components/Icon.vue';

import type { IconType } from '~/types';

const { icon = '', active } = defineProps<{
  icon?: IconType;
  active: boolean;
}>();

const buttonIcon = computed(() =>
  active ? 'ic:baseline-check' : 'mdi:chevron-right',
);
</script>

<template>
  <button
    v-bind="$attrs"
    class="flex w-full items-center justify-between rounded-lg border-3 border-solid px-3 py-5 text-button-1 uppercase tracking-wider shadow-sm"
    :class="{
      'border-primary-8 bg-primary-8 text-white': active,
      'border-white bg-white text-primary-8': !active,
    }"
  >
    <div class="flex items-center">
      <Icon v-if="icon" :icon="icon" class="mr-3" />
      <slot />
    </div>

    <Icon :icon="buttonIcon" />
  </button>
</template>
