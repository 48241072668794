<script lang="ts" setup name="SailingScheduleTradeLanePortDates">
import { computed } from 'vue';

const {
  id,
  sortedVesselId,
  hover = false,
  last = false,
  dates = [],
  visible = false,
  hasSelectedPorts = false,
  fullScreen = false,
} = defineProps<{
  id: string;
  hover?: boolean;
  last?: boolean;
  dates?: {
    id: string;
    vesselId: string;
    date: string;
  }[];
  visible?: boolean;
  hasSelectedPorts?: boolean;
  sortedVesselId?: string;
  fullScreen?: boolean;
}>();

const emit = defineEmits<{
  (e: 'mouseover', id: string): void;
  (e: 'mouseout', id: string): void;
}>();

const shouldHaveBackground = computed(() => {
  if (hasSelectedPorts && visible && fullScreen) return true;

  return hover;
});
</script>

<template>
  <div
    class="flex h-14 border-b"
    data-testid="sailing-schedule-trade-lane-port-dates"
    :class="{
      'border-charcoal-2': (last && hover) || !last,
      'border-transparent': last && !hover,
      'bg-charcoal-1': shouldHaveBackground && !sortedVesselId,
    }"
    @mouseover="emit('mouseover', id)"
    @mouseout="emit('mouseout', id)"
  >
    <p
      v-for="date in dates"
      :key="date.id"
      class="flex w-44 shrink-0 items-center justify-start truncate px-4"
      :class="{
        'opacity-40': !!sortedVesselId && date.vesselId !== sortedVesselId,
        'text-coal-900': hover,
      }"
    >
      {{ date.date }}
    </p>
  </div>
</template>
