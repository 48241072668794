<script setup lang="ts" name="FilterMobileFilter">
import BaseButton from '~/components/BaseButton.vue';

const emit = defineEmits<{
  apply: [];
}>();
</script>

<template>
  <div class="border-t border-charcoal-2">
    <div class="ml-auto mt-4 flex w-36 flex-col">
      <BaseButton variant="btn-primary" @click="emit('apply')">
        Apply
      </BaseButton>
    </div>
  </div>
</template>
