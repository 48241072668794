<script lang="ts" name="ModalsProvider">
import type { Component } from 'vue';
import { h, onBeforeUnmount, onMounted, ref, Teleport } from 'vue';

import { useModalsProviderModals } from '~/features/useModalsProvider';

import type { PromiseResolvePayload } from '~/types';

export default {
  setup() {
    const modals = useModalsProviderModals();

    const currentAction = ref<PromiseResolvePayload>({ action: 'CLOSE' });

    const escapeHandler = (e: KeyboardEvent) => {
      if (modals.value.length === 0) {
        return;
      }

      if (e.key === 'Escape') {
        const lastModal = modals.value[modals.value.length - 1];
        lastModal.props.closeModal(currentAction.value);
      }
    };

    onMounted(() => {
      document.addEventListener('keydown', escapeHandler);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', escapeHandler);
    });

    return () =>
      h(
        Teleport,
        {
          to: 'body',
        },
        modals.value.map((modal) =>
          h(
            'dialog',
            {
              open: true,
              class:
                'fixed inset-0 z-10 grid place-items-center h-screen w-screen bg-black/50 overflow-y-auto',
              onClick: (e: MouseEvent) => {
                if (
                  e.target === e.currentTarget &&
                  !modal.preventClickOutside
                ) {
                  modal.props.closeModal(currentAction.value);
                }
              },
            },
            h(modal.component as Component, {
              key: modal.id,
              ...modal.props,
              onActionChange: (action) => (currentAction.value = action),
            }),
          ),
        ),
      );
  },
};
</script>
