<script setup lang="ts" name="AuthProfileEdit">
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useProfileStore, useSnackbarStore } from '~/store';

import { useRouteBack } from '~/features/useRouteBack';

import Service from '~/services/Service.js';

import BasePage from '~/components/BasePage.vue';
import Field from '~/components/Field.vue';
import FieldForm from '~/components/FieldForm.vue';

import type { IProfile } from '~/types';

const publicProfile = 'PublicUserProfile';
const authProfile = 'AuthProfile';

const snackbar = useSnackbarStore();
const router = useRouter();
const profileStore = useProfileStore();

const errors = ref(null);
const isLoading = ref(false);

const profile = computed(() => profileStore.profile as IProfile);

const backPath = useRouteBack(
  profileStore.isPublic ? { name: publicProfile } : { name: authProfile },
);

const backButton = {
  to: backPath,
  label: 'My Details',
};

const page = computed(() => ({
  title: 'Edit My Details',
  backButton,
}));

const form = reactive({
  firstName: profile.value.firstName,
  lastName: profile.value.lastName,
});

const schema = reactive({
  firstName: {
    name: 'first_name',
    label: 'First Name',
    rules: 'required|min:2|max:128',
    component: 'FieldText',
    autocomplete: 'first_name',
  },
  lastName: {
    name: 'last_name',
    label: 'Last Name',
    rules: 'required|min:2|max:128',
    component: 'FieldText',
    autocomplete: 'last_name',
  },
});

const onSubmit = async () => {
  await Service.profile()
    .store(form)
    .onStart(() => {
      isLoading.value = true;
    })
    .onSuccess(async () => {
      profileStore.updateName(form);

      await router.push({
        name: profileStore.isPublic ? publicProfile : authProfile,
      });

      snackbar.add({
        type: 'success',
        text: 'Your details updated.',
      });
    })
    .onErrorValidation((data) => {
      errors.value = data.response.data?.errors;
    })
    .onFinish(() => {
      isLoading.value = false;
    })
    .execute();
};
</script>

<template>
  <BasePage v-bind="page">
    <div class="col-span-full rounded-md bg-white p-6 shadow">
      <FieldForm
        confirm="Submit"
        :errors="errors"
        :loading="isLoading"
        full
        @submit="onSubmit"
      >
        <p class="text-subtitle-1 text-charcoal-9">My Details</p>
        <div class="max-w-form space-y-4">
          <Field v-model="form.firstName" :field="schema.firstName" />
          <Field v-model="form.lastName" :field="schema.lastName" />
        </div>
      </FieldForm>
    </div>
  </BasePage>
</template>
