import type { Ref } from 'vue';
import { ref } from 'vue';
import { watchDebounced } from '@vueuse/shared';

const SEARCH_DEBOUNCE_TIME = 200;

export function useSearchDebounce(
  searchValue: Ref<string>,
  callback: () => void,
  minSearchLength: number,
) {
  const searchQuery = ref('');

  watchDebounced(
    () => searchValue.value,
    (currSearch) => {
      const searchLengthIsValid = currSearch.length >= minSearchLength;
      const searchIsCurrent = currSearch.startsWith(searchQuery.value);

      if (searchLengthIsValid && (!searchIsCurrent || !searchQuery.value)) {
        searchQuery.value = currSearch;

        callback();
      }
    },
    {
      debounce: SEARCH_DEBOUNCE_TIME,
    },
  );
}
