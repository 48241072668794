<script setup lang="ts" name="SailingScheduleTradelaneButton">
import { useShowModal } from '~/features/useModalsProvider';

import Icon from '~/components/Icon.vue';
import SailingScheduleTradeLaneModal from '~/components/SailingScheduleTradeLaneModal.vue';

import type {
  BaseTagContrastType,
  ITradeLanePort,
  ITradeLaneVessel,
} from '~/types';

const { tradeLane, downloadable, organisationId, tagVariant, vessels, ports } =
  defineProps<{
    organisationId?: OrganisationId;
    vessels: ITradeLaneVessel[];
    ports: ITradeLanePort[];
    tradeLane: string;
    downloadable?: boolean;
    tagVariant: BaseTagContrastType;
  }>();

const showModal = useShowModal();

const openTradeLaneModal = () => {
  showModal(SailingScheduleTradeLaneModal, {
    organisationId: organisationId,
    tagVariant,
    vessels: vessels,
    downloadable: downloadable,
    ports: ports,
    tradeLane,
  });
};
</script>

<template>
  <button
    v-tooltip.bottom.min="'Expand list'"
    type="button"
    class="grid h-08 w-08 place-items-center rounded-md text-primary-8 outline-none hover:bg-charcoal-2"
    @click.stop="openTradeLaneModal"
  >
    <Icon icon="mdi:arrow-expand-all" />
  </button>
</template>
