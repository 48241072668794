<script setup lang="ts" name="OrganisationSelectButton">
import Icon from '~/components/Icon.vue';

const { hasOneOrganisation, closed, name, open } = defineProps<{
  name: string;
  closed: boolean;
  open: boolean;
  hasOneOrganisation?: boolean;
}>();

const emit = defineEmits<{
  toggle: [];
}>();
</script>

<template>
  <button
    data-test="organisation-select"
    class="padding-change-transition relative flex h-14 w-full items-center justify-between rounded-[5px] border border-ocean-4 text-left hover:border-opacity-25"
    :class="[
      closed ? 'pr-[14px]' : 'px-4',
      {
        'pointer-events-none': hasOneOrganisation,
      },
    ]"
    :title="name"
    :disabled="hasOneOrganisation"
    @click.stop="emit('toggle')"
  >
    <p
      class="text-opacity-transition absolute -top-2 left-1 bg-primary-8 px-1 text-xs uppercase"
      :class="closed ? 'opacity-0' : 'opacity-100'"
    >
      Organisation
    </p>

    <span
      class="text-opacity-transition truncate"
      :class="closed ? 'opacity-0' : 'opacity-100'"
    >
      {{ name }}
    </span>

    <Icon
      v-if="!hasOneOrganisation"
      icon="mdi:chevron-down"
      class="transform text-ocean-2"
      :class="{
        'rotate-180': open,
      }"
    />
  </button>
</template>
