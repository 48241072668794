<script setup lang="ts" name="OrganisationHomeAside">
import { computed, onMounted } from 'vue';

import { ORG_FEATURES, SHIPMENT_TABS, STATES } from '~/constants';
import { useHomeStore, useProfileStore } from '~/store';

import { featureIsEnabled } from '~/features/useFeatures';
import { getOrgRoute } from '~/features/useRouteHelpers';

import ComponentErrorCard from '~/components/ComponentErrorCard.vue';
import OrganisationHomeCarbonWidget from '~/components/OrganisationHomeCarbonWidget.vue';
import OrganisationHomeTheLatestNews from '~/components/OrganisationHomeTheLatestNews.vue';
import OrganisationHomeTheQuickLinks from '~/components/OrganisationHomeTheQuickLinks.vue';
import OrganisationHomeTheYourShipments from '~/components/OrganisationHomeTheYourShipments.vue';

import type { IOrganisation, IOrgFeature } from '~/types';

const { organisation } = defineProps<{
  organisation: IOrganisation;
}>();

const toShipmentsPage = {
  ...getOrgRoute('OrganisationShipmentsIndex', organisation.id),
  query: {
    status: SHIPMENT_TABS.ALL,
  },
};

const homeStore = useHomeStore();
const profileStore = useProfileStore();
const checkFeature = (feature: IOrgFeature) =>
  featureIsEnabled(feature, organisation);

const stateIsError = computed(() => homeStore.asideStateIs(STATES.ERROR));

onMounted(() => {
  homeStore.fetchAside(organisation.id);
});
</script>

<template>
  <div class="space-y-2">
    <ComponentErrorCard
      v-if="stateIsError"
      spacing="card-sm"
      styled
      @retry="homeStore.fetchAside(organisation.id)"
    />

    <OrganisationHomeTheYourShipments
      v-if="!stateIsError"
      :shipments-path="toShipmentsPage"
      :shipments-count="homeStore.shipmentsCount"
      :loading="homeStore.asideStateIs(STATES.LOADING)"
    />

    <OrganisationHomeTheQuickLinks
      ocean-link-visible
      :organisation="organisation"
      :loading="homeStore.asideStateIs(STATES.LOADING)"
    />

    <OrganisationHomeCarbonWidget
      v-if="
        checkFeature(ORG_FEATURES.CARBON_OFFSETTING_WIDGET) && !stateIsError
      "
      v-bind="homeStore.emissions"
      :loading="homeStore.asideStateIs(STATES.LOADING)"
      :organisation-id="organisation.id"
      :isG2OCustomerUser="profileStore.isCustomer"
      :emissionsFeatureEnabled="checkFeature(ORG_FEATURES.STATISTICS_EMISSIONS)"
    />

    <OrganisationHomeTheLatestNews
      v-if="!stateIsError"
      v-bind="homeStore.latestNews"
      :loading="homeStore.asideStateIs(STATES.LOADING)"
      :has-error="stateIsError"
    />
  </div>
</template>
