<script setup lang="ts" name="FormChangePassword">
import { reactive, ref } from 'vue';

import { useSnackbarStore } from '~/store';

import Service from '~/services/Service.js';

import Field from '~/components/Field.vue';
import FieldForm from '~/components/FieldForm.vue';
import Icon from '~/components/Icon.vue';

import type { IFieldFormSubmit } from '~/types';

const isLoading = ref(false);
const errors = ref(null);

const form = reactive({
  password: null,
  newPassword: null,
  confirmPassword: null,
});

const schema = reactive({
  password: {
    name: 'current_password',
    label: 'Current Password',
    rules: 'required|max:128',
    component: 'FieldPassword',
    autocomplete: 'current_password',
  },
  newPassword: {
    name: 'new_password',
    label: 'New Password',
    rules: 'required|g2password|min:10|max:128',
    component: 'FieldPassword',
    autocomplete: 'new_password',
  },
  confirmPassword: {
    name: 'confirm_password',
    label: 'Confirm Password',
    rules: 'required|confirms:@new_password',
    component: 'FieldPassword',
    autocomplete: 'confirm_password',
  },
});

const onSubmit = ({ resetForm }: IFieldFormSubmit) => {
  Service.profile()
    .password()
    .store(form)
    .onStart(() => {
      isLoading.value = true;
    })
    .onSuccess(() => {
      useSnackbarStore().add({
        type: 'success',
        text: 'Password updated.',
      });

      resetForm();
    })
    .onErrorValidation((data: any) => {
      errors.value = data.response.data?.errors;
    })
    .onFinish(() => {
      isLoading.value = false;
    })
    .execute();
};
</script>

<template>
  <FieldForm
    data-test="password"
    confirm="Submit"
    :errors="errors"
    :loading="isLoading"
    button-full
    @submit="onSubmit"
  >
    <Field v-model="form.password" :field="schema.password" />
    <Field v-model="form.newPassword" :field="schema.newPassword" />
    <Field v-model="form.confirmPassword" :field="schema.confirmPassword" />
    <div class="flex space-x-3">
      <Icon icon="mdi:information" class="text-indigo-4" />
      <p class="text-sm text-charcoal-6">
        Please note, password must be at least 10 characters long and must
        include at least one uppercase letter and at least one number.
      </p>
    </div>
  </FieldForm>
</template>
