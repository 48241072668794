import { http, type HttpHandler, HttpResponse } from 'msw';

const TABLE_ROWS_COUNT = 10;
const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

const base = `${baseEndpoint}/api/customer/organisations/1/kpi-settings/invoices`;

const getTableData = [...Array(TABLE_ROWS_COUNT)].map((_, index) => ({
  id: index,
  days: 1,
  visible: true,
  name: 'ESA-ENA.2017.CMPC',
  periodStart: '25 Feb 2023',
  periodEnd: '22 Mar 2023',
  status: 'active',
  createdAt: '24 Feb 2023',
}));

export default [
  http.get(base, async () => {
    return HttpResponse.json({
      data: getTableData,
      links: { prev: '', next: '/', first: '', last: '' },
      meta: {
        current_page: 1,
        from: 1,
        links: [{ url: null, label: '', active: false }],
        path: '',
        per_page: 10,
        to: 10,
        last_page: 2,
        total: 18,
      },
    });
  }),
] satisfies HttpHandler[];
