<script setup lang="ts" name="PublicSailingScheduleAddTradeDropdown">
import { useVModel } from '@vueuse/core';

import { provideBaseCardState } from '~/features/useBaseCardState';
import { ADD_TRADE_MIN_SEARCH_LENGTH } from '~/features/usePublicSailingScheduleAddTrade';
import { useSearchDebounce } from '~/features/useSearchDebounce';

import Service from '~/services/Service';

import FilterMenuOptions from '~/components/FilterMenuOptions.vue';
import PublicSailingScheduleSelectMultiple from '~/components/PublicSailingScheduleSelectMultiple.vue';
import SearchField from '~/components/SearchField.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { IStatisticsTradeLane, ITradeLane } from '~/types';

const props = defineProps<{
  modelValue: string;
  tradesEmpty: boolean;
  searchActive: boolean;
  searchTradesStatusText: string;
  hasReachedMaximumTrades: boolean;
  filteredTradeOptions: IStatisticsTradeLane[];
  selectedTradesServiceState: Record<string, unknown>;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
  (e: 'setTradelanes', value: ITradeLane[]): void;
  (e: 'setSearchStatus', value: { active: boolean }): void;
  (e: 'setSelectedTradelanes', value: ITradeLane[]): void;
  (e: 'selectTrade', close: () => void, value: IStatisticsTradeLane): void;
}>();

const { setStateLoading, setStateIdle, stateIsLoading } =
  provideBaseCardState();

const searchModel = useVModel(props, 'modelValue', emit);

const showDropdown = (
  searchValue: string,
  open: () => void,
  close: () => void,
) => {
  const shouldClose =
    searchValue.length < ADD_TRADE_MIN_SEARCH_LENGTH ||
    props.hasReachedMaximumTrades;

  if (props.hasReachedMaximumTrades) {
    emit('setSearchStatus', { active: true });
  }

  if (shouldClose) {
    close();

    return;
  }

  open();
};

const selectTrade = (close: () => void, trade: IStatisticsTradeLane) => {
  Service.publicSailingSchedule()
    .customize()
    .store(trade.id)
    .onSuccess(({ data }: { data: ITradeLane[] }) => {
      emit('setSelectedTradelanes', data);
    })
    .execute();

  close();
};

const fetchTradelanes = () => {
  Service.publicSailingSchedule()
    .customize()
    .tradelanes(searchModel.value)
    .onStart(setStateLoading)
    .onSuccess(({ data }: { data: ITradeLane[] }) => {
      emit('setTradelanes', data);

      setStateIdle();
    })
    .execute();
};

useSearchDebounce(searchModel, fetchTradelanes, ADD_TRADE_MIN_SEARCH_LENGTH);
</script>

<template>
  <div>
    <XDropdown data-testid="semi-public-search">
      <template #trigger="{ open, close }">
        <SearchField
          v-model="searchModel"
          placeholder="Search by Trade Name"
          icon="none"
          :error="hasReachedMaximumTrades && searchActive"
          @click="showDropdown(searchModel, open, close)"
          @update:modelValue="
            (value: string) => showDropdown(value, open, close)
          "
        />
      </template>

      <template v-if="!stateIsLoading" #default="{ close }">
        <FilterMenuOptions
          data-testid="combo-box-options"
          :has-options="!tradesEmpty"
        >
          <PublicSailingScheduleSelectMultiple
            :options="filteredTradeOptions"
            @selectTrade="selectTrade(close, $event)"
          />
        </FilterMenuOptions>
      </template>
    </XDropdown>

    <p
      v-if="!selectedTradesServiceState.stateIsLoading"
      v-html="searchTradesStatusText"
      class="py-2 text-sm"
      :class="{
        'text-red-6': hasReachedMaximumTrades && searchActive,
        'text-charcoal-6': !hasReachedMaximumTrades,
      }"
    ></p>
  </div>
</template>
