<script setup lang="ts" name="FilterMobile" generic="T extends IOption">
import { useVModel } from '@vueuse/core';

import { useFilterMenu } from '~/features/useFilterMenu';

import FilterMobileFooter from '~/components/FilterMobileFooter.vue';
import ListCheckbox from '~/components/ListCheckbox.vue';
import SearchField from '~/components/SearchField.vue';

import type { IOption } from '~/types';

const props = defineProps<{
  modelValue: T[];
  options: T[];
}>();

const emit = defineEmits<{
  apply: [];
  'update:modelValue': [value: T[]];
}>();

const { search, menuOptions } = useFilterMenu<T>(props);

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <div>
    <SearchField v-model="search" placeholder="Search" />

    <div class="h-[500px] overflow-y-auto">
      <template v-if="menuOptions?.length">
        <ListCheckbox
          v-for="option in menuOptions"
          v-model="inputValue"
          :key="option.id"
          :item="option"
        >
          <slot :item="option">
            {{ option.name }}
          </slot>
        </ListCheckbox>
      </template>

      <div v-else class="p-4 text-sm">No results found...</div>
    </div>

    <FilterMobileFooter @apply="emit('apply')" />
  </div>
</template>
