import type { PiniaPluginContext } from 'pinia';

import { PINIA_STORE } from '~/constants';
import { useProfileStore } from '~/store';

import { useWebsocketChannels } from '~/features/useWebsocketChannels';

const { setupAuthChannels, disconnectChannels } = useWebsocketChannels();

export const usePiniaPlugin = ({ store }: PiniaPluginContext) => {
  if (store.$id === PINIA_STORE.AUTH_STORE) {
    store.$onAction((action) => {
      if (action.name === 'logout' || action.name === 'logout401') {
        disconnectChannels();
      }
    });
  }

  if (store.$id === PINIA_STORE.AUTH_STORE) {
    store.$onAction((action) => {
      if (action.name === 'login') {
        action.after(() => {
          setupAuthChannels();
        });
      }
    });
  }

  if (store.$id === PINIA_STORE.PROFILE_STORE) {
    if (store.isAuthenticated) {
      useProfileStore()
        .fetchProfile()
        .then(() => {
          setupAuthChannels();
        })
        .catch(() => {});
    }
  }
};
