<script lang="ts" setup name="BaseModal">
import SimpleCard from '~/components/Cards/SimpleCard.vue';
import ModalCloseButton from '~/components/ModalCloseButton.vue';

const {
  title,
  dataTest = 'g2-modal',
  maxWidth = 'max-content',
  content = 'default',
} = defineProps<{
  title?: string;
  content?: 'default' | 'compact';
  dataTest?: string;
  maxWidth?: string;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>

<template>
  <div
    class="flex max-h-[90vh] w-full cursor-auto flex-col overscroll-contain"
    v-focus-trap
    tabindex="-1"
    :data-test="dataTest"
    :style="`max-width: ${maxWidth}`"
  >
    <SimpleCard class="flex h-full min-h-0 w-full flex-col">
      <slot name="heading">
        <div class="flex items-center justify-between px-6 pb-4 pt-6">
          <slot name="title">
            <p class="text-subtitle-1">{{ title }}</p>
          </slot>

          <ModalCloseButton @close="emit('close')" />
        </div>
      </slot>

      <div
        class="flex min-h-0 flex-col"
        :class="{ 'px-6': content === 'default' }"
      >
        <slot />
      </div>

      <slot name="actions"> </slot>
    </SimpleCard>
  </div>
</template>
