<script setup lang="ts" name="OrganisationHomeVlsfoPriceCard">
import Icon from '~/components/Icon.vue';

import type { IFilledVlsfoPriceBunker } from '~/types';

const { bunkers } = defineProps<{
  bunkers: IFilledVlsfoPriceBunker[];
}>();

const getBunkerValue = (value: number | string | null) => value || '-';
</script>

<template>
  <div class="mt-2 flex h-full w-full items-end justify-between">
    <div
      v-for="(bunker, index) in bunkers"
      :key="index"
      :class="{ 'text-right': index === 1 }"
    >
      <div class="inline-block">
        <div v-if="bunker.icon" class="flex justify-center">
          <Icon :icon="bunker.icon" />
        </div>
        <p class="text-sm text-secondary-9">
          {{ bunker.name }}
        </p>
      </div>
      <div
        class="mt-2 flex items-baseline space-x-2"
        :class="{ 'justify-end': index === 1 }"
      >
        <p class="text-2xl font-light text-secondary-9">
          {{ getBunkerValue(bunker.value) }}
        </p>
        <p class="text-xs font-light text-charcoal-6">
          {{ bunker.description }}
        </p>
      </div>
    </div>
  </div>
</template>
