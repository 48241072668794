<script setup lang="ts" name="LogoG2OVertical"></script>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.38397 31.97C2.41637 31.97 1.60197 31.6448 0.963166 31.0036C0.324366
      30.3624 0.000366211 29.5664 0.000366211 28.636C0.000366211 27.712 0.324366
      26.9184 0.963166 26.276C1.60197 25.6336 2.41717 25.3096 3.38397 25.3096C4.35077
      25.3096 5.16517 25.6344 5.80477 26.276C6.44437 26.9176 6.76757 27.7104 6.76757
      28.636C6.76757 29.5664 6.44357 30.3632 5.80477 31.0036C5.16597 31.644 4.35117
      31.97 3.38397 31.97ZM3.39357 26.8232C2.89997 26.8232 2.50077 26.9964 2.17317
      27.3532C1.84557 27.71 1.68197 28.1332 1.68197 28.6364C1.68197 29.146 1.84197
      29.5684 2.17357 29.9284C2.50517 30.2884 2.89957 30.4568 3.39317 30.4568C3.87997
      30.4568 4.27317 30.2836 4.59837 29.9276C4.92357 29.5716 5.08597 29.1444 5.08597
      28.6348C5.08597 28.1316 4.92597 27.7116 4.59837 27.3512C4.27077 26.9908 3.87957
      26.8232 3.39317 26.8232H3.39357Z"
      fill="white"
    />
    <path
      d="M10.5984 31.97C9.63084 31.97 8.81644 31.6448 8.17764 31.0036C7.53884
      30.3624 7.21484 29.5664 7.21484 28.636C7.21484 27.712 7.53884 26.9184
      8.17764 26.276C8.81644 25.6336 9.63164 25.3096 10.5984 25.3096C11.402 25.3096
      12.1116 25.544 12.708 26.0064L12.834 26.104L11.874 27.2376L11.762 27.1548C11.4636
      26.9348 11.104 26.8232 10.694 26.8232C10.1552 26.8232 9.73124 26.9956 9.39764
      27.35C9.06404 27.7044 8.89764 28.1272 8.89764 28.636C8.89764 29.1448 9.05764
      29.568 9.38924 29.928C9.72084 30.288 10.114 30.4568 10.608 30.4568C11.1196
      30.4568 11.5328 30.308 11.8708 30.0024L11.986 29.8984L12.9896 31.0412L12.876
      31.142C12.2596 31.692 11.4936 31.97 10.5984 31.97Z"
      fill="white"
    />
    <path
      d="M13.4216 31.866V25.4064H18.5408V26.852H15.046V27.7996H18.3616
      V29.2352H15.046V30.4204H18.6628V31.866H13.4216Z"
      fill="white"
    />
    <path
      d="M24.2944 31.8656L23.8896 30.8872H20.9968L20.5788 31.8656H18.89L21.6636
      25.4064H23.1896L25.89 31.8656H24.2944ZM23.33 29.5368L22.442 27.3952C22.2756
      27.866 22.1156 28.2752 21.9644 28.622L21.5744 29.5368H23.33Z"
      fill="white"
    />
    <path
      d="M30.8884 31.8656C30.7292 31.8658 30.5724 31.8266 30.432 31.7514C30.2916
      31.6762 30.1721 31.5675 30.084 31.4348L27.922 28.1768V31.8644H26.2972V25.4064H27.4524C27.6122
      25.4061 27.7695 25.4456 27.9103 25.5213C28.051 25.597 28.1706 25.7065 28.2584 25.84L30.3756
      29.0568V25.4064H32V31.8656H30.8884Z"
      fill="white"
    />
    <path
      d="M20.1829 10.7792H13.2773C12.8847 10.7792 12.5083 10.9351 12.2307 11.2127C11.9532
      11.4902 11.7973 11.8667 11.7973 12.2592V14.8776H16.3009C15.3353 16.276 13.6269 17.1768
      11.7944 17.1768C8.93725 17.1768 6.29765 14.846 6.29805 11.6476C6.29805 8.49681
      8.79965 6.19201 11.6644 6.12321H11.8645C11.9009 6.12321 11.9353 6.12841 11.9713
      6.12961H15.6341L19.9409 0.00280762H11.9009C11.8477 0.00280762 11.7941 0.00280762
      11.7409 0.00280762H11.5809V0.00680756C11.4496 0.00920755 11.3193 0.00960747 11.1873
      0.0164075C5.06525 0.330807 0.202048 5.47281 0.179648 11.6032C0.155648 18.0512 5.35005
      23.2928 11.7933 23.294C12.685 23.2932 13.5738 23.19 14.4421 22.9864L21.3921 13.1064C22.0785
      12.1272 21.3789 10.7796 20.1829 10.7792Z"
      fill="white"
    />
    <path
      d="M30.3709 18.3564H25.2633L29.7057 12.0525C29.7057 12.0525 31.1037 10.1893 31.1057
      7.76685C31.1057 3.97765 28.0945 0.00165449 23.5601 0.000854492C23.5121 0.000854492 23.4649
      0.00325448 23.4173 0.00365448H21.4257L17.1113 6.12885H21.1221C21.3651 5.75359 21.6983 5.44519
      22.0912 5.2318C22.4841 5.01841 22.9242 4.90684 23.3713 4.90725C25.0173 4.90725 26.1353 6.39845
      26.1349 7.57885C26.1349 8.47965 25.6997 9.25605 25.1097 9.97045L15.7277 23.2905L31.8477
      23.2936V19.8321C31.8474 19.4406 31.6918 19.0652 31.4148
      18.7885C31.1379 18.5117 30.7624 18.3563 30.3709 18.3564Z"
      fill="#A7E3E3"
    />
  </svg>
</template>
