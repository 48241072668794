<script setup lang="ts" name="SailingScheduleVoyageScheduleView">
import SailingScheduleTradeLanePort from '~/components/SailingScheduleTradeLanePort.vue';
import SailingsScheduleViewButton from '~/components/SailingsScheduleViewButton.vue';

import type { SailingScheduleVoyagePort } from '~/types';

const { voyagePorts, fullScheduleView, hasSelectedPorts } = defineProps<{
  voyagePorts: SailingScheduleVoyagePort[];
  fullScheduleView: boolean;
  hasSelectedPorts: boolean;
}>();

const emit = defineEmits<{ (e: 'toggleFullScheduleView'): void }>();
</script>

<template>
  <div>
    <SailingScheduleTradeLanePort
      v-for="item in voyagePorts"
      :key="item.id"
      v-bind="item"
      :full-screen="fullScheduleView"
      :has-selected-ports="hasSelectedPorts"
    />
  </div>

  <div class="divide-y divide-charcoal-2">
    <div
      v-for="item in voyagePorts"
      :key="item.id"
      class="flex-center h-14"
      :class="{
        'bg-charcoal-1': hasSelectedPorts && fullScheduleView && item.visible,
      }"
    >
      {{ item.date }}
    </div>
  </div>

  <SailingsScheduleViewButton
    v-if="hasSelectedPorts"
    :fullVoyageView="fullScheduleView"
    @toggleFullScheduleView="emit('toggleFullScheduleView')"
  />
</template>
