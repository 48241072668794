<script setup lang="ts" name="StateModal">
import BaseModal from '~/components/BaseModal.vue';
import BaseModalActions from '~/components/BaseModalActions.vue';
import ModalForm from '~/components/ModalForm.vue';

defineProps<{
  onClose: () => void;
  title: string;
  maxWidth: string;
  loading?: boolean;
}>();

const emit = defineEmits<{
  submit: [];
}>();
</script>

<template>
  <BaseModal
    content="compact"
    :title="title"
    :max-width="maxWidth"
    @close="onClose"
  >
    <ModalForm @submit="emit('submit')">
      <div class="overflow-y-auto">
        <slot v-if="loading" name="loading">
          <div class="mx-7 space-y-2">
            <div
              v-for="index in 7"
              :key="index"
              class="animate h-08 w-full animate-pulse truncate rounded bg-charcoal-2"
            ></div>
          </div>
        </slot>

        <slot v-else />
      </div>

      <BaseModalActions
        cancel="Cancel"
        submit="Save"
        :loading="loading"
        @cancel="onClose"
      />
    </ModalForm>
  </BaseModal>
</template>
