<script setup lang="ts" name="ModalG2OSelectedListOption">
import BaseButton from '~/components/BaseButton.vue';
const { singleOption } = defineProps<{
  singleOption?: boolean;
}>();
const emit = defineEmits<{
  (e: 'delete'): void;
}>();
</script>

<template>
  <div class="flex w-full items-center justify-between">
    <div class="flex w-11/12 items-center gap-x-2">
      <slot />
    </div>

    <BaseButton
      v-if="!singleOption"
      icon="ic:baseline-delete"
      variant="btn-tertiary"
      @click="emit('delete')"
    />
  </div>
</template>
