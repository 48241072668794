import { Item, useBaseItems } from '~/features/useBaseItem';
import { capitalize } from '~/features/useUtilities';

import type { ISharedShipmentInfo, ISharedShipmentOrganisation } from '~/types';

export const getShipmentInfo = (
  shipmentInfo?: ISharedShipmentInfo,
  organisation?: ISharedShipmentOrganisation,
) =>
  useBaseItems(
    Item.label(
      capitalize(
        organisation?.type === 'agency' ? 'Shared by' : organisation?.type,
      ),
    )
      .value(organisation?.name)
      .fallback('-'),
    Item.label('Vessel / Voyage')
      .value(`${shipmentInfo?.vessel} ${shipmentInfo?.voyage}`)
      .fallback('-'),
    Item.label('Commodity').value(shipmentInfo?.commodity).fallback('-'),
  );
