import { setupWorker } from 'msw/browser';

import type { UserModule } from '~/types';

import handlers from '../mocks/handlers';

export const install: UserModule = () => {
  if (import.meta.env.VITE_USE_MOCK_SERVICE === 'true' && !window.Cypress) {
    const worker = setupWorker(...handlers);
    worker.start();
  }
};
