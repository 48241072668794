<script setup name="G2oTourFinish">
import { computed } from 'vue';

import BaseButton from '~/components/BaseButton.vue';
import SimpleCard from '~/components/Cards/SimpleCard.vue';

const props = defineProps({
  currentStep: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['prev', 'next']);

const stepIsActive = computed(() => props.id === props.currentStep.id);
</script>

<template>
  <SimpleCard v-if="stepIsActive" class="max-w-sm space-y-4 bg-white p-6">
    <img
      src="/images/illustrations/finnish-tour.svg"
      class="h-24 w-24"
      alt=""
    />
    <h2 class="subtitle-1 font-title text-lg font-bold">Congratulations!</h2>
    <p>
      You have completed MyG2 Tutorial. You can always find the tutorial in the
      Support page.
    </p>
    <div class="flex items-center justify-end space-x-4">
      <BaseButton variant="btn-secondary" @click="emit('prev')">
        Back
      </BaseButton>
      <BaseButton @click="emit('next')">Finish</BaseButton>
    </div>
  </SimpleCard>
</template>
