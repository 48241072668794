<script setup lang="ts" name="BillOfLadingInformation">
import { Item, useBaseItems } from '~/features/useBaseItem';

import BaseItem from '~/components/BaseItem.vue';
import BillOfLadingCard from '~/components/BillOfLadingCard.vue';

const { bolName, voyage, vessel } = defineProps<{
  bolName?: string;
  voyage?: number;
  vessel?: string;
}>();

const bolItems = useBaseItems(
  Item.label('Bill of Lading').value(bolName).fallback('-'),
  Item.label('Vessel / Voyage').value(`${vessel} ${voyage}`).fallback('-'),
);
</script>

<template>
  <BillOfLadingCard title="Bill of Lading Information">
    <div
      class="mt-4 grid grid-cols-1 grid-rows-2 gap-4 sm:grid-cols-3 sm:grid-rows-1 sm:gap-y-0 lg:grid-cols-4"
    >
      <BaseItem v-for="item in bolItems" :key="item.label" v-bind="item" />
    </div>
  </BillOfLadingCard>
</template>
