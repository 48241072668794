import { computed, ref } from 'vue';

import { REGISTRATION_MAXIMUM_TRADES } from '~/constants';

import type { IStatisticsTradeLane, ITradeLane } from '~/types';

import { getSearchableLane } from './useSailingSchedule';
import { isEmpty } from './useUtilities';

export const ADD_TRADE_MIN_SEARCH_LENGTH = 2;

export const usePublicSailingScheduleAddTrade = () => {
  const search = ref('');
  const searchActive = ref(false);

  const tradelanes = ref<IStatisticsTradeLane[]>([]);
  const initialTrades = ref<IStatisticsTradeLane[]>([]);
  const selectedTradelanes = ref<IStatisticsTradeLane[]>([]);

  const filteredTradeOptions = computed<IStatisticsTradeLane[]>(() =>
    tradelanes.value.filter(({ id, continents }) => {
      const checkedOption = selectedTradelanes.value.find(
        (option) => option.id === id,
      );

      const nameMatches = continents
        .toLowerCase()
        .includes(search.value.toLowerCase());

      const idMatches =
        typeof id === 'string'
          ? id.toLowerCase().includes(search.value.toLowerCase())
          : id.toString().includes(search.value);

      return !checkedOption && (nameMatches || idMatches);
    }),
  );

  const isTradelanesEmpty = computed(() => isEmpty(filteredTradeOptions.value));

  const hasReachedMaximumTrades = computed(
    () => selectedTradelanes.value.length === REGISTRATION_MAXIMUM_TRADES,
  );

  const searchTradesStatusText = computed(
    () =>
      `<b>${selectedTradelanes.value.length} of 10 added </b>. ${
        hasReachedMaximumTrades.value
          ? 'You’ve have hit the limit of trades. Don’t worry, you can add new trade by removing existing one.'
          : 'Add up to 10 new trades to your sailing schedule.'
      }`,
  );

  const hasAllSameTrades = computed(
    () =>
      initialTrades.value.every(({ id }) =>
        selectedTradelanes.value.find((trade) => trade.id === id),
      ) && initialTrades.value.length === selectedTradelanes.value.length,
  );

  const getSearchableLaneById = (data: ITradeLane[]) =>
    data.map(({ id }) => getSearchableLane(id));

  const setTradelanes = (data: ITradeLane[]) => {
    tradelanes.value = getSearchableLaneById(data);
  };

  const setInitialTradelanes = (data: ITradeLane[]) => {
    initialTrades.value = getSearchableLaneById(data);
  };

  const setSelectedTradelanes = (data: ITradeLane[]) => {
    selectedTradelanes.value = getSearchableLaneById(data);
  };

  const setSearchStatus = ({ active }: { active: boolean }) => {
    searchActive.value = active;
  };

  return {
    initialTrades,
    search,
    searchActive,
    selectedTradelanes,
    isTradelanesEmpty,
    searchTradesStatusText,
    hasReachedMaximumTrades,
    filteredTradeOptions,
    hasAllSameTrades,

    setInitialTradelanes,
    setTradelanes,
    setSelectedTradelanes,
    setSearchStatus,
  };
};
