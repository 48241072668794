<script setup lang="ts" name="VCard">
import { useSlots } from 'vue';

import VCardContent from '~/components/VCardContent.vue';
import VCardHeading from '~/components/VCardHeading.vue';
import VCardText from '~/components/VCardText.vue';

import type { IVCardTextColor, IVCardTextSize } from '~/types';

const { title, titleSize, titleColor, subtitle, height } = defineProps<{
  title?: string;
  titleSize?: IVCardTextSize;
  titleColor?: IVCardTextColor;
  subtitle?: string;
  height?: number | string;
}>();

const slots = useSlots();
</script>

<template>
  <div
    class="flex flex-col overflow-hidden rounded bg-white shadow"
    :style="{
      height: `${height}px`,
    }"
  >
    <slot name="heading">
      <VCardHeading v-if="title || $slots.title">
        <slot name="title">
          <VCardText
            v-if="title"
            :size="titleSize ?? 'text-subtitle-1'"
            :color="titleColor ?? 'text-charcoal-9'"
            :text="title!"
          />
        </slot>

        <slot name="subtitle">
          <VCardText
            v-if="subtitle"
            :size="titleSize ?? 'text-body-1'"
            :color="titleColor ?? 'text-charcoal-9'"
            :text="subtitle"
          >
            {{ subtitle }}
          </VCardText>
        </slot>
      </VCardHeading>
    </slot>

    <slot name="content">
      <VCardContent v-if="slots && slots?.default">
        <slot />
      </VCardContent>
    </slot>

    <div
      v-if="slots.actions"
      class="flex justify-end gap-x-4 rounded bg-white p-6"
    >
      <slot name="actions"> </slot>
    </div>
  </div>
</template>
