<script lang="ts" setup name="NotificationsMenu">
import { computed, ref, watch } from 'vue';

import { useUnreadNotificationsStore } from '~/store';

import Service from '~/services/Service.js';

import InfiniteScrollLoading from '~/components/InfiniteScrollLoading.vue';
import NotificationsMenuButton from '~/components/NotificationsMenuButton.vue';
import NotificationsMenuContainer from '~/components/NotificationsMenuContainer.vue';
import NotificationsRows from '~/components/NotificationsRows.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { INotification, INotificationResponse } from '~/types';

const STATE = {
  LOADING: 'LOADING',
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  NO_DATA: 'NO_DATA',
};

type State = (typeof STATE)[keyof typeof STATE];

const unreadNotificationsStore = useUnreadNotificationsStore();
const state = ref<State>(STATE.IDLE);
const stateIs = computed(() => (value: State) => state.value === value);

const scrollPosition = ref(0);
const notificationListRef = ref<HTMLDivElement>();

const pageCount = ref(0);
const query = ref({
  page: 0,
  per_page: 5,
});

const notifications = ref<INotification[]>([]);
const isFetchingNotifications = ref(false);
const emptyNotifications = computed(() => notifications.value.length === 0);
const totalUnread = ref(0);

const fetchNotifications = () => {
  Service.notifications()
    .index()
    .data(query.value)
    .onStart(() => {
      if (emptyNotifications.value) state.value = STATE.LOADING;
    })
    .onSuccess((response: INotificationResponse) => {
      const newNotifications = response?.data || [];
      notifications.value = [...notifications.value, ...newNotifications];
      pageCount.value = response.meta.last_page;
      totalUnread.value = response.meta.total_unread;
    })
    .onFinish(() => {
      isFetchingNotifications.value = false;

      if (emptyNotifications.value) {
        state.value = STATE.NO_DATA;
        return;
      }

      state.value = STATE.IDLE;
    })
    .execute();
};

const handleScroll = (e: Event) => {
  if (query.value.page >= pageCount.value) return;

  const { scrollTop, clientHeight, scrollHeight } =
    e.target as HTMLButtonElement;

  if (scrollTop + clientHeight >= scrollHeight) {
    scrollPosition.value = scrollTop + clientHeight;

    if (!isFetchingNotifications.value) incrementPage();

    isFetchingNotifications.value = true;
  }
};

const resetNotificationState = () => {
  window.removeEventListener('scroll', handleScroll);
  notifications.value = [];
  query.value.page = 0;
  scrollPosition.value = 0;

  if (notificationListRef.value) notificationListRef.value.scrollTop = 0;
};

const openNotificationsList = (toggle: () => void) => {
  unreadNotificationsStore.reset();
  toggle();
};

const incrementPage = () => {
  query.value.page += 1;
};

const onOpen = () => {
  incrementPage();
};

const onClose = () => {
  resetNotificationState();
};

watch(
  () => query.value.page,
  () => {
    if (query.value.page > 0) fetchNotifications();
  },
);
</script>

<template>
  <XDropdown @open="onOpen" @close="onClose" @click-outside="onClose">
    <template #trigger="{ toggle, isOpen }">
      <NotificationsMenuButton
        data-test="notification-bell"
        :is-open="isOpen"
        :total-unread="unreadNotificationsStore.total"
        @click="openNotificationsList(toggle)"
      />
    </template>

    <template #default="{ close }">
      <NotificationsMenuContainer
        :total-unread="totalUnread"
        :emptyNotifications="emptyNotifications"
        @close="close"
      >
        <div
          ref="notificationListRef"
          data-test="notification-list"
          class="h-[440px] w-full overflow-y-auto"
          @scroll.passive="handleScroll"
        >
          <InfiniteScrollLoading
            v-if="stateIs(STATE.LOADING)"
            :height-full="!notifications.length"
          />

          <div
            v-else-if="stateIs(STATE.NO_DATA)"
            class="flex h-full flex-col items-center justify-center"
          >
            <p class="pb-6 text-charcoal-6">No new notifications</p>
            <img
              src="/images/illustrations/no-data.svg"
              alt=""
              class="h-48 w-40"
            />
          </div>

          <NotificationsRows
            v-else-if="stateIs(STATE.IDLE)"
            :notifications="notifications"
            @close="close"
          >
            <InfiniteScrollLoading
              v-if="isFetchingNotifications"
              class="bg-white pb-8 sm:pb-4"
            />
          </NotificationsRows>
        </div>
      </NotificationsMenuContainer>
    </template>
  </XDropdown>
</template>
