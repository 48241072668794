<script setup lang="ts" name="G2oTour">
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  useDevFeaturesStore,
  useOrganisationsStore,
  useProfileStore,
} from '~/store';

import { useCustomerSteps, useG2OSteps } from '~/features/useG2OTourSteps';
import { getHomeRouteBasedOnUser } from '~/features/useRouteHelpers';
import { injectSidebarState } from '~/features/useSidebarState';

import type { IG2OTourStepIndexed } from '~/types';

const router = useRouter();
const profileStore = useProfileStore();
const organisationStore = useOrganisationsStore();

const sidebarState = injectSidebarState();

const internalSteps = useG2OSteps();
const externalSteps = computed(() => {
  const organisationHasKpis =
    (organisationStore.tourOrganisation?.kpisCount || 0) > 0;

  return useCustomerSteps({
    isG2OEmployee: profileStore.isG2OEmployee,
    kpisAreVisible: organisationHasKpis,
    organisation: organisationStore.tourOrganisation,
  });
});

const steps = computed<IG2OTourStepIndexed[]>(() => {
  let stepIndex = 0;

  const allSteps = profileStore.isG2OEmployee
    ? [...internalSteps, ...externalSteps.value]
    : externalSteps.value;

  return allSteps.map((step, index) => {
    if (step.type !== 'dialog') {
      stepIndex += 1;
    }

    return {
      ...step,
      stepLabel: stepIndex,
      index,
    };
  });
});

const totalStepsLabel = computed(
  () => steps.value.filter((x) => x.type !== 'dialog').length,
);

const currentStepIndex = ref(0);
const currentStep = computed<IG2OTourStepIndexed | null>(() => {
  if (currentStepIndex.value > steps.value.length - 1) {
    return null;
  }

  return steps.value[currentStepIndex.value];
});

const onClose = () => {
  profileStore.completeTour();
};

const setOrganisationWithAllFeatures = async () => {
  if (!organisationStore.tourOrganisation) {
    return;
  }
  const organisationId = organisationStore.tourOrganisation.id;

  organisationStore.setById(organisationId);

  await router.push({
    name: 'OrganisationHomeIndex',
    params: { organisationId },
  });
};

const onNext = async () => {
  if (currentStep.value?.id === 'switch-organisation') {
    await setOrganisationWithAllFeatures();
  }

  currentStepIndex.value += 1;

  if (!currentStep.value) {
    onClose();
  }
};

const onPrev = async () => {
  const currentIndex = currentStep.value?.index ?? 0;
  const previousStep = steps.value[currentIndex - 1];
  if (previousStep?.id === 'switch-organisation') {
    await router.push(getHomeRouteBasedOnUser());
  }

  currentStepIndex.value -= 1;
};

const stepIsDialog = computed(() => currentStep.value?.type === 'dialog');

onBeforeMount(async () => {
  if (profileStore.isG2OEmployee) {
    router.push(getHomeRouteBasedOnUser());
    return;
  }

  await setOrganisationWithAllFeatures();
});
</script>

<template>
  <div class="fixed inset-0 flex h-screen w-screen items-center justify-center">
    <div
      class="flex-center fixed inset-0 bg-black/50"
      :class="{
        'ml-sidebar': !stepIsDialog && !sidebarState.closed.value,
        'ml-sidebar-closed': !stepIsDialog && sidebarState.closed.value,
      }"
    >
      <component
        :is="step.component"
        v-for="step in steps"
        v-bind="step"
        :key="step.id"
        :current-step="currentStep"
        :total-steps="totalStepsLabel"
        @close="onClose"
        @next="onNext"
        @prev="onPrev"
      />
    </div>
  </div>
</template>
