<script setup lang="ts" name="TheSnackbar">
import { computed } from 'vue';

import { SNACKBAR_TYPE } from '~/constants';

import Icon from '~/components/Icon.vue';

import type { ISnackbar } from '~/types';

const { snackbar } = defineProps<{
  snackbar: ISnackbar;
}>();

const emit = defineEmits<{ (e: 'close'): void }>();

const colors = {
  [SNACKBAR_TYPE.SUCCESS]: 'bg-success-6',
  [SNACKBAR_TYPE.ERROR]: 'bg-danger-6',
  [SNACKBAR_TYPE.INFO]: 'bg-info-4',
};

const backgroundColor = computed(() => snackbar.type && colors[snackbar.type]);
</script>

<template>
  <div
    class="snackbar flex items-center justify-between gap-x-4 rounded p-4 text-white shadow-md"
    :class="backgroundColor"
  >
    <div class="flex items-center gap-x-2">
      <Icon v-if="snackbar.icon" :icon="snackbar.icon" />

      <div v-if="snackbar.html" v-html="snackbar.html" />

      <div v-else class="flex items-center">
        <div class="flex flex-col">
          <p
            v-if="snackbar.title"
            class="text-subtitle-2 font-bold !text-white"
          >
            {{ snackbar.title }}
          </p>

          <p class="text-body-1">
            {{ snackbar.text }}
          </p>
        </div>
      </div>
    </div>

    <button
      data-test="snackbar-close"
      type="button"
      class="flex"
      @click="emit('close')"
    >
      <Icon icon="ic:round-close" class="text-white" />
    </button>
  </div>
</template>

<style #scoped>
.snackbar-fade-enter-active {
  transition: opacity 0.3s ease;
}

.snackbar-fade-leave-active {
  transition: opacity 2s ease;
}

.snackbar-fade-enter,
.snackbar-fade-leave-to {
  opacity: 0;
}

.snackbar {
  min-width: 344px;
  max-width: 600px;
  min-height: 48px;
}

@media screen and (max-width: 500px) {
  .snackbar {
    min-width: 250px;
  }
}
</style>
