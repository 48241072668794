<script setup lang="ts" name="PublicUserMyDetails">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useProfileStore } from '~/store';

import Card from '~/components/Card.vue';
import UserDetails from '~/components/UserDetails.vue';

const profileStore = useProfileStore();

const router = useRouter();

const profile = computed(() => profileStore?.profile);

const onEditProfile = () => {
  router.push({ name: 'PublicProfileEdit' });
};
</script>

<template>
  <Card>
    <UserDetails
      :email="profile?.email"
      :firstName="profile?.firstName"
      :lastName="profile?.lastName"
      :editable="!profileStore.isG2ocean"
      @edit="onEditProfile"
    />
  </Card>
</template>
