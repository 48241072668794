import { createApp, markRaw } from 'vue';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';

import { useAuthStore, useProfileStore } from '~/store';
import { usePiniaPlugin } from '~/store/usePiniaPlugin';

import 'v-calendar/style.css';
import 'uno.css';
import 'element-plus/es/components/date-picker/style/css';
import './index.css';
import App from './App.vue';
import { router } from './routes';
import type { UserModuleFile } from './types';

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

pinia.use(usePiniaPlugin);

const app = createApp(App);

app.use(pinia);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DNS,
  logErrors: true,

  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'dev.myg2.g2ocean.com', /^\//],
    }),
    new Sentry.Replay(),
  ],
});

app.use(router);

if (window.Cypress) {
  window.store = {
    authStore: useAuthStore(),
    profileStore: useProfileStore(),
  };
}
// install all modules under `modules/**.*.ts */`
const modules = import.meta.glob<UserModuleFile>('./modules/*.ts', {
  eager: true,
});

Object.values(modules).forEach((i) => i.install?.({ app, router, pinia }));

window.Vue = app;

app.mount('#app');
