<script lang="ts" setup name="SailingScheduleFilters">
import { useVModel } from '@vueuse/core';

import FilterMenuSelectMultiple from '~/components/FilterMenuSelectMultiple.vue';
import FilterMenuSelectMultipleTrades from '~/components/FilterMenuSelectMultipleTrades.vue';
import FiltersLayout from '~/components/FiltersLayout.vue';
import FilterTag from '~/components/FilterTag.vue';

import type {
  IFilter,
  ISailingScheduleOption,
  ISailingScheduleQuery,
  IStatisticsTradeLane,
} from '~/types';

const props = defineProps<{
  modelValue: ISailingScheduleQuery;
  activeFilters: any[];
  tradelanes: IStatisticsTradeLane[];
  loadingPorts: ISailingScheduleOption[];
  dischargePorts: ISailingScheduleOption[];
  vessels: ISailingScheduleOption[];
}>();

const emit = defineEmits<{
  (e: 'clearFilters'): void;
  (e: 'update:modelValue'): void;
  (e: 'clearFilter', filter: IFilter): void;
}>();

const query = useVModel(props, 'modelValue', emit);
</script>

<template>
  <FiltersLayout
    class="relative rounded bg-white drop-shadow"
    @clear="emit('clearFilters')"
  >
    <FilterMenuSelectMultipleTrades
      v-model="query.tradelanes"
      label="Trade"
      searchable
      sorted
      :options="tradelanes"
    />
    <FilterMenuSelectMultiple
      v-model="query.loadingPorts"
      label="Load Port"
      searchable
      sorted
      :options="loadingPorts"
    />
    <FilterMenuSelectMultiple
      v-model="query.dischargePorts"
      label="Discharge Port"
      searchable
      sorted
      :options="dischargePorts"
    />
    <FilterMenuSelectMultiple
      v-model="query.vessels"
      label="Vessel"
      searchable
      sorted
      :options="vessels"
    />

    <template v-if="activeFilters.length" #tags>
      <FilterTag
        v-for="filter in activeFilters"
        :key="filter.name"
        :name="filter.label"
        @click="emit('clearFilter', filter)"
      />
    </template>

    <template #actions>
      <slot />
    </template>
  </FiltersLayout>
</template>
