<script setup lang="ts" name="OrganisationHomeStatisticsWidgetsHeader">
import Icon from '~/components/Icon.vue';
const { spectator } = defineProps<{
  spectator?: boolean;
}>();

const emit = defineEmits<{
  (e: 'open'): void;
}>();
</script>

<template>
  <div
    class="flex items-center justify-between rounded-b-md bg-white p-6 drop-shadow"
  >
    <p class="text-subtitle-1 text-charcoal-9">Statistics</p>
    <button
      v-if="!spectator"
      data-testid="home-statistics-widgets-settings"
      v-tooltip.bottom.min="'Customise statistics'"
      type="button"
      class="grid h-08 w-08 place-items-center rounded-md text-primary-8 outline-none hover:bg-charcoal-2"
      @click="emit('open')"
    >
      <Icon icon="g2o:tune-rounded" size="3xl" />
    </button>
  </div>
</template>
