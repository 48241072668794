import type { AxiosResponse } from 'axios';
import axios, { AxiosError } from 'axios';

import { LOCAL_STORAGE_KEYS } from '~/constants';
import { useAuthStore } from '~/store';

import type { UserModule } from '~/types';

// Setup Axios instance
export const install: UserModule = () => {
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
  );

  window.axios = axios;

  window.axios.defaults.baseURL = import.meta.env.VITE_API_ENDPOINT;
  window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: accessToken || null,
  };
  window.axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        useAuthStore().logout401();
        return;
      }
      throw error;
    },
  );
};
