import type { RouteRecordRaw } from 'vue-router';

import { ORG_FEATURES, PERMISSION } from '~/constants';
import { useDevFeaturesStore } from '~/store';

import { makeKeyBuilder, makeOrganisationRoute } from '~/features/useRouteMeta';

const OrganisationStatisticsLaytime = () =>
  import('~/pages/OrganisationStatisticsLaytime.vue');
const OrganisationStatisticsLaytimeView = () =>
  import('~/pages/OrganisationStatisticsLaytimeView.vue');
const OrganisationStatisticsV2 = () =>
  import('~/pages/OrganisationStatisticsV2.vue');
const OrganisationStatisticsV2Bunkers = () =>
  import('~/pages/OrganisationStatisticsV2Bunkers.vue');
const OrganisationStatisticsV2Claims = () =>
  import('~/pages/OrganisationStatisticsV2Claims.vue');
const OrganisationStatisticsV2Emissions = () =>
  import('~/pages/OrganisationStatisticsV2Emissions.vue');
const OrganisationStatisticsV2Invoices = () =>
  import('~/pages/OrganisationStatisticsV2Invoices.vue');
const OrganisationStatisticsV2InvoicesOpen = () =>
  import('~/pages/OrganisationStatisticsV2InvoicesOpen.vue');
const OrganisationStatisticsV2InvoicesPaid = () =>
  import('~/pages/OrganisationStatisticsV2InvoicesPaid.vue');
const OrganisationStatisticsV2LaycanPerformance = () =>
  import('~/pages/OrganisationStatisticsV2LaycanPerformance.vue');
const OrganisationStatisticsV2Productivity = () =>
  import('~/pages/OrganisationStatisticsV2Productivity.vue');
const OrganisationStatisticsV2ShipmentVolumes = () =>
  import('~/pages/OrganisationStatisticsV2ShipmentVolumes.vue');
const OrganisationStatisticsV2TransitTime = () =>
  import('~/pages/OrganisationStatisticsV2TransitTime.vue');
const OrganisationStatisticsWaitingForBerth = () =>
  import('~/pages/OrganisationStatisticsWaitingForBerth.vue');

export const organisationStatisticsRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    path: '/statistics',
    component: OrganisationStatisticsV2,
    permission: PERMISSION.DASHBOARD_VIEW_OWN,
    orgFeature: ORG_FEATURES.STATISTICS_AVAILABLE,
    keyBuilder: makeKeyBuilder('OrganisationStatisticsV2'),
    children: [
      {
        name: 'OrganisationStatisticsV2',
        path: '',
        redirect: { name: 'OrganisationStatisticsV2ShipmentVolumes' },
      },
      {
        name: 'OrganisationStatisticsV2ShipmentVolumes',
        path: 'shipment-volumes',
        component: OrganisationStatisticsV2ShipmentVolumes,
        props: true,
      },
      {
        name: 'OrganisationStatisticsV2LaycanPerformance',
        path: 'laycan',
        component: OrganisationStatisticsV2LaycanPerformance,
        props: true,
      },
      {
        name: 'OrganisationStatisticsV2TransitTime',
        path: 'transit-time',
        component: OrganisationStatisticsV2TransitTime,
        props: (route) => ({
          organisationId: route.params.organisationId,
          trade: route.query.trade,
        }),
      },
      {
        path: 'invoices',
        component: OrganisationStatisticsV2Invoices,
        props: true,
        children: [
          {
            name: 'OrganisationStatisticsV2Invoices',
            path: '',
            redirect: { name: 'OrganisationStatisticsV2InvoicesOpen' },
          },
          {
            name: 'OrganisationStatisticsV2InvoicesOpen',
            path: 'open',
            component: OrganisationStatisticsV2InvoicesOpen,
            props: true,
          },
          {
            name: 'OrganisationStatisticsV2InvoicesPaid',
            path: 'paid',
            component: OrganisationStatisticsV2InvoicesPaid,
            props: true,
          },
        ],
      },
      {
        name: 'OrganisationStatisticsLaytime',
        path: 'laytime',
        component: OrganisationStatisticsLaytime,
        props: true,
      },
      {
        name: 'OrganisationStatisticsLaytimeView',
        path: 'laytime/:id/name/:name',
        component: OrganisationStatisticsLaytimeView,
        props: true,
      },
      {
        name: 'OrganisationStatisticsV2Emissions',
        path: 'emissions',
        component: OrganisationStatisticsV2Emissions,
        props: true,
      },
      {
        name: 'OrganisationStatisticsV2Productivity',
        path: 'productivity',
        component: OrganisationStatisticsV2Productivity,
        props: true,
      },
      {
        name: 'OrganisationStatisticsV2Bunkers',
        path: 'bunkers',
        component: OrganisationStatisticsV2Bunkers,
        props: true,
      },
      {
        name: 'OrganisationStatisticsV2Claims',
        path: 'claims',
        component: OrganisationStatisticsV2Claims,
        props: true,
      },
      {
        name: 'OrganisationStatisticsWaitingForBerth',
        path: 'waiting-for-berth',
        component: OrganisationStatisticsWaitingForBerth,
        props: true,
        beforeEnter: (_, __, next) =>
          useDevFeaturesStore().STATISTICS_WAITING_FOR_BERTH
            ? next(true)
            : next('/404'),
      },
    ],
  }),
];
