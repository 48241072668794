import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

import { Item, useBaseItems } from '~/features/useBaseItem';

import type { ISharedContact, ISharedContactCard } from '~/types';

const formatAddress = (value: string | null) =>
  value ? value.split(';').join('<br>') : value;

const getContentItems = (contacts: ISharedContact) =>
  useBaseItems(
    Item.label('Phone').value(contacts.phone).preventTruncate().fallback('-'),
    Item.label('Fax').value(contacts.fax).fallback('-'),
    Item.label('Address')
      .value(formatAddress(contacts.address))
      .preventTruncate()
      .useHtml(contacts.address?.includes(';'))
      .fallback('-'),
    Item.label('Email').value(contacts.email).fallback('-'),
  );

const getHeadingItems = (items: ISharedContact, label: string) =>
  useBreakpoints(breakpointsTailwind).isGreater('sm')
    ? useBaseItems(
        Item.label('Port Name').value(items.portName).fallback('-'),
        Item.label(label).value(items.agentName).fallback('-'),
      )
    : useBaseItems(
        Item.label('Port Name').value(items.portName).fallback('-'),
        Item.label(label)
          .value(items.agentName)
          .fallback('-')
          .preventTruncate(),
      );

const getItems = (items: ISharedContact[], label: string) =>
  items.map((contacts) => ({
    triggerItems: getHeadingItems(contacts, label),
    contentItems: getContentItems(contacts),
  }));

export const usePortContacts = (
  contacts: ISharedContact[],
  cardTitle: string,
  provider: string,
  id: string,
): ISharedContactCard => ({
  id,
  title: cardTitle,
  items: getItems(contacts, provider),
});
