import {
  MILESTONE_STATUS_TO_VOYAGE_TRACKER_STEP_STATUS,
  VOYAGE_TRACKER_STEP_STATUS,
} from '~/constants';

import type {
  IPortsAndDates,
  IPortsAndDatesEvents,
  IPortsAndDatesInBetween,
  IVoyageTrackerStep,
  VoyageTrackerEventStatus,
} from '~/types';

import { getDateYear, getTimeFromDate } from './useDates';

type ShipmentMilestoneEvents = 'arrivalDate' | 'berthedDate' | 'departureDate';

const withMeta = (
  port: IVoyageTrackerStep,
  index: number,
  allPorts: IVoyageTrackerStep[],
): IVoyageTrackerStep => {
  const prev = allPorts[index - 1];
  const first = index === 0;
  const last = index === allPorts.length - 1;
  let { stepStatus } = port;

  if (
    prev &&
    prev.stepStatus === VOYAGE_TRACKER_STEP_STATUS.COMPLETED &&
    stepStatus === VOYAGE_TRACKER_STEP_STATUS.UPCOMING
  ) {
    stepStatus = VOYAGE_TRACKER_STEP_STATUS.ACTIVE;
  }

  return {
    ...port,
    stepStatus,
    hideTopBorder: first,
    hideBottomBorder: last,
  };
};

const getEventName = (
  key: ShipmentMilestoneEvents,
  happened: boolean,
): VoyageTrackerEventStatus => {
  if (key === 'arrivalDate') return happened ? 'Arrived' : 'Est. Arrival';

  if (key === 'berthedDate') return happened ? 'Berthed' : 'Est. Berth';

  return happened ? 'Departed' : 'Est. Departure';
};

const spreadPortAndEvents = (
  acc: IVoyageTrackerStep[],
  index: number,
  portData: IPortsAndDatesEvents,
): IVoyageTrackerStep[] => {
  const primaryPort: IVoyageTrackerStep = {
    id: `${portData.name}-${index}`,
    port: {
      type: portData.type === 'L' ? 'Load Port' : 'Discharge Port',
      name: portData.name,
      swap: true,
    },
    stepType: 'primary',
    padding: 'large',
    stepStatus: VOYAGE_TRACKER_STEP_STATUS.UPCOMING,
  };

  const secondaryPorts: IVoyageTrackerStep[] = [];
  const events: ShipmentMilestoneEvents[] = [
    'arrivalDate',
    'berthedDate',
    'departureDate',
  ];

  for (const key of events) {
    const event = portData[key];
    if (!event) continue;
    secondaryPorts.push({
      id: `${primaryPort?.port?.name}-${key}`,
      port: {
        date: getDateYear(event.date),
        time: getTimeFromDate(event.date),
        eventStatus: getEventName(key, event.happened),
      },
      stepStatus: event.happened
        ? VOYAGE_TRACKER_STEP_STATUS.COMPLETED
        : VOYAGE_TRACKER_STEP_STATUS.UPCOMING,
      padding: 'medium',
      stepType: 'secondary',
    });
  }

  const secondaryPortsAllCompleted = secondaryPorts.every(
    (event) => event.stepStatus === VOYAGE_TRACKER_STEP_STATUS.COMPLETED,
  );

  if (secondaryPortsAllCompleted) {
    primaryPort.stepStatus = VOYAGE_TRACKER_STEP_STATUS.COMPLETED;
  } else {
    const [arrivalEvent] = secondaryPorts;
    const isArrivalCompleted =
      arrivalEvent.stepStatus === VOYAGE_TRACKER_STEP_STATUS.COMPLETED;

    primaryPort.stepStatus = isArrivalCompleted
      ? VOYAGE_TRACKER_STEP_STATUS.ACTIVE_IN_PROGRESS
      : VOYAGE_TRACKER_STEP_STATUS.UPCOMING;
  }

  return [...acc, primaryPort, ...secondaryPorts];
};

const getInBetweenPort = (
  acc: IVoyageTrackerStep[],
  index: number,
  port: IPortsAndDatesInBetween,
): IVoyageTrackerStep[] => {
  return [
    ...acc,
    {
      id: `${port.name}-${index}`,
      port: {
        name: port.name,
        date: getDateYear(port.date),
        time: getTimeFromDate(port.date),
        eventStatus: port.status === 'est_arrival' ? 'Est. Arrival' : 'Arrived',
      },
      padding: 'large',
      inBetween: true,
      stepType: 'primary',
      stepStatus: MILESTONE_STATUS_TO_VOYAGE_TRACKER_STEP_STATUS[port.status],
    },
  ];
};

export const useShipmentMilestones = (
  ports: IPortsAndDates[],
  showInBetween = false,
): IVoyageTrackerStep[] =>
  ports
    .reduce<IVoyageTrackerStep[]>((acc, port, index) => {
      if ('type' in port) {
        return spreadPortAndEvents(acc, index, port);
      }
      if (showInBetween) {
        return getInBetweenPort(acc, index, port);
      }

      return acc;
    }, [])
    .map(withMeta);
