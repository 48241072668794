<script setup name="ProfileMenu">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

import { useAuthStore, useProfileStore } from '~/store';

import ProfileMenuButton from '~/components/ProfileMenuButton.vue';
import XDropdown from '~/components/XDropdown.vue';

const store = useProfileStore();

const userProfile = computed(() => store.profile);

const onLogout = async (close) => {
  close();
  useAuthStore().logout();
};

const toMyDetails = store.isPublic
  ? {
      name: 'PublicUserProfile',
    }
  : {
      name: 'AuthProfile',
    };
</script>

<template>
  <XDropdown>
    <template #trigger="{ toggle, isOpen }">
      <ProfileMenuButton
        v-bind="userProfile"
        :is-open="isOpen"
        @click="toggle"
      />
    </template>
    <template #default="{ close }">
      <div class="flex min-w-max flex-col items-start">
        <RouterLink
          :to="toMyDetails"
          class="w-full p-4 text-left text-secondary-9 hover:bg-charcoal-1"
          @click="close"
        >
          My details
        </RouterLink>
        <button
          class="w-full p-4 text-left text-red-6 hover:bg-charcoal-1"
          @click="onLogout(close)"
        >
          Logout
        </button>
      </div>
    </template>
  </XDropdown>
</template>
