import { http, type HttpHandler, HttpResponse } from 'msw';

import type { IShipmentVolumesKpi } from '~/types';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;
const index = `${baseEndpoint}/api/customer/organisations/1/kpi-settings/shipmentVolumes`;
const show = `${baseEndpoint}/api/customer/organisations/1/kpi-settings/shipmentVolumes/1`;
const edit = `${baseEndpoint}/api/customer/organisations/1/kpi-settings/shipmentVolumes/1/edit`;
const destroy = `${baseEndpoint}/api/customer/organisations/1/kpi-settings/shipmentVolumes/1`;

let id = 1;

const getContract = (status: string): IShipmentVolumesKpi => {
  const newId = id++;
  return {
    id: newId.toString(),
    name: `Holz.EU-ENA.202${newId}`,
    periodStart: `${newId} Jan 2022`,
    periodEnd: '31 Dec 2023',
    visible: newId % 2 === 0,
    createdAt: `1${newId} Feb 2023`,
    status,
  };
};

export default [
  http.get(index, async () => {
    return HttpResponse.json({
      data: {
        kpis: [
          getContract('active'),
          getContract('active'),
          getContract('active'),
          getContract('active'),
          getContract('upcoming'),
          getContract('expired'),
          getContract('expired'),
        ],
      },
    });
  }),
  http.get(show, async () => {
    return HttpResponse.json({
      data: {
        kpiTargets: [
          {
            periodStart: '2023-01-01',
            periodEnd: '2023-04-01',
            periodMinVolume: '10',
            periodMaxVolume: null,
            shipmentMinVolume: null,
            shipmentMaxVolume: null,
          },
          {
            periodStart: '2024-01-01',
            periodEnd: '2024-04-01',
            periodMinVolume: '10',
            periodMaxVolume: '20',
            shipmentMinVolume: '30',
            shipmentMaxVolume: '40',
          },
          {
            periodStart: '2024-01-01',
            periodEnd: '2024-04-01',
            periodMinVolume: null,
            periodMaxVolume: null,
            shipmentMinVolume: null,
            shipmentMaxVolume: '40',
          },
        ],
        tradeContract: {
          id: 21,
          name: 'ESA-ENA.2017.CMPC',
          periodStart: '1 Jan 2017',
          periodEnd: '31 Dec 2020',
        },
        visible: true,
      },
    });
  }),
  http.get(edit, async () => {
    const selectedKpi = getContract('active');
    return HttpResponse.json({
      data: {
        contracts: [
          selectedKpi,
          getContract('active'),
          getContract('active'),
          getContract('active'),
          getContract('active'),
          getContract('upcoming'),
          getContract('expired'),
          getContract('expired'),
        ],
        kpi: {
          kpiTargets: [
            {
              periodStart: '2023-01-01',
              periodEnd: '2023-04-01',
              periodMinVolume: '10',
              periodMaxVolume: null,
              shipmentMinVolume: null,
              shipmentMaxVolume: null,
            },
          ],
          tradeContract: selectedKpi,
          visible: true,
        },
      },
    });
  }),
  http.delete(destroy, async () => {
    return new HttpResponse(null, { status: 200 });
  }),
] satisfies HttpHandler[];
