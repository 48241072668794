<script setup lang="ts" name="CookieLaw">
import { computed, Transition } from 'vue';
import { RouterLink } from 'vue-router';

import { useAnalyticsStore, useAuthStore, useProfileStore } from '~/store';

const analyticsStore = useAnalyticsStore();

const learnMoreLink = computed(() =>
  useProfileStore().isAuthenticated
    ? {
        name: 'CookiePolicy',
      }
    : { name: 'PublicCookiePolicy' },
);
</script>

<template>
  <Transition
    enter-active-class="transition duration-200 ease-in"
    leave-active-class="transition duration-200 ease-in"
    enter-class="transform translate-y-full"
    enter-to-class="transform translate-y-0"
    leave-class="transform translate-y-0"
    leave-to-class="transform translate-y-full"
  >
    <div
      v-if="analyticsStore.shouldShowCookieLaw"
      class="fixed bottom-0 left-0 z-50 flex w-full flex-wrap items-center justify-between space-y-8 bg-secondary-9/70 p-8 md:space-x-8 md:space-y-0"
    >
      <p class="text-white">
        This website uses cookies to ensure you get the best experience on our
        website.
        <RouterLink
          :to="learnMoreLink"
          target="_blank"
          class="font-bold hover:underline"
        >
          Learn more
        </RouterLink>
      </p>
      <div class="flex items-center space-x-4">
        <button
          type="button"
          class="rounded-lg px-4 py-3 text-xs uppercase text-white hover:underline"
          @click="analyticsStore.rejectCookieLaw"
        >
          Reject
        </button>
        <button
          type="button"
          class="rounded-lg bg-orange-6 px-4 py-3 text-xs uppercase text-secondary-9 hover:bg-secondary-3"
          @click="analyticsStore.acceptCookieLaw"
        >
          Accept
        </button>
      </div>
    </div>
  </Transition>
</template>
