<script lang="ts" setup name="NotificationsRows">
import { NOTIFICATION_SIZE } from '~/constants';

import NotificationsRow from '~/components/NotificationsRow.vue';

import type { INotification } from '~/types';

const { notifications, large = false } = defineProps<{
  notifications: INotification[];
  large?: boolean;
}>();

const emit = defineEmits<{ (e: 'close'): void }>();
</script>

<template>
  <div
    class="grid gap-y-[1px] overflow-hidden bg-charcoal-3"
    :class="{ 'rounded shadow': large }"
  >
    <NotificationsRow
      v-for="(notification, index) in notifications"
      v-bind="notification"
      :key="index"
      :size="large ? NOTIFICATION_SIZE.LARGE : NOTIFICATION_SIZE.DEFAULT"
      @close="emit('close')"
    />
    <slot />
  </div>
</template>
