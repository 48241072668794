<script setup name="LogoMYG2O"></script>

<template>
  <svg
    width="76"
    height="32"
    viewBox="0 0 76 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6 6V21.961H14.685V12.16C14.685 11.9547 14.6887 11.7347 14.696 11.5C14.7107 11.2653
      14.7327 11.027 14.762 10.785L10.197 19.442C9.94767 19.9187 9.56267 20.157 9.042
      20.157H8.58C8.3233320.157 8.096 20.0983 7.898 19.981C7.7 19.8563 7.54233 19.6767 7.425
      19.442L2.838 10.752C2.86 11.0087 2.87833 11.258 2.893 11.5C2.90767 11.7347 2.915
      11.9547 2.915 12.16V21.961H0V6H2.508C2.64733 6 2.772 6.00367 2.882 6.011C2.992 6.01833
      3.08733 6.04033 3.168 6.077C3.256 6.10633 3.333 6.15767 3.399 6.231C3.47233 6.297 3.542
      6.39233 3.608 6.517L8.074 15.031C8.21333 15.2877 8.34167 15.5517 8.459 15.823C8.58367
      16.0943 8.701 16.373 8.811 16.659C8.921 16.3657 9.03467 16.0797 9.152 15.801C9.27667
      15.5223 9.40867 15.2547 9.548 14.998L13.981 6.517C14.047 6.39233 14.1167 6.297 14.19
      6.231C14.2633 6.15767 14.3403 6.10633 14.421 6.077C14.509 6.04033 14.6043 6.01833
      14.707 6.011C14.817 6.00367 14.9453 6 15.092 6H17.6Z"
      fill="#266764"
    />
    <path
      d="M30.7875 10.62L24.6715 25.008C24.5761 25.2133 24.4588 25.3673 24.3195 25.47C24.1875
      25.5727 23.9748 25.624 23.6815 25.624H21.4265L23.5715 21.048L19.0175 10.62H21.6795C21.9215
      10.62 22.1085 10.675 22.2405 10.785C22.3725 10.895 22.4678 11.0233 22.5265 11.17L24.6825
      16.593C24.7631 16.8057 24.8328 17.022 24.8915 17.242C24.9575 17.4547 25.0198 17.6673 25.0785
      17.88C25.2105 17.4473 25.3608 17.0147 25.5295 16.582L27.5315 11.17C27.5901 11.0087 27.6965
      10.8767 27.8505 10.774C28.0045 10.6713 28.1731 10.62 28.3565 10.62H30.7875Z"
      fill="#266764"
    />
    <path
      d="M60.2522 14.5511H50.9295C50.3996 14.5511 49.8914 14.7616 49.5167 15.1363C49.1419 15.511
      48.9314 16.0193 48.9314 16.5492V20.0841H55.0114C53.7078 21.972 51.4014 23.1881 48.9277
      23.1881C45.0704 23.1881 41.5068 20.0414 41.5074 15.7235C41.5074 11.4698 44.8846 8.35829
      48.7522 8.26541H49.0222C49.0713 8.26541 49.1177 8.27243 49.1663 8.27405H54.1112L59.9255
      0.00270844H49.0713C48.9995 0.00270844 48.9271 0.00270844 48.8553
      0.00270844H48.6393V0.00810846C48.4622 0.0113485 48.2861 0.0118884 48.1079 0.0210686C39.8431
      0.445517 33.2776 7.38735 33.2474 15.6636C33.215 24.3685 40.2275 31.4448 48.926 31.4464C50.13
      31.4453 51.3298 31.306 52.502 31.0312L61.8847 17.6929C62.8113 16.371 61.8669
      14.5517 60.2522 14.5511Z"
      fill="#266764"
    />
    <path
      d="M74.0063 24.7805H67.1109L73.1082 16.27C73.1082 16.27 74.9956 13.7546 74.9983
      10.4843C74.9983 5.36878 70.9331 0.00108002 64.8115 0C64.7467 0 64.683 0.00324005 64.6187
      0.00378006H61.93L56.1054 8.27296H61.5201C61.8483 7.76635 62.2981 7.35 62.8285 7.06192C63.3589
      6.77384 63.953 6.6232 64.5566 6.62377C66.7788 6.62377 68.2881 8.63693 68.2875 10.2305C68.2875
      11.4466 67.7 12.4948 66.9035 13.4592L54.2375 31.4416L76
      31.4459V26.7726C75.9997 26.2441 75.7895
      25.7373 75.4156 25.3638C75.0417 24.9902 74.5348 24.7804 74.0063 24.7805Z"
      fill="#66BCBC"
    />
  </svg>
</template>
