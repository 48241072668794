<script lang="ts" setup name="OrganisationHomeTheYourShipments">
import { computed } from 'vue';

import { SHIPMENTS_STATUS } from '~/constants';

import BaseCard from '~/components/BaseCard.vue';
import BaseCardLink from '~/components/BaseCardLink.vue';
import BaseDivideWrapper from '~/components/BaseDivideWrapper.vue';
import OrganisationHomeTheYourShipmentCount from '~/components/OrganisationHomeTheYourShipmentCount.vue';

import type { IShipmentStatus } from '~/types';

const { shipmentsPath, shipmentsCount, loading } = defineProps<{
  shipmentsPath?: {
    name: string;
    params: {
      organisationId: OrganisationId;
    };
  };
  shipmentsCount?: Record<IShipmentStatus, number>;
  loading: boolean;
}>();

const toShipmentsPage = (status: IShipmentStatus) => ({
  to: {
    ...shipmentsPath,
    ...(shipmentsCount
      ? { query: { status, expanded: false, page: 1, per_page: 10 } }
      : { hash: `#${status}` }),
  },
});

const shipments = computed(() => [
  {
    label: 'Upcoming',
    ...toShipmentsPage(SHIPMENTS_STATUS.UPCOMING),
    count: shipmentsCount?.upcoming ?? 0,
  },
  {
    label: 'Ongoing',
    ...toShipmentsPage(SHIPMENTS_STATUS.ONGOING),
    count: shipmentsCount?.ongoing ?? 0,
  },
  {
    label: 'Completed',
    ...toShipmentsPage(SHIPMENTS_STATUS.COMPLETED),
    count: shipmentsCount?.completed ?? 0,
  },
]);
</script>

<template>
  <BaseCard title="Your Shipments">
    <BaseDivideWrapper class="-mt-4">
      <div
        v-for="(shipment, index) in shipments"
        :key="index"
        class="space-y-2 py-4 md:space-y-0"
      >
        <BaseCardLink :to="shipment.to" :loading="loading" :disabled="loading">
          <OrganisationHomeTheYourShipmentCount
            v-if="shipmentsCount && !loading"
            :count="shipment.count"
            :label="shipment.label"
          />
        </BaseCardLink>
      </div>
    </BaseDivideWrapper>
  </BaseCard>
</template>
