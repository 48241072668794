<script setup lang="ts" name="BaseDisplayMetric">
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { useOnResize } from '~/features/useOnResize';

import BaseCard from '~/components/BaseCard.vue';
import BaseCardLink from '~/components/BaseCardLink.vue';
import BaseCircle from '~/components/BaseCircle.vue';
import InfoTooltip from '~/components/InfoTooltip.vue';

const {
  to,
  value,
  loading,
  withLink,
  tooltip,
  fallbackValue = 'No data available yet.',
  date,
} = defineProps<{
  id: string;
  title: string;
  date?: string;
  tooltip?: string;
  subTitle?: string;
  description?: string;
  to?: RouteLocationRaw;
  loading?: boolean;
  height?: number;
  value?: string | number | null;
  fallbackValue?: string | null;
  comparativeValue?: string | number | null;
  comparativeDescription?: string;
  blinker?: boolean;
  withLink?: boolean;
}>();

const hasMetricValue = computed(() => value != null);

const metricWithLink = computed(() => (loading && withLink) || !!to);
const { isOnMobile } = useOnResize();

const showMobileTooltip = computed(
  () => tooltip && isOnMobile.value && !loading,
);
</script>

<template>
  <BaseCard :style="{ height: showMobileTooltip ? 'auto' : `${height}px` }">
    <div class="flex h-full flex-col pt-6" :data-test="id">
      <template v-if="showMobileTooltip">
        <InfoTooltip
          isResponsive
          title="How is this number calculated?"
          :description="tooltip!"
        >
          <div class="w-full">
            <div class="mb-2 flex">
              <p class="text-subtitle-1">
                {{ title }}
              </p>

              <p v-if="date" class="px-4 text-sm text-charcoal-6">{{ date }}</p>
            </div>
            <p v-if="subTitle" class="text-body-2">
              {{ subTitle }}
            </p>
          </div>
        </InfoTooltip>
      </template>

      <template v-else>
        <div class="mb-2 flex items-start justify-between">
          <p class="text-subtitle-1" :class="{ 'text-loading w-5/6': loading }">
            {{ title }}
          </p>
          <p v-if="date" class="text-sm text-charcoal-6">{{ date }}</p>

          <InfoTooltip
            v-if="tooltip && !loading"
            title="How is this number calculated?"
            :description="tooltip"
          />
        </div>

        <p
          v-if="subTitle"
          class="text-body-2"
          :class="{ 'text-loading h-5 w-4/6': loading }"
        >
          {{ subTitle }}
        </p>
      </template>

      <p
        v-if="!hasMetricValue"
        class="mt-5 flex h-full items-end text-charcoal-9"
        :class="{ 'text-loading mt-7 h-5 w-1/4': loading }"
      >
        {{ fallbackValue }}
      </p>

      <slot v-else>
        <div
          class="mt-5 flex h-full items-center"
          :class="{
            'justify-between': !!comparativeValue,
          }"
        >
          <div
            class="flex min-w-0 items-baseline space-x-2"
            :class="{ 'text-loading h-5 w-1/4': loading }"
          >
            <BaseCircle
              v-if="blinker && !loading"
              size="sm"
              class="self-center"
              color="secondary"
              background="pulse"
            />

            <p
              v-title
              class="truncate text-h4 font-light text-secondary-9"
              :class="{ 'text-loading': loading }"
            >
              {{ value }}
            </p>
            <p v-if="description" class="text-body-2 text-charcoal-6">
              {{ description }}
            </p>
          </div>

          <div
            v-if="!!comparativeValue && !loading"
            class="flex min-w-0 items-baseline space-x-2"
            :class="{ 'mr-5': blinker }"
          >
            <p
              v-title
              class="truncate text-[28px] font-light text-secondary-9"
              :class="{ 'text-loading h-5': loading }"
            >
              {{ comparativeValue }}
            </p>
            <p
              v-if="comparativeDescription"
              class="text-body-2 text-charcoal-6"
            >
              {{ comparativeDescription }}
            </p>
          </div>
        </div>
      </slot>

      <BaseCardLink
        v-if="metricWithLink"
        class="mt-5 shrink-0"
        :to="to"
        :loading="loading"
      >
        View details
      </BaseCardLink>
    </div>
  </BaseCard>
</template>
