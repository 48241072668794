import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

const base = `${baseEndpoint}/api/customer/organisation/1/kpis`;

export const kpis = base;

export const perPeriodFilters = `${base}/shipmentVolumes/per_period/filters`;
export const perShipmentFilters = `${base}/shipmentVolumes/per_shipment/filters`;

export const perPeriodChart = `${base}/shipmentVolumes/per_period`;
export const perShipmentChart = `${base}/shipmentVolumes/per_shipment`;

const transitTimeFilters = `${base}/transitTime/filters`;
const transitTimeChart = `${base}/transitTime/chart`;

const invoicesChart = `${base}/invoices/chart`;

export default [
  http.get(kpis, async () => {
    return HttpResponse.json({
      data: ['volumePerPeriod', 'volumePerShipment', 'invoices', 'transitTime'],
    });
  }),

  http.get(perPeriodFilters, async () => {
    return HttpResponse.json({
      data: {
        // contracts: [
        //   {
        //     id: 30,
        //     name: 'tadas',
        //     period: '1 Jan 2021 - 31 Dec 2021',
        //     status: 'active',
        //     inTradelanes: ['FE-WSA'],
        //   },
        // ],
        statuses: [
          { id: 'active', name: 'Active', inTradelanes: ['FE-WSA'] },
          { id: 'upcoming', name: 'Upcoming', inTradelanes: ['FE-WSA'] },
          { id: 'expired', name: 'Expired', inTradelanes: ['FE-WSA'] },
        ],
        tradelanes: [
          {
            id: 'FE-WSA',
            name: 'FE-WSA',
          },
        ],
      },
    });
  }),
  http.get(perPeriodChart, async () => {
    return HttpResponse.json({
      data: [
        {
          id: 392,
          type: 'shipmentVolumes',
          name: 'ESA-FE.2017.CMPC',
          min: 4,
          max: 6,
          period: '3 Feb 2008 - 31 Mar 2023',
          series: {
            'ESA-FE': {
              '9 May, 2017': {
                timestamp: 1494288000,
                value: 11570,
              },
              '1 Jun, 2017': {
                timestamp: 1496275200,
                value: 47299,
              },
            },
            'SUZ-FE': {
              '30 Sep, 2020': {
                timestamp: 1601424000,
                value: 8044,
              },
              '18 Jan, 2022': {
                timestamp: 1642464000,
                value: 30781,
              },
            },
          },
          range: 'years',
          tradelane: 'ESA-FE',
        },
      ],
    });
  }),

  http.get(perShipmentFilters, async () => {
    return HttpResponse.json({
      data: {
        // contracts: [
        //   {
        //     id: 30,
        //     name: 'tadas',
        //     period: '1 Jan 2021 - 31 Dec 2021',
        //     status: 'active',
        //     inTrade  anes: ['FE-WSA'],
        //   },
        // ],
        statuses: [
          { id: 'active', name: 'Active', inTradelanes: ['FE-WSA'] },
          { id: 'upcoming', name: 'Upcoming', inTradelanes: ['FE-WSA'] },
          { id: 'expired', name: 'Expired', inTradelanes: ['FE-WSA'] },
        ],
        tradelanes: [
          {
            id: 'FE-WSA',
            name: 'FE-WSA',
          },
        ],
      },
    });
  }),
  http.get(perShipmentChart, async () => {
    return HttpResponse.json({
      data: [
        {
          id: 900,
          type: 'shipmentVolumes',
          name: 'Molestias nobis nam rerum fugiat.',
          period: '1 Jan 2023 - 28 Feb 2023',
          tradelane: 'FE-WSA',
          chart: {
            min: 143,
            max: 338,
            series: [
              {
                category_id: 'Jan, 2023',
                label: '2 Jan 2023',
                value: 6,
                details: {
                  vesselName: 'Grimes Plc',
                  voyageNumber: 202399,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Grimes Plc+202399&status=all&expanded',
                },
              },
              {
                category_id: 'Jan, 2023',
                label: '10 Jan 2023',
                value: 717,
                details: {
                  vesselName: 'Gulgowski, Ziemann And Gorczany',
                  voyageNumber: 202337,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Gulgowski, Ziemann And Gorczany+202337&status=all&expanded',
                },
              },
            ],
            categories: ['Jan, 2023', 'Feb, 2023'],
            range: 'months',
          },
          widget: { total: 44, aboveMax: 50, belowMin: 6 },
        },
        {
          id: 901,
          type: 'shipmentVolumes',
          name: 'Molestias nobis nam rerum fugiat.',
          period: '1 Jan 2023 - 28 Feb 2023',
          tradelane: 'FE-WSA',
          chart: {
            min: 149,
            max: 243,
            series: [
              {
                category_id: 'Jan, 2023',
                label: '2 Jan 2023',
                value: 6,
                details: {
                  vesselName: 'Grimes Plc',
                  voyageNumber: 202399,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Grimes Plc+202399&status=all&expanded',
                },
              },
              {
                category_id: 'Jan, 2023',
                label: '10 Jan 2023',
                value: 717,
                details: {
                  vesselName: 'Gulgowski, Ziemann And Gorczany',
                  voyageNumber: 202337,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Gulgowski, Ziemann And Gorczany+202337&status=all&expanded',
                },
              },
            ],
            categories: ['Jan, 2023', 'Feb, 2023'],
            range: 'months',
          },
          widget: { total: 19, aboveMax: 75, belowMin: 6 },
        },
      ],
    });
  }),

  http.get(transitTimeFilters, async () => {
    return HttpResponse.json({
      data: {
        contracts: [
          {
            id: 30,
            name: 'ESA-MED',
            period: '1 Jan 2021 - 31 Dec 2021',
            status: 'active',
            inTradelanes: ['FE-WSA', 'EU-WSA'],
          },
        ],
        statuses: [{ id: 'active', name: 'Active', inTradelanes: ['FE-WSA'] }],
        tradelanes: [
          {
            id: 'FE-WSA',
            name: 'FE-WSA',
          },
          {
            id: 'EU-WSA',
            name: 'EU-WSA',
          },
        ],
        ports: [
          {
            id: 'FE',
            name: 'FE',
            in_tradelanes: ['FE-WSA'],
          },
          {
            id: 'EU',
            name: 'EU',
            in_tradelanes: ['EU-WSA'],
          },
        ],
      },
    });
  }),
  http.get(transitTimeChart, async () => {
    return HttpResponse.json({
      data: [
        {
          id: 900,
          type: 'transitTimes',
          name: 'Molestias nobis nam rerum fugiat.',
          rule: 'firstToFirst',
          period: '1 Jan 2023 - 28 Feb 2023',
          tradelane: 'FE-WSA',
          chart: {
            max: 159,
            series: [
              {
                category_id: 'Jan, 2023',
                label: '2 Jan 2023',
                value: 4,
                details: {
                  vesselName: 'Grimes Plc',
                  voyageNumber: 202399,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Grimes Plc+202399&status=all&expanded',
                },
              },
              {
                category_id: 'Jan, 2023',
                label: '10 Jan 2023',
                value: 15,
                details: {
                  vesselName: 'Gulgowski, Ziemann And Gorczany',
                  voyageNumber: 202337,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Gulgowski, Ziemann And Gorczany+202337&status=all&expanded',
                },
              },
            ],
            categories: ['Jan, 2023', 'Feb, 2023'],
            range: 'months',
          },
          widget: { total: 44, aboveMax: 50 },
        },
        {
          id: 901,
          type: 'transitTimes',
          rule: 'firstToLast',
          name: 'Molestias nobis nam rerum fugiat.',
          period: '1 Jan 2023 - 28 Feb 2023',
          tradelane: 'FE-WSA',
          chart: {
            max: 112,
            series: [
              {
                category_id: 'Jan, 2023',
                label: '2 Jan 2023',
                value: 13,
                details: {
                  vesselName: 'Grimes Plc',
                  voyageNumber: 202399,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Grimes Plc+202399&status=all&expanded',
                },
              },
              {
                category_id: 'Jan, 2023',
                label: '10 Jan 2023',
                value: 23,
                details: {
                  vesselName: 'Gulgowski, Ziemann And Gorczany',
                  voyageNumber: 202337,
                  actionUrl:
                    '/organisation/W4nmNemU/shipments?search=Gulgowski, Ziemann And Gorczany+202337&status=all&expanded',
                },
              },
            ],
            categories: ['Jan, 2023', 'Feb, 2023'],
            range: 'months',
          },
          widget: { total: 19, aboveMax: 75 },
        },
      ],
    });
  }),

  http.get(invoicesChart, async () => {
    return HttpResponse.json({
      data: [
        {
          id: 1000,
          type: 'invoices',
          period: '1 Jan 2023 - 28 Feb 2023',
          chart: {
            max: 112,
            categories: ['Jan, 2023', 'Feb, 2023'],
            series: [
              {
                category_id: 'Jan, 2023',
                label: '2 Jan 2023',
                value: 4,
                details: {
                  invoiceNumber: 'IR192',
                  amount: 1000,
                  vessel: 'Grimes Plc',
                  voyage: 202399,
                  port: 'Braziliana',
                },
              },
              {
                category_id: 'Jan, 2023',
                label: '10 Jan 2023',
                value: 15,
                details: {
                  invoiceNumber: 'IR192',
                  amount: 1000,
                  vessel: 'Grimes Plc',
                  voyage: 202399,
                  port: 'Braziliana',
                },
              },
            ],
          },
          widget: { total: 19, aboveMax: 75 },
        },
      ],
    });
  }),
] satisfies HttpHandler[];
