<script setup lang="ts" name="VesselsLayout"></script>

<template>
  <div class="col-span-full space-y-2">
    <div class="rounded-t-md shadow">
      <slot name="search" />
    </div>

    <div class="overflow-hidden rounded-md bg-white shadow">
      <slot />
    </div>
  </div>
</template>
