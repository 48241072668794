<script setup lang="ts" name="OrganisationHomeStatisticsWidgetsNoData">
import BaseButton from '~/components/BaseButton.vue';
import BaseCardNoData from '~/components/BaseCardNoData.vue';

defineProps<{
  spectator?: boolean;
}>();

const emit = defineEmits<{
  (e: 'openDialog'): void;
}>();
</script>

<template>
  <div class="mt-2">
    <BaseCardNoData
      title="This Is Where You’ll See Your Statistics"
      message="Start by choosing what’s important to you"
      illustration="no-data"
      styled
    >
      <BaseButton
        v-if="!spectator"
        icon="mdi:plus"
        class="mt-4"
        variant="btn-tertiary"
        @click="emit('openDialog')"
      >
        add statistics
      </BaseButton>
    </BaseCardNoData>
  </div>
</template>
