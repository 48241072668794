<script setup lang="ts" name="OrganisationShipmentsVoyageSkeleton">
const LOADING_COLS = 4;
const LOADING_ROWS = 5;
</script>

<template>
  <div class="space-y-2">
    <div
      v-for="row in LOADING_ROWS"
      :key="row"
      class="rounded bg-white px-6 py-9 shadow 2xl:pl-14"
    >
      <div
        class="flex justify-evenly space-x-4 xl:justify-start xl:space-x-12 2xl:space-x-24"
      >
        <div
          v-for="col in LOADING_COLS"
          :key="col"
          class="text-loading h-5 w-36"
        />
      </div>
    </div>
  </div>
</template>
