<script setup lang="ts" name="PublicSailingScheduleTradesList">
import Service from '~/services/Service';

import ModalG2OSelectedList from '~/components/ModalG2OSelectedList.vue';
import ModalG2OSelectedListOption from '~/components/ModalG2OSelectedListOption.vue';

import type { IStatisticsTradeLane, ITradeLane } from '~/types';
const { selectedTradelanes, loading } = defineProps<{
  selectedTradelanes: IStatisticsTradeLane[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: 'setSearchStatus', value: { active: boolean }): void;
  (e: 'setSelectedTradelanes', value: ITradeLane[]): void;
}>();

const LOADING_ROWS = 5;

const deleteTrade = (trade: IStatisticsTradeLane) => {
  Service.publicSailingSchedule()
    .customize()
    .destroy(trade.id)
    .onSuccess(({ data }: { data: ITradeLane[] }) => {
      emit('setSelectedTradelanes', data);
      emit('setSearchStatus', { active: false });
    })
    .execute();
};
</script>

<template>
  <div>
    <div v-if="loading" class="space-y-2">
      <div
        v-for="rows in LOADING_ROWS"
        :key="rows"
        class="animate h-6 w-full animate-pulse bg-charcoal-0"
      />
    </div>
    <ModalG2OSelectedList v-else title="Trades in a Sailing Schedule:">
      <div class="space-y-2">
        <ModalG2OSelectedListOption
          v-for="trade in selectedTradelanes"
          :key="trade.id"
          :singleOption="selectedTradelanes.length === 1"
          @delete="deleteTrade(trade)"
        >
          <p v-title="trade.name" class="truncate text-body-1">
            {{ trade.continents }}
          </p>
        </ModalG2OSelectedListOption>
      </div>
    </ModalG2OSelectedList>
  </div>
</template>
