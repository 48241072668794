import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

export const customize = `${baseEndpoint}/api/public/sailing-schedule/customization`;
export const tradelanes = `${baseEndpoint}/api/public/sailing-schedule/customization/tradelanes`;

export default [
  http.get(customize, async () => {
    return HttpResponse.json({
      data: [
        {
          id: 'ESA-FE',
          name: 'East South America - Far East',
        },
        {
          id: 'FE-WNA',
          name: 'Far East - West North America',
        },
        {
          id: 'FE-WSA',
          name: 'Far East - West South America',
        },
      ],
    });
  }),
  http.get(tradelanes, async () => {
    return HttpResponse.json({
      data: [
        {
          id: 'FE-WSA',
          name: 'Far East - West South America',
        },
        {
          id: 'FE-ESA',
          name: 'Far East - East South America',
        },
        {
          id: 'ESA-EU',
          name: 'East South America - Europe',
        },
        {
          id: 'ESA-FE',
          name: 'East South America - Far East',
        },
        {
          id: 'FE-WNA',
          name: 'Far East - West North America',
        },
        {
          id: 'WSA-EU',
          name: 'West South America - Europe',
        },
        {
          id: 'ESA-MED',
          name: 'East South America - Mediterranean',
        },
        {
          id: 'WSA-FE',
          name: 'West South America - Far East',
        },
        {
          id: 'ESA-ENA',
          name: 'East South America - East North America',
        },
        {
          id: 'ESA-WSA',
          name: 'East South America - West South America',
        },
        {
          id: 'WNA-FE',
          name: 'West North America - Far East',
        },
        {
          id: 'ENA-ESA',
          name: 'East North America - East South America',
        },
        {
          id: 'EU-ESA',
          name: 'Europe - East South America',
        },
        {
          id: 'EU-WNA',
          name: 'Europe - West North America',
        },
      ],
    });
  }),
] satisfies HttpHandler[];
