import Service from '~/services/Service.js';

import type { IShipment, ISnackbarConfig, IVoyage } from '~/types';

export const getVoyageTooltipText = (notify: boolean) =>
  notify
    ? 'Disable notifications from all shipments in this voyage.'
    : 'Get notified about all shipments related to this voyage.';

export const getShipmentTooltipText = (notify: boolean) =>
  notify
    ? 'Disable notifications about updates of this shipment.'
    : 'Get notified about the updates of this shipment.';

const getNotifiedShipmentSnackbarMessages = (
  notify: boolean,
  notifyFor: 'voyage' | 'shipment',
) => ({
  title: `Notifications ${notify ? 'enabled.' : 'disabled.'}`,
  description: notify
    ? `You’ll now receive notifications related to this ${notifyFor}.`
    : `You’ll no longer receive notifications related to this ${notifyFor}.`,
});

export const showShipmentsNotificationsSnackbar = ({
  notify,
  notifyFor,
  add,
}: {
  notify: boolean;
  notifyFor: 'voyage' | 'shipment';
  add: (config: ISnackbarConfig) => void;
}) => {
  const { title, description } = getNotifiedShipmentSnackbarMessages(
    notify,
    notifyFor,
  );

  add({
    type: 'success',
    icon: 'ic:baseline-check-circle',
    title: title,
    text: description,
  });
};

export const getSelectedVoyageShipments = (voyage) =>
  voyage.shipments.filter((shipment) => shipment.notify !== !voyage.notify);

export const updateShipmentsNotifications = (
  shipments: IShipment[],
  organisationId: OrganisationId,
  callback: () => void,
) => {
  const shipmentsToSubmit = shipments.map(
    ({ bookingNo, loadingPort, dischargePort }) => ({
      cargoRef: bookingNo,
      loadingPort: loadingPort.id,
      dischargePort: dischargePort.id,
    }),
  );

  Service.organisation(organisationId)
    .shipment()
    .notifications(shipmentsToSubmit)
    .onSuccess(() => {
      callback();
    })
    .execute();
};

export const getUpdatedSelectedVoyageNotification = (
  voyages: IVoyage[],
  selectedVoyage: IVoyage,
) =>
  voyages.map((voyage) => {
    if (voyage.id !== selectedVoyage.id) {
      return voyage;
    }

    return {
      ...voyage,
      notify: !selectedVoyage.notify,
      shipments: voyage.shipments.map((shipment) => ({
        ...shipment,
        notify: !selectedVoyage.notify,
      })),
    };
  });

export const getUpdateSelectedShipmentNotification = (
  voyages: IVoyage[],
  selectedShipment: IShipment,
) =>
  voyages.map((voyage) => {
    const idMatches = voyage.shipments.find(
      (shipment) => shipment.id === selectedShipment.id,
    );

    if (!idMatches) {
      return voyage;
    }

    const updatedShipments = voyage.shipments.map((shipment) => {
      if (shipment.id !== selectedShipment.id) {
        return shipment;
      }
      return { ...shipment, notify: !shipment.notify };
    });

    const hasSelectedShipment = updatedShipments.some(
      (shipment) => shipment.notify,
    );

    return {
      ...voyage,
      notify: hasSelectedShipment,
      shipments: updatedShipments,
    };
  });
