import type { RouteRecordRaw } from 'vue-router';

import { PERMISSION } from '~/constants';

import { makeKeyBuilder, makeOrganisationRoute } from '~/features/useRouteMeta';
import { storage } from '~/features/useStorage';

import Service from '~/services/Service';

const OrganisationSettingsCompaniesIndex = () =>
  import('~/pages/OrganisationSettingsCompaniesIndex.vue');
const OrganisationSettingsEdit = () =>
  import('~/pages/OrganisationSettingsEdit.vue');
const OrganisationSettingsIndex = () =>
  import('~/pages/OrganisationSettingsIndex.vue');
const OrganisationSettingsKpisIndex = () =>
  import('~/pages/OrganisationSettingsKpisIndex.vue');
const OrganisationSettingsLiveIndex = () =>
  import('~/pages/OrganisationSettingsLiveIndex.vue');
const OrganisationSettingsUserEdit = () =>
  import('~/pages/OrganisationSettingsUserEdit.vue');
const OrganisationSettingsUserListIndex = () =>
  import('~/pages/OrganisationSettingsUserListIndex.vue');

export const organisationSettingsRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    path: '/settings',
    component: OrganisationSettingsIndex,
    permission: PERMISSION.ORGANISATION_VIEW_OWN,
    keyBuilder: makeKeyBuilder('OrganisationSettingsIndex'),
    children: [
      {
        name: 'OrganisationSettingsIndex',
        path: '',
        redirect: { name: 'OrganisationSettingsCompaniesIndex' },
      },
      {
        name: 'OrganisationSettingsCompaniesIndex',
        path: 'companies',
        component: OrganisationSettingsCompaniesIndex,
        props: true,
      },
      {
        name: 'OrganisationSettingsUserListIndex',
        path: 'users',
        component: OrganisationSettingsUserListIndex,
        props: true,
        beforeEnter: (to, _, next) => {
          Service.organisation(to.params.organisationId)
            .users()
            .filters()
            .onSuccess(({ data }) => {
              storage.setOrganisationUserListQuery(data);
              next();
            })
            .execute();
        },
      },
      {
        name: 'OrganisationSettingsLiveIndex',
        path: 'live',
        component: OrganisationSettingsLiveIndex,
        props: true,
      },
      {
        name: 'OrganisationSettingsKpisIndex',
        path: 'kpis',
        component: OrganisationSettingsKpisIndex,
        props: true,
      },
    ],
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsUserEdit',
    path: '/settings/edit/user/:userId',
    component: OrganisationSettingsUserEdit,
    permission: PERMISSION.ORGANISATION_USERS_VIEW_OWN,
    props: (route) => ({
      userId: route.params.userId as string,
    }),
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsEdit',
    path: '/settings/edit/:name/view/:view',
    component: OrganisationSettingsEdit,
    permission: PERMISSION.ORGANISATION_UPDATE_OWN,
    props: (route) => ({
      name: route.params.name as string,
      view: route.params.view as string,
    }),
  }),
];
