<script setup lang="ts" name="OrganisationHomeIndex">
import { computed, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';

import { ORG_FEATURES } from '~/constants';
import { MAINTENANCE_AREA } from '~/constants';
import { useHomeStore, useProfileStore } from '~/store';

import { ALL_STATISTIC_FEATURES } from '~/features/useFeatures';
import { provideOrganisationId } from '~/features/useOrganisationShipment';
import { useResetStore } from '~/features/useResetStore';

import BaseCardServerError from '~/components/BaseCardServerError.vue';
import BasePage from '~/components/BasePage.vue';
import OrganisationHomeAllShipments from '~/components/OrganisationHomeAllShipments.vue';
import OrganisationHomeAside from '~/components/OrganisationHomeAside.vue';
import OrganisationHomeStatistics from '~/components/OrganisationHomeStatistics.vue';

import type { IOrganisation } from '~/types';

const { organisationId, organisation } = defineProps<{
  organisationId: OrganisationId;
  organisation: IOrganisation;
}>();

provideOrganisationId(organisationId);

const homeStore = useHomeStore();
const profile = computed(() => useProfileStore().profile);

const { isPageError, refreshPage } = storeToRefs(homeStore);

const resetStore = useResetStore();

const page = computed(() => ({
  title: `Hello, ${profile.value?.firstName || 'There'}`,
}));

const showStatistics = computed(() =>
  organisation.features.some(
    (feature) =>
      ORG_FEATURES.STATISTICS_LAYTIME !== feature &&
      ORG_FEATURES.STATISTICS_WAITING_FOR_BERTH !== feature &&
      ALL_STATISTIC_FEATURES.includes(feature),
  ),
);

onUnmounted(() => {
  resetStore.HOME_STORE();
});
</script>

<template>
  <BasePage
    :title="page.title"
    with-pattern
    :with-aside="!isPageError"
    :area-id="MAINTENANCE_AREA.HOME"
    titleCapitalize
  >
    <div v-if="isPageError" class="col-span-full">
      <BaseCardServerError styled @retry="refreshPage(organisationId)" />
    </div>

    <OrganisationHomeAllShipments :organisation="organisation" />
    <OrganisationHomeStatistics
      v-if="showStatistics"
      :organisation="organisation"
    />

    <template v-if="!isPageError" #aside>
      <OrganisationHomeAside :organisation="organisation" />
    </template>
  </BasePage>
</template>
