<script lang="ts" setup name="SailingScheduleIndex">
import { computed, ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';

import {
  getCategoricalColors,
  getCategoricalTagColors,
} from '~/features/useColors';
import { useLastUpdated } from '~/features/useLastSync';
import { useOnResize } from '~/features/useOnResize';
import { useSailingScheduleTradeColoring } from '~/features/useSailingSchedule';
import {
  dateTypes,
  getSailingScheduleDateType,
  getSailingScheduleFilters,
  setSailingScheduleDateType,
  useSailingScheduleFilters,
} from '~/features/useSailingScheduleFilters';
import { storage } from '~/features/useStorage';

import Service from '~/services/Service.js';

import BaseAlert from '~/components/BaseAlert.vue';
import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import BasePage from '~/components/BasePage.vue';
import SailingScheduleContent from '~/components/SailingScheduleContent.vue';
import SailingScheduleFilters from '~/components/SailingScheduleFilters.vue';
import SailingScheduleMobileFilters from '~/components/SailingScheduleMobileFilters.vue';
import SailingScheduleNoDataCard from '~/components/SailingScheduleNoDataCard.vue';
import SailingScheduleTradeLaneCollapsible from '~/components/SailingScheduleTradeLaneCollapsible.vue';
import SailingScheduleVoyageCollapsible from '~/components/SailingScheduleVoyageCollapsible.vue';
import TextToggle from '~/components/TextToggle.vue';

import type {
  ISailingScheduleFilters,
  SailingScheduleTradeLane,
  SailingScheduleVoyages,
} from '~/types';

const { organisationId } = defineProps<{
  organisationId: OrganisationId;
}>();

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.organisationId === from.params.organisationId) {
    return true;
  }

  return await getSailingScheduleFilters(to.params.organisationId as string);
});

const { isOnMobile } = useOnResize();

const { lastUpdated, setLastUpdated } = useLastUpdated();

const filters = computed<ISailingScheduleFilters>(() =>
  storage.getSailingScheduleQuery(),
);

const {
  query,
  tradelanesOptions,
  loadingPortsOptions,
  dischargePortsOptions,
  vesselsOptions,

  activeFilters,
  clearAllFilters,
  clearActiveFilter,
} = useSailingScheduleFilters(filters);

const { getTradeColor } = useSailingScheduleTradeColoring();

const tradelanes = ref<SailingScheduleTradeLane[]>([]);
const voyageTradelanes = ref<SailingScheduleVoyages[]>([]);
</script>

<template>
  <BasePage title="Sailing Schedule" :last-updated="lastUpdated">
    <BaseAlert type="info" class="col-span-full rounded drop-shadow">
      All dates, vessels, and port calls are subject to change without notice
    </BaseAlert>
    <div class="col-span-full space-y-2">
      <SailingScheduleFilters
        class="z-[1] col-span-full hidden md:block"
        v-model="query"
        :activeFilters="activeFilters"
        :tradelanes="tradelanesOptions"
        :discharge-ports="dischargePortsOptions"
        :loading-ports="loadingPortsOptions"
        :vessels="vesselsOptions"
        @clearFilter="clearActiveFilter"
        @clearFilters="clearAllFilters"
      >
        <TextToggle
          :options="dateTypes"
          :modelValue="getSailingScheduleDateType()"
          @update:model-value="(value) => setSailingScheduleDateType(value)"
        />
      </SailingScheduleFilters>

      <SailingScheduleMobileFilters
        class="col-span-full block md:hidden"
        v-model="query"
        :activeFilters="activeFilters"
        :tradelanes="tradelanesOptions"
        :discharge-ports="dischargePortsOptions"
        :loading-ports="loadingPortsOptions"
        :vessels="vesselsOptions"
        @clearFilters="clearAllFilters"
      />

      <SailingScheduleContent
        v-if="isOnMobile"
        paginated
        v-model:query="query"
        v-model:items="voyageTradelanes"
        :service="
          Service.organisation(organisationId).sailingScheduleV2().indexMobile()
        "
        @setLastUpdated="setLastUpdated"
        @clearQuery="clearAllFilters"
      >
        <template #no-data>
          <BaseCardNoResults
            styled
            hideClear
            message="There are no voyages available for your selected trades at the moment."
          />
        </template>

        <template #no-results>
          <BaseCardNoResults
            v-if="activeFilters.length"
            styled
            message="There are no voyages available for this trade at the moment."
            @clear="clearAllFilters"
          />
        </template>

        <template #default="{ items }">
          <SailingScheduleVoyageCollapsible
            v-for="voyage in items"
            :key="voyage.tradelane"
            :voyage="voyage"
            v-bind="getTradeColor(voyage.tradelane)"
          />
        </template>
      </SailingScheduleContent>

      <SailingScheduleContent
        v-else
        paginated
        v-model:query="query"
        v-model:items="tradelanes"
        :service="
          Service.organisation(organisationId).sailingScheduleV2().index()
        "
        @setLastUpdated="setLastUpdated"
        @clearQuery="clearAllFilters"
      >
        <template #no-data>
          <SailingScheduleNoDataCard />
        </template>

        <template #default="{ items }">
          <SailingScheduleTradeLaneCollapsible
            v-for="(tradeLane, index) in items"
            :key="tradeLane.tradelane"
            :organisation-id="organisationId"
            :trade-lane="tradeLane"
            :tagVariant="getCategoricalTagColors(index)"
            :color="getCategoricalColors(index)"
          />
        </template>
      </SailingScheduleContent>
    </div>
  </BasePage>
</template>
