<script lang="ts" setup name="NotificationsRow">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

import { NOTIFICATION_SIZE, NOTIFICATION_VARIANT } from '~/constants';

import { useNotification } from '~/features/useNotification';

import Service from '~/services/Service.js';

import Icon from '~/components/Icon.vue';

import type { INotificationContent, INotificationType } from '~/types';

type VariantType =
  (typeof NOTIFICATION_VARIANT)[keyof typeof NOTIFICATION_VARIANT];
type SizeType = (typeof NOTIFICATION_SIZE)[keyof typeof NOTIFICATION_SIZE];

const {
  id,
  type,
  content,
  dataTest = 'archive-notifications-row',
  created_at = '',
  read = false,
  size = NOTIFICATION_SIZE.DEFAULT,
} = defineProps<{
  id: string;
  type: INotificationType;
  content: INotificationContent;
  dataTest?: string;
  created_at?: string;
  read?: boolean;
  size?: SizeType;
}>();

const emit = defineEmits<{ (e: 'close'): void }>();

const notification = computed(() => useNotification(type, content));

const sizeIsDefault = computed(() => NOTIFICATION_SIZE.DEFAULT === size);

const markAsRead = () => {
  Service.notifications().update(id).execute();
  emit('close');
};

const currentVariant = computed(() => {
  if (sizeIsDefault.value) {
    return read
      ? NOTIFICATION_VARIANT.DEFAULT_READ
      : NOTIFICATION_VARIANT.DEFAULT_UNREAD;
  }
  return read
    ? NOTIFICATION_VARIANT.LARGE_READ
    : NOTIFICATION_VARIANT.LARGE_UNREAD;
});

const variantIs = computed(() => (...variant: VariantType[]) => {
  return variant.includes(currentVariant.value);
});
</script>

<template>
  <div
    :data-test="dataTest"
    class="relative overflow-hidden"
    :class="{
      'bg-white': variantIs(
        NOTIFICATION_VARIANT.DEFAULT_READ,
        NOTIFICATION_VARIANT.LARGE_READ,
      ),
      'bg-charcoal-0': variantIs(
        NOTIFICATION_VARIANT.LARGE_UNREAD,
        NOTIFICATION_VARIANT.DEFAULT_UNREAD,
      ),
    }"
  >
    <div v-if="!read" class="absolute inset-y-0 left-0 w-[6px] bg-red-6"></div>
    <div class="flex py-4" :class="[sizeIsDefault ? 'px-4' : 'px-7']">
      <Icon :icon="notification.icon" class="mr-6 text-secondary-9" />
      <div class="flex-1">
        <p
          v-title
          v-html="notification.message"
          data-testid="notification-title"
          class="truncate-2-lines text-sm leading-5 text-secondary-9"
          :class="[sizeIsDefault ? 'mb-4' : 'mb-8']"
        />
        <div class="flex justify-between">
          <RouterLink
            v-if="notification.path"
            class="cursor-pointer rounded-lg text-xs font-bold uppercase tracking-widest text-primary-8 hover:text-primary-6 focus-visible:text-primary-8"
            :to="notification.path"
            @click="markAsRead"
          >
            {{ notification.buttonText }}
          </RouterLink>
          <p class="text-sm text-charcoal-6">{{ created_at }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
