<script lang="ts" setup name="NavBarLinkStyle">
import { injectSidebarState } from '~/features/useSidebarState';

import Icon from '~/components/Icon.vue';

import type { IconType } from '~/types';

const { label, icon } = defineProps<{
  label: string;
  icon?: IconType;
  active?: boolean;
}>();

const sidebarState = injectSidebarState();
</script>

<template>
  <div
    class="padding-change-transition flex w-full items-center gap-x-4 rounded bg-white bg-opacity-0 py-4 hover:bg-opacity-10"
    :class="[
      sidebarState.closed.value ? 'px-[14px]' : 'px-[17px]',
      {
        'bg-opacity-30 hover:bg-opacity-40': active,
      },
    ]"
  >
    <Icon v-if="icon" :icon="icon" class="text-ocean-2" />
    <div v-else class="h-6 w-6 shrink-0" />
    <p
      class="text-opacity-transition font-bold leading-5 text-white"
      :class="sidebarState.closed.value ? 'opacity-0' : 'opacity-100'"
    >
      {{ label }}
    </p>
    <slot />
  </div>
</template>
