<script setup name="LogoG2O"></script>

<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.61396 71.9325C5.43685 71.9325 3.60445 71.2008 2.16715
      69.7581C0.729854 68.3154 0.000854492 66.5244 0.000854492
      64.431C0.000854492 62.352 0.729854 60.5664 2.16715 59.121C3.60445
      57.6756 5.43865 56.9466 7.61396 56.9466C9.78926 56.9466 11.6217
      57.6774 13.0608 59.121C14.4999 60.5646 15.2271 62.3484 15.2271
      64.431C15.2271 66.5244 14.4981 68.3172 13.0608 69.7581C11.6235
      71.199 9.79016 71.9325 7.61396 71.9325ZM7.63555 60.3522C6.52495
      60.3522 5.62675 60.7419 4.88965 61.5447C4.15255 62.3475 3.78445
      63.2997 3.78445 64.4319C3.78445 65.5785 4.14445 66.5289 4.89055
      67.3389C5.63665 68.1489 6.52405 68.5278 7.63465 68.5278C8.72995
      68.5278 9.61466 68.1381 10.3464 67.3371C11.0781 66.5361 11.4435
      65.5749 11.4435 64.4283C11.4435 63.2961 11.0835 62.3511 10.3464
      61.5402C9.60926 60.7293 8.72905 60.3522 7.63465 60.3522H7.63555Z"
      fill="#266764"
    />
    <path
      d="M23.8464 71.9325C21.6693 71.9325 19.8369 71.2008 18.3996
      69.7581C16.9623 68.3154 16.2333 66.5244 16.2333 64.431C16.2333
      62.352 16.9623 60.5664 18.3996 59.121C19.8369 57.6756 21.6711
      56.9466 23.8464 56.9466C25.6545 56.9466 27.2511 57.474 28.593
      58.5144L28.8765 58.734L26.7165 61.2846L26.4645 61.0983C25.7931
      60.6033 24.984 60.3522 24.0615 60.3522C22.8492 60.3522 21.8952
      60.7401 21.1446 61.5375C20.394 62.3349 20.0196 63.2862 20.0196
      64.431C20.0196 65.5758 20.3796 66.528 21.1257 67.338C21.8718
      68.148 22.7565 68.5278 23.868 68.5278C25.0191 68.5278 25.9488
      68.193 26.7093 67.5054L26.9685 67.2714L29.2266 69.8427L28.971
      70.0695C27.5841 71.307 25.8606 71.9325 23.8464 71.9325Z"
      fill="#266764"
    />
    <path
      d="M30.1986 71.6985V57.1644H41.7168V60.417H33.8535V62.5491H41.3136
      V65.7792H33.8535V68.4459H41.9913V71.6985H30.1986Z"
      fill="#266764"
    />
    <path
      d="M54.6623 71.6976L53.7515 69.4962H47.2427L46.3022
      71.6976H42.5024L48.743 57.1644H52.1765L58.2524
      71.6976H54.6623ZM52.4924 66.4578L50.4944 61.6392C50.12
      62.6985 49.76 63.6192 49.4198 64.3995L48.5423 66.4578H52.4924Z"
      fill="#266764"
    />
    <path
      d="M69.4989 71.6976C69.1406 71.6981 68.7878 71.6099 68.4719
      71.4407C68.1561 71.2716 67.8871 71.0268 67.689 70.7283L62.8245
      63.3978V71.6949H59.1687V57.1644H61.7679C62.1274 57.1637 62.4814
      57.2526 62.798 57.4229C63.1146 57.5932 63.3838 57.8397 63.5814
    58.14L68.3451 65.3778V57.1644H72V71.6976H69.4989Z"
      fill="#266764"
    />
    <path
      d="M45.4113 24.2532H29.8737C28.9906 24.2532 28.1436 24.604 27.5191
      25.2285C26.8946 25.853 26.5437 26.7 26.5437 27.5832V33.4746H36.6768C34.5042
      36.621 30.6603 38.6478 26.5374 38.6478C20.1087 38.6478 14.1696 33.4035
      14.1705 26.2071C14.1705 19.1178 19.7991 13.932 26.2449 13.7772H26.6949C26.7768
      13.7772 26.8542 13.7889 26.9352 13.7916H35.1765L44.8668 0.00628662H26.7768C26.6571
      0.00628662 26.5365 0.00628662 26.4168 0.00628662H26.0568V0.0152865C25.7616
      0.0206865 25.4682 0.0215863 25.1712 0.0368863C11.3967 0.744286 0.454517
      12.3138 0.404117 26.1072C0.350117 40.6152 12.0375 52.4088 26.5347 52.4115C28.5413
      52.4096 30.541 52.1774 32.4945 51.7194L48.132 29.4894C49.6764 27.2862 48.1023
      24.2541 45.4113 24.2532Z"
      fill="#266764"
    />
    <path
      d="M68.3343 41.3019H56.8422L66.8376 27.1179C66.8376 27.1179 69.9831
      22.9257 69.9876 17.4753C69.9876 8.94957 63.2124 0.00357002 53.01
      0.00177002C52.902 0.00177002 52.7958 0.00716999 52.6887 0.00806999H48.2076L38.5002
      13.7898H47.5245C48.0714 12.9454 48.821 12.2515 49.705 11.7714C50.589 11.2913
      51.5792 11.0402 52.5852 11.0412C56.2887 11.0412 58.8042 14.3964 58.8033
      17.0523C58.8033 19.0791 57.8241 20.826 56.4966 22.4334L35.3871 52.4034L71.6571
      52.4106V44.622C71.6566 43.7411 71.3063 42.8965 70.6832 42.2739C70.06 41.6513
      69.2151 41.3016 68.3343 41.3019Z"
      fill="#66BCBC"
    />
  </svg>
</template>
