<script lang="ts" setup name="OrganisationVesselsShow">
import { computed, ref } from 'vue';

import { useRouteBack } from '~/features/useRouteBack';
import { useService } from '~/features/useService.js';

import Service from '~/services/Service.js';

import VesselsShow from '~/components/VesselsShow.vue';

import type { IVesselsShow, IVesselsShowResponse } from '~/types/vessels';

const { organisationId, name, id } = defineProps<{
  organisationId: OrganisationId;
  name: string;
  id: string | number;
}>();

const page = computed(() => ({
  title: name,
  backButton: {
    label: 'Vessels',
    to: useRouteBack({
      name: 'OrganisationVesselsIndex',
      params: {
        organisationId: organisationId,
      },
    }),
  },
}));

const vessel = ref<IVesselsShow | null>(null);

const serviceState = useService(
  Service.organisation(organisationId).vessels().show(id),
  ({ data }: IVesselsShowResponse) => {
    vessel.value = data;
    return true;
  },
);
</script>

<template>
  <VesselsShow :page="page" :serviceState="serviceState" :vessel="vessel" />
</template>
