<script
  setup
  lang="ts"
  generic="T extends SailingScheduleTradeLane"
  name="SailingScheduleContent"
>
import { useVModel } from '@vueuse/core';

import { useProfileStore } from '~/store';

import { useService } from '~/features/useService';
import { getIdsFromArray } from '~/features/useUrlSearch';

import type { HTTP } from '~/services/HTTP';

import BaseAutoPagination from '~/components/BaseAutoPagination.vue';
import ServiceCard from '~/components/ServiceCard.vue';

import type {
  ISailingScheduleQuery,
  SailingScheduleTradeLane,
  SailingScheduleV2IndexResponse,
} from '~/types';

const props = defineProps<{
  items: T[];
  service: HTTP;
  paginated?: boolean;
  query: ISailingScheduleQuery;
}>();

const emit = defineEmits<{
  (e: 'query'): void;
  (e: 'clearQuery'): void;
  (e: 'items', value: T[]): void;
  (e: 'setLastUpdated', value?: number): void;
}>();

const filtersQuery = useVModel(props, 'query', emit);
const items = useVModel(props, 'items', emit);

const serviceState = useService(
  props.service,
  ({ data, meta }: SailingScheduleV2IndexResponse<T>) => {
    if (!data.length) return false;

    items.value = data;

    emit('setLastUpdated', meta?.lastUpdated);

    return true;
  },
  {
    cancelOnNewRequest: true,
    paginated: props.paginated,
    query: filtersQuery,
    getQuery: () => ({
      tradelanes: getIdsFromArray(filtersQuery.value.tradelanes),
      dischargePorts: getIdsFromArray(filtersQuery.value.dischargePorts),
      loadingPorts: getIdsFromArray(filtersQuery.value.loadingPorts),
      vessels: getIdsFromArray(filtersQuery.value.vessels),
    }),
  },
);
</script>

<template>
  <ServiceCard
    v-bind="serviceState"
    styled
    no-data-title="We have looked carefully..."
    no-data-message="You have no sailing schedule at the moment."
    no-results-message="Please amend the search criteria."
    @clear="emit('clearQuery')"
  >
    <template #no-data>
      <slot name="no-data" />
    </template>

    <template #no-results>
      <slot name="no-results" />
    </template>

    <div class="mb-2 space-y-2">
      <slot :items="items" />
    </div>

    <div
      v-if="!useProfileStore().isPublic"
      class="col-span-full flex justify-end rounded bg-white p-4 drop-shadow"
    >
      <BaseAutoPagination />
    </div>
  </ServiceCard>
</template>
