<script lang="ts" setup name="SailingScheduleTradeLaneVoyages">
import { computed, ref, watch } from 'vue';

import { getSailingSchedulePreviewPorts } from '~/features/useSailingSchedule';
import { getSailingScheduleDateType } from '~/features/useSailingScheduleFilters';
import { useSortA1ByA2 } from '~/features/useSortA1ByA2';

import SailingScheduleTradeLaneHorizontalScroll from '~/components/SailingScheduleTradeLaneHorizontalScroll.vue';
import SailingScheduleTradeLanePort from '~/components/SailingScheduleTradeLanePort.vue';
import SailingScheduleTradeLanePortDates from '~/components/SailingScheduleTradeLanePortDates.vue';
import SailingScheduleTradeLaneVesselHeading from '~/components/SailingScheduleTradeLaneVesselHeading.vue';
import SailingScheduleTradeLaneVoyagesLayout from '~/components/SailingScheduleTradeLaneVoyagesLayout.vue';
import SailingScheduleTradeLaneVoyagesLoading from '~/components/SailingScheduleTradeLaneVoyagesLoading.vue';
import SailingScheduleTradeLaneVoyagesNoData from '~/components/SailingScheduleTradeLaneVoyagesNoData.vue';
import SailingsScheduleViewButton from '~/components/SailingsScheduleViewButton.vue';
import ServiceCard from '~/components/ServiceCard.vue';

import type { ITradeLanePort, ITradeLaneVessel } from '~/types';

const {
  vessels,
  ports,
  serviceState = {},
} = defineProps<{
  vessels: ITradeLaneVessel[];
  ports: ITradeLanePort[];
  serviceState?: Record<string, unknown>;
}>();

const hoverPortId = ref('');
const sortedVesselId = ref('');

const fullScreen = ref(false);

const toVessel = (vessel: ITradeLaneVessel, i: number) => ({
  name: vessel.vessel,
  vvn: vessel.vvn,
  id: vessel.id,
  ports: vessel.ports,
});

const voyageVessels = computed(() =>
  vessels.filter((vessel) => vessel.visible).map(toVessel),
);

const voyagePorts = computed(() => {
  const vessel = voyageVessels.value.find((x) => x.id === sortedVesselId.value);
  if (!vessel) {
    return getSailingSchedulePreviewPorts(ports, fullScreen.value);
  }

  const keys = Object.keys(vessel.ports);

  const sortedVoyagesById = useSortA1ByA2(ports, keys, 'id');
  return getSailingSchedulePreviewPorts(sortedVoyagesById, fullScreen.value);
});

const hasSelectedPorts = computed(() => !ports.every((port) => port.visible));

const vesselsPortDates = computed(() =>
  voyagePorts.value.map((port) => ({
    ...port,
    id: port.id,
    dates: voyageVessels.value.map((vessel, y) => {
      const date = vessel.ports[port.id]?.[getSailingScheduleDateType()] || '-';
      return {
        id: `${vessel.vvn}-${port.id}-${y}-${date}`,
        vesselId: vessel.id,
        date,
      };
    }),
    last: port.last,
  })),
);

const onMouseOut = () => {
  hoverPortId.value = '';
};

const onMouseOver = (portId: string) => {
  hoverPortId.value = portId;
};

const toggleFullScreen = () => {
  fullScreen.value = !fullScreen.value;
};

const onSort = (vesselId: string) => {
  sortedVesselId.value = sortedVesselId.value === vesselId ? '' : vesselId;
};

watch(
  () => voyageVessels.value,
  (value) => {
    if (!value.some((vessel) => vessel.id === sortedVesselId.value)) {
      sortedVesselId.value = '';
    }
  },
);
</script>

<template>
  <ServiceCard v-bind="serviceState" styled>
    <template #loading>
      <SailingScheduleTradeLaneVoyagesLoading />
    </template>

    <template #no-data>
      <SailingScheduleTradeLaneVoyagesNoData />
    </template>

    <SailingScheduleTradeLaneVoyagesLayout>
      <SailingScheduleTradeLanePort
        v-for="item in voyagePorts"
        :key="item.id"
        v-bind="item"
        :hover="item.id === hoverPortId"
        :has-selected-ports="hasSelectedPorts"
        :full-screen="fullScreen"
        :sorted="!!sortedVesselId"
        @mouseover="onMouseOver"
        @mouseout="onMouseOut"
      />

      <SailingsScheduleViewButton
        v-if="hasSelectedPorts"
        :fullVoyageView="fullScreen"
        @toggleFullScheduleView="toggleFullScreen"
      />

      <template #vessels>
        <SailingScheduleTradeLaneHorizontalScroll>
          <div class="table h-full w-full rounded-b bg-white">
            <div class="flex border-y border-charcoal-2 bg-charcoal-1">
              <div
                v-if="voyageVessels.length === 0"
                class="h-16 bg-charcoal-1"
              />

              <SailingScheduleTradeLaneVesselHeading
                v-for="vessel in voyageVessels"
                v-bind="vessel"
                :key="vessel.id"
                :sorted="vessel.id === sortedVesselId"
                @sort="onSort"
              />
            </div>

            <div v-for="port in vesselsPortDates" :key="port.id">
              <SailingScheduleTradeLanePortDates
                v-bind="port"
                :hover="port.id === hoverPortId"
                :has-selected-ports="hasSelectedPorts"
                :full-screen="fullScreen"
                :sortedVesselId="sortedVesselId"
                @mouseover="onMouseOver"
                @mouseout="onMouseOut"
              />
            </div>
          </div>
        </SailingScheduleTradeLaneHorizontalScroll>
      </template>
    </SailingScheduleTradeLaneVoyagesLayout>
  </ServiceCard>
</template>
