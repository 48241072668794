<script setup lang="ts" name="OrganisationHomeStatisticsWidget">
import { HOME_WIDGET_TYPE } from '~/constants';

import { addObjectIf } from '~/features/useUtilities';

import BaseDisplayMetric from '~/components/BaseDisplayMetric.vue';
import OrganisationHomeVlsfoPriceCard from '~/components/OrganisationHomeVlsfoPriceCard.vue';

import type { IHomeWidget } from '~/types';

const { widget, organisationId } = defineProps<{
  widget: IHomeWidget;
  organisationId: OrganisationId;
}>();

const toStatistics = (widget: IHomeWidget) => {
  const { trade, hash } = widget;

  return {
    name: hash,
    params: {
      organisationId,
    },
    ...addObjectIf(!!trade, {
      query: {
        trade,
      },
    }),
  };
};
</script>

<template>
  <BaseDisplayMetric :height="219" v-bind="widget" :to="toStatistics(widget)">
    <OrganisationHomeVlsfoPriceCard
      v-if="widget.id === HOME_WIDGET_TYPE.VLSFO_PRICE"
      :bunkers="widget.bunkers!"
    />
  </BaseDisplayMetric>
</template>
