<script lang="ts" setup name="OrganisationShipmentsVoyageShipment">
import { computed, ref } from 'vue';
import { RouterLink } from 'vue-router';
import { useVModel } from '@vueuse/core';

import { SHIPMENTS_STATUS } from '~/constants';
import { useOrganisationsStore } from '~/store';

import { Item, useBaseItems } from '~/features/useBaseItem';
import { getShipmentTooltipText } from '~/features/useShipmentNotifications';

import BaseItem from '~/components/BaseItem.vue';
import Icon from '~/components/Icon.vue';
import NotifyBellButton from '~/components/NotifyBellButton.vue';
import ShipmentsCountIcon from '~/components/ShipmentsCountIcon.vue';

import type { IPort, IShipmentsFilterId, IShipmentStatus } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    organisationId: OrganisationId;
    shipmentNumber?: number;
    bookingNo?: string;
    total: number;
    unit: string;
    commodity: string;
    loadingPort: IPort;
    dischargePort: IPort;
    status: IShipmentStatus;
    arrived?: boolean;
    date: string;
    marked?: boolean;
    from?: IShipmentsFilterId;
  }>(),
  {
    shipmentNumber: 1,
    bookingNo: '',
    total: 0,
    unit: '',
    arrived: false,
    date: '',
    marked: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const bookingNumber = computed(() =>
  Item.label('Booking No').value(props.bookingNo).bold().toBaseItem(),
);

const shipmentCompleted = computed(
  () => props.status === SHIPMENTS_STATUS.COMPLETED,
);

const shipmentArrivedText = computed(() =>
  (props.arrived && props.status === SHIPMENTS_STATUS.ONGOING) ||
  shipmentCompleted.value
    ? 'Arrived'
    : 'Est. Arrival',
);

const details = computed(() =>
  useBaseItems(
    Item.label('Qty').value(props.total).unit(props.unit).type('number'),
    Item.label('Commodity').value(props.commodity).capitalize(),
    Item.label('Load Port').value(props.loadingPort.name),
    Item.label('Discharge Port').value(props.dischargePort.name),
  ),
);

const shipmentView = computed(() => ({
  name: 'OrganisationShipmentsShow',
  params: {
    organisationId: props.organisationId,
    bookingNo: props.bookingNo,
    loadPortId: props.loadingPort.id,
    dischargePortId: props.dischargePort.id,
  },
  query: {
    from: props.from,
  },
}));
</script>

<template>
  <RouterLink
    class="group relative col-span-full grid grid-cols-subgrid items-center border-b border-charcoal-2 text-left last:border-none hover:bg-charcoal-1"
    :class="{
      'bg-charcoal-1': marked,
      'bg-white': !marked,
    }"
    :to="shipmentView"
  >
    <div class="col-span-full grid grid-cols-subgrid items-center">
      <div
        class="col-span-full grid min-w-[719px] grid-cols-subgrid group-hover:bg-charcoal-1 sm:pr-0"
      >
        <div
          class="sticky left-0 z-10 flex h-full border-r border-charcoal-2 bg-white px-6 py-4 group-hover:bg-charcoal-1"
        >
          <ShipmentsCountIcon
            class="mr-4 hidden self-center md:flex"
            data-testid="shipment-count"
            :value="shipmentNumber"
          />
          <BaseItem v-bind="bookingNumber" />
        </div>
        <BaseItem
          class="px-2 py-4"
          v-for="item in details"
          v-bind="item"
          :key="item.key"
        />
        <div class="px-2 py-4">
          <p class="truncate whitespace-nowrap text-body-2 text-charcoal-6">
            {{ shipmentArrivedText }}
          </p>
          <div class="flex items-center gap-x-2">
            <Icon
              v-if="shipmentCompleted"
              icon="ic:baseline-check-circle"
              class="text-green-6"
            />
            <p v-title class="truncate">
              {{ date }}
            </p>
          </div>
        </div>
        <div
          v-if="useOrganisationsStore().canReceiveShipmentsNotifications"
          @click.stop
          class="flex items-center justify-center"
        >
          <NotifyBellButton
            v-model="inputValue"
            :description="getShipmentTooltipText(inputValue)"
          />
        </div>
        <div
          class="pointer-events-none sticky right-0 ml-auto w-0 shrink-0 self-stretch sm:right-0"
        >
          <div class="relative h-full">
            <div
              class="absolute inset-y-0 right-0 grid w-14 place-items-center bg-fade"
            >
              <Icon icon="mdi:chevron-right" class="text-primary-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </RouterLink>
</template>
