<script lang="ts" setup name="NavBarLink">
import { computed } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { RouterLink, useRoute } from 'vue-router';

import { injectSidebarState } from '~/features/useSidebarState';

import NavBarLinkCollapsible from '~/components/NavBarLinkCollapsible.vue';
import NavBarLinkStyle from '~/components/NavBarLinkStyle.vue';

import type { IconType, ISidebarLinkChild } from '~/types';

const {
  label,
  to,
  icon,
  dataTest = 'nav-item',
  children,
} = defineProps<{
  label: string;
  to: RouteLocationNamedRaw;
  icon?: IconType;
  dataTest?: string;
  children?: ISidebarLinkChild[];
}>();

const emit = defineEmits<{
  close: [];
}>();

const currentRoute = useRoute();

const sidebarState = injectSidebarState();

const routeIsActive = computed(() => {
  const name = (currentRoute?.name as string) ?? 'unknown';
  const toName = (to?.name as string) ?? 'toUnknown';

  return name.startsWith(toName.replace('Index', ''));
});

const onClickLink = () => {
  sidebarState.setSidebarVisible(false);
  emit('close');
};
</script>

<template>
  <NavBarLinkCollapsible
    v-if="children"
    :to="to"
    :icon="icon"
    :label="label"
    :data-test="dataTest"
    :children="children"
  >
    <nav class="mt-1">
      <NavBarLink
        v-for="child in children"
        :key="child.label"
        v-bind="child"
        @click="emit('close')"
      />
    </nav>
  </NavBarLinkCollapsible>
  <RouterLink
    v-else
    class="block"
    v-slot="{ isActive }"
    :to="to"
    :data-test="dataTest"
    @click="onClickLink"
  >
    <NavBarLinkStyle
      :icon="icon"
      :label="label"
      :active="isActive || routeIsActive"
    />
  </RouterLink>
</template>
