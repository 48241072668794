<script setup lang="ts" name="SailingScheduleTradeLaneVesselHeading">
import SailingScheduleTradeLaneHeading from '~/components/SailingScheduleTradeLaneHeading.vue';
import SortIcon from '~/components/SortIcon.vue';

const { id, name, vvn, sorted } = defineProps<{
  id: string;
  name: string;
  vvn: string | number;
  sorted: boolean;
}>();
const emit = defineEmits<{ (e: 'sort', id: string): void }>();

const onSort = () => {
  emit('sort', id);
};
</script>

<template>
  <button type="button" @click="onSort">
    <SailingScheduleTradeLaneHeading class="flex w-44 shrink-0 items-center">
      <div class="text-left">
        <p v-title class="truncate">
          {{ name }}
        </p>
        <p v-title class="truncate">
          {{ vvn }}
        </p>
      </div>
      <div class="m-2"><SortIcon :sorted="sorted" /></div>
    </SailingScheduleTradeLaneHeading>
  </button>
</template>
