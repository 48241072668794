<script lang="ts" setup name="SailingScheduleTradeLaneDescription">
import { computed } from 'vue';

import { getLaneDescription } from '~/features/useSailingSchedule';

import BaseTag from '~/components/BaseTag.vue';

import type { BaseTagContrastType } from '~/types';

const { tagVariant, tradeLane } = defineProps<{
  tagVariant: BaseTagContrastType;
  tradeLane: string;
}>();

const laneDescription = computed(() => getLaneDescription(tradeLane));
</script>

<template>
  <div class="flex items-center gap-2">
    <BaseTag :variant="tagVariant" :name="tradeLane" />

    <h2 class="font-bold leading-none">
      {{ laneDescription }}
    </h2>
  </div>
</template>
