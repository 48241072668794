<script setup lang="ts" name="SailingScheduleTradeLaneVoyagesNoData">
import SailingScheduleTradeLaneHeading from '~/components/SailingScheduleTradeLaneHeading.vue';
import SailingScheduleTradeLanePort from '~/components/SailingScheduleTradeLanePort.vue';
import SailingScheduleTradeLaneVoyagesLayout from '~/components/SailingScheduleTradeLaneVoyagesLayout.vue';

const data = [
  {
    first: true,
    last: false,
    name: 'Load Port',
    id: '1',
    visible: true,
  },
  {
    first: false,
    last: true,
    name: 'Discharge Port',
    id: '2',
    visible: true,
  },
];
</script>

<template>
  <SailingScheduleTradeLaneVoyagesLayout>
    <SailingScheduleTradeLanePort
      v-for="item in data"
      :key="item.id"
      v-bind="item"
      portDisabled
    />

    <template #vessels>
      <div class="flex flex-col bg-charcoal-1">
        <div class="border-y border-r border-charcoal-2">
          <SailingScheduleTradeLaneHeading class="flex items-center" />
        </div>

        <div
          class="flex h-28 w-full items-center justify-center bg-white text-center"
        >
          <p class="px-4 text-body-1">
            We apologize, but there are no voyages available for this trade at
            the moment.
          </p>
        </div>
      </div>
    </template>
  </SailingScheduleTradeLaneVoyagesLayout>
</template>
