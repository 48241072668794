<script setup lang="ts" name="RegistrationCheckboxInput">
import { computed } from 'vue';

import type { IRegistrationHighlightedOption } from '~/types';

const props = defineProps<{
  modelValue?: IRegistrationHighlightedOption;
  option: IRegistrationHighlightedOption;
  dataTest?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', options: IRegistrationHighlightedOption): void;
  (e: 'change'): void;
  (e: 'blur'): void;
}>();

const inputDataTest = computed(() => props.dataTest || 'radio-input');
</script>

<template>
  <label
    class="group flex w-full cursor-pointer items-center gap-2 px-4 py-2 hover:bg-charcoal-1"
    :data-test="inputDataTest"
  >
    <input
      class="hidden"
      type="checkbox"
      :value="option as any"
      :data-test="`${inputDataTest}-field`"
      @blur="emit('blur')"
      @change="emit('update:modelValue', option)"
    />
    <p class="w-full truncate group-hover:text-primary-8">
      <slot />
    </p>
  </label>
</template>
