<script lang="ts" setup name="VesselPreview">
import { computed, ref } from 'vue';

import Icon from '~/components/Icon.vue';
import SectionCard from '~/components/SectionCard.vue';
import VesselPreviewButton from '~/components/VesselPreviewButton.vue';

const { images = [] } = defineProps<{
  images?: {
    title: string;
    source: string;
  }[];
}>();

const currentImage = ref(0);
const expanded = ref(false);

const hasImages = computed(() => images.length > 0);

const hasMultipleImages = computed(() => images.length > 1);

const title = computed(() =>
  hasImages.value ? images[currentImage.value].title : 'General Preview',
);

const isHidden = computed(
  () => (index: number) => currentImage.value !== index,
);

const nextImage = () => {
  currentImage.value += 1;
  if (currentImage.value >= images.length) currentImage.value = 0;
};

const prevImage = () => {
  currentImage.value -= 1;
  if (currentImage.value < 0) currentImage.value = images.length - 1;
};
</script>

<template>
  <div>
    <SectionCard :title="title">
      <div v-if="hasImages" class="flex items-center lg:h-[500px] lg:space-x-4">
        <div v-if="hasMultipleImages" class="hidden lg:block">
          <VesselPreviewButton icon="mdi:chevron-left" @click="prevImage" />
        </div>

        <button
          v-for="(image, index) in images"
          :key="image.source"
          type="button"
          class="group relative h-full flex-1 lg:p-4"
          :class="{ hidden: isHidden(index) }"
          @click="expanded = true"
        >
          <img
            :src="image.source"
            :alt="image.title"
            class="h-full w-full object-contain"
          />

          <div
            class="flex-center absolute inset-0 -m-1 rounded-lg bg-black/25 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
          >
            <Icon
              icon="mdi:magnify"
              size="custom"
              class="text-5xl text-white"
            />
          </div>
        </button>

        <div v-if="hasMultipleImages" class="hidden lg:block">
          <VesselPreviewButton icon="mdi:chevron-right" @click="nextImage" />
        </div>
      </div>

      <div v-else class="flex h-[500px] items-center justify-center">
        <div>
          <img
            src="/images/illustrations/woman_on_ledge.svg"
            class="h-auto w-64 opacity-50"
            alt="no preview available"
          />
          <p class="mt-4 text-center text-charcoal-6">No Preview Available</p>
        </div>
      </div>
    </SectionCard>

    <div
      v-if="expanded"
      class="max-w-screen fixed inset-0 z-50 flex h-screen items-center bg-black/75 p-2 xl:space-x-4 xl:p-8"
    >
      <VesselPreviewButton
        icon="ic:round-close"
        class="absolute right-0 top-0 m-8"
        @click="expanded = false"
      />

      <VesselPreviewButton
        v-if="hasMultipleImages"
        class="hidden xl:flex"
        icon="mdi:chevron-left"
        @click="prevImage"
      />

      <div v-if="hasMultipleImages" class="absolute left-5">
        <VesselPreviewButton
          class="xl:hidden"
          size="sm"
          icon="mdi:chevron-left"
          @click="prevImage"
        />
      </div>

      <img
        v-for="(image, index) in images"
        :key="image.source"
        :src="image.source"
        :alt="image.title"
        class="max-h-full flex-1 object-contain"
        :class="{ hidden: isHidden(index) }"
      />

      <div v-if="hasMultipleImages" class="absolute right-5">
        <VesselPreviewButton
          class="xl:hidden"
          icon="mdi:chevron-right"
          size="sm"
          @click="nextImage"
        />
      </div>

      <VesselPreviewButton
        v-if="hasMultipleImages"
        class="hidden xl:flex"
        icon="mdi:chevron-right"
        @click="nextImage"
      />
    </div>
  </div>
</template>
