import { http, type HttpHandler, HttpResponse } from 'msw';

import { KPI_TRANSIT_TIME_RULE } from '~/constants';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

const base = `${baseEndpoint}/api/customer/organisations/1/kpi-settings/transitTime/1/edit`;
const isPortPairs = false;
const kpiTargets = isPortPairs
  ? [
      {
        days: '2',
        loadPort: {
          id: 438,
          name: 'Santos',
        },
        dischargePort: {
          id: 2738,
          name: 'Ghent',
        },
      },
      {
        days: '2',
        loadPort: {
          id: 438,
          name: 'Santos',
        },
        dischargePort: {
          id: 2738,
          name: 'Ghent',
        },
      },
    ]
  : {
      days: '2',
      name: 'Santos',
    };

const type = isPortPairs
  ? KPI_TRANSIT_TIME_RULE.PORT_PAIRS
  : KPI_TRANSIT_TIME_RULE.FIRST_TO_LAST;

export default [
  http.get(base, async () => {
    return HttpResponse.json({
      data: {
        contracts: [
          {
            id: 19,
            name: 'ESA-MED.2017.CMPC',
            periodStart: 'Dec 2016',
            periodEnd: 'Dec 2020',
            tradelane: 'ESA-MED',
            loadPorts: [{ id: 1, name: 'Rio Grande (Brazil)' }],
            dischargePorts: [{ id: 2, name: 'Palamos' }],
          },
          {
            id: 19,
            name: 'ESA-MED.2017.CMPC',
            periodStart: 'Dec 2016',
            periodEnd: 'Dec 2020',
            tradelane: 'ESA-MED',
            loadPorts: [{ id: 3, name: 'Rio Grande (Brazil)' }],
            dischargePorts: [{ id: 4, name: 'Palamos' }],
          },
        ],
        kpi: {
          id: '197779bf-3d82-4792-9d7d-0cf7bbbec16d',
          visible: true,
          periodStart: '5 Mar 2023',
          periodEnd: '27 Apr 2023',
          tradeContract: {
            id: 19,
            name: 'ESA-MED.2017.CMPC',
            periodStart: 'Dec 2016',
            periodEnd: 'Dec 2020',
            tradelane: 'ESA-MED',
            loadPorts: [{ id: 438, name: 'Rio Grande (Brazil)' }],
            dischargePorts: [{ id: 1488, name: 'Palamos' }],
          },
          type, // firstToLast/lastToFirst/lastToFirst/portPairs
          kpiTargets,
        },
      },
    });
  }),
] satisfies HttpHandler[];
