<script setup lang="ts" name="OrganisationHomeStatistics">
import { computed, onMounted } from 'vue';

import { STATES } from '~/constants';
import {
  useHomeStore,
  useOrganisationsStore,
  useProfileStore,
  useSnackbarStore,
} from '~/store';

import { useShowModal } from '~/features/useModalsProvider';

import ComponentErrorCard from '~/components/ComponentErrorCard.vue';
import DisplayMetricStandardLayout from '~/components/DisplayMetricStandardLayout.vue';
import OrganisationHomeStatisticsWidget from '~/components/OrganisationHomeStatisticsWidget.vue';
import OrganisationHomeStatisticsWidgetsHeader from '~/components/OrganisationHomeStatisticsWidgetsHeader.vue';
import OrganisationHomeStatisticsWidgetSkeleton from '~/components/OrganisationHomeStatisticsWidgetSkeleton.vue';
import OrganisationHomeStatisticsWidgetsNoData from '~/components/OrganisationHomeStatisticsWidgetsNoData.vue';
import OrganisationHomeWidgetsSettingsModal from '~/components/OrganisationHomeWidgetsSettingsModal.vue';

import type { IOrganisation } from '~/types';

const { organisation } = defineProps<{
  organisation: IOrganisation;
}>();

const showModal = useShowModal();
const onOpenStatisticsModal = async () => {
  const result = await showModal(OrganisationHomeWidgetsSettingsModal, {
    organisationId: organisation.id,
  });

  if (result.action === 'SAVE') {
    updateSettings(result.payload);
  }
};

const FALLBACK_WIDGETS_COUNT = 3;

const snackbar = useSnackbarStore();

const homeStore = useHomeStore();
const profileStore = useProfileStore();

const widgetsCount = computed(
  () => organisation.countWidgets || FALLBACK_WIDGETS_COUNT,
);

const showSuccessSnackbar = () => {
  snackbar.add({
    type: 'success',
    html: '<div><span class="font-bold">Statistics</span> customised successfully.</div>',
  });
};

const updateSettings = (count: number) => {
  useOrganisationsStore().updateWidgetCount(count);
  homeStore.fetchStatistics(organisation.id, showSuccessSnackbar);
};

onMounted(() => homeStore.fetchStatistics(organisation.id));
</script>

<template>
  <div>
    <div class="space-y-2" v-if="homeStore.statisticsStateIs(STATES.LOADING)">
      <OrganisationHomeStatisticsWidgetsHeader
        data-testid="home-statistics-header"
      />
      <DisplayMetricStandardLayout>
        <OrganisationHomeStatisticsWidgetSkeleton
          v-for="widget in widgetsCount"
          :key="widget"
          data-testid="home-statistics-loading"
        />
      </DisplayMetricStandardLayout>
    </div>

    <ComponentErrorCard
      v-else-if="homeStore.statisticsStateIs(STATES.ERROR)"
      data-testid="home-statistics-error"
      styled
      @retry="homeStore.fetchStatistics(organisation.id)"
    />

    <div v-else>
      <OrganisationHomeStatisticsWidgetsHeader
        data-testid="home-statistics-header"
        :spectator="profileStore.isSpectator"
        @open="onOpenStatisticsModal"
      />

      <OrganisationHomeStatisticsWidgetsNoData
        v-if="homeStore.statisticsStateIs(STATES.NO_DATA)"
        data-testid="home-statistics-no-data"
        :spectator="profileStore.isSpectator"
        @openDialog="onOpenStatisticsModal"
      />

      <DisplayMetricStandardLayout v-else class="mt-2">
        <OrganisationHomeStatisticsWidget
          v-for="widget in homeStore.widgetsData"
          :key="widget.id"
          data-testid="home-statistics-widget"
          :widget="widget"
          :organisation-id="organisation.id"
        />
      </DisplayMetricStandardLayout>
    </div>
  </div>
</template>
