import type { RouteRecordRaw } from 'vue-router';

import { PERMISSION } from '~/constants';

import { makeOrganisationRoute } from '~/features/useRouteMeta';

import OrganisationHomeIndex from '~/pages/OrganisationHomeIndex.vue';

export const organisationRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    name: 'OrganisationHomeIndex',
    path: '/home',
    permission: PERMISSION.DASHBOARD_VIEW_OWN,
    component: OrganisationHomeIndex,
  }),
];
