<script setup lang="ts" name="PublicSailingScheduleAddTradesOverlay">
import { useVModel } from '@vueuse/core';

import { usePublicSailingScheduleAddTrade } from '~/features/usePublicSailingScheduleAddTrade';
import { useService } from '~/features/useService';

import Service from '~/services/Service';

import BaseButton from '~/components/BaseButton.vue';
import BaseOverlay from '~/components/BaseOverlay.vue';
import PublicSailingScheduleAddTradeDropdown from '~/components/PublicSailingScheduleAddTradeDropdown.vue';
import PublicSailingScheduleTradesList from '~/components/PublicSailingScheduleTradesList.vue';

import type { ITradeLane } from '~/types';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
  (e: 'applyTradesSelection'): void;
}>();

const overlay = useVModel(props, 'modelValue', emit);

const {
  search,
  searchActive,
  isTradelanesEmpty,
  selectedTradelanes,
  filteredTradeOptions,
  searchTradesStatusText,
  hasReachedMaximumTrades,
  hasAllSameTrades,

  setTradelanes,
  setSearchStatus,
  setInitialTradelanes,
  setSelectedTradelanes,
} = usePublicSailingScheduleAddTrade();

const closeOverlay = () => {
  overlay.value = false;

  if (!hasAllSameTrades.value) {
    emit('applyTradesSelection');
  }
};

const selectedTradesServiceState = useService(
  Service.publicSailingSchedule().customize().index(),
  ({ data }: { data: ITradeLane[] }) => {
    setInitialTradelanes(data);
    setSelectedTradelanes(data);
    return true;
  },
);
</script>

<template>
  <BaseOverlay
    v-model="overlay"
    closable
    fullscreen
    backTo="Sailing Schedule"
    @back="overlay = false"
  >
    <div class="mx-2">
      <p class="mx-2 text-subtitle-1">Add Trades to The Sailing Schedule</p>

      <PublicSailingScheduleAddTradeDropdown
        v-model="search"
        :searchActive="searchActive"
        :trades-empty="isTradelanesEmpty"
        :filteredTradeOptions="filteredTradeOptions"
        :searchTradesStatusText="searchTradesStatusText"
        :hasReachedMaximumTrades="hasReachedMaximumTrades"
        :selectedTradesServiceState="selectedTradesServiceState"
        @setTradelanes="setTradelanes"
        @setSearchStatus="setSearchStatus"
        @setSelectedTradelanes="setSelectedTradelanes"
      />

      <div class="my-4 flex justify-end gap-4">
        <BaseButton variant="btn-secondary" @click="overlay = false">
          Cancel
        </BaseButton>
        <BaseButton @click="closeOverlay"> Apply </BaseButton>
      </div>

      <PublicSailingScheduleTradesList
        class="overflow-y-auto"
        :selectedTradelanes="selectedTradelanes"
        :loading="selectedTradesServiceState.stateIsLoading"
        @setSearchStatus="setSearchStatus"
        @setSelectedTradelanes="setSelectedTradelanes"
      />
    </div>
  </BaseOverlay>
</template>
