<script lang="ts" setup name="PublicSailingScheduleTradeLane">
import {
  getCallingAtPorts,
  getTradeDeskEmail,
} from '~/features/useSailingSchedule';

import BaseItem from '~/components/BaseItem.vue';
import InfoTooltip from '~/components/InfoTooltip.vue';
import SailingScheduleCard from '~/components/SailingScheduleCard.vue';
import SailingScheduleCardContent from '~/components/SailingScheduleCardContent.vue';
import SailingScheduleCardFooter from '~/components/SailingScheduleCardFooter.vue';
import SailingScheduleTradeLaneActions from '~/components/SailingScheduleTradeLaneActions.vue';
import SailingScheduleTradelaneButton from '~/components/SailingScheduleTradelaneButton.vue';
import SailingScheduleTradeLaneDescription from '~/components/SailingScheduleTradeLaneDescription.vue';
import SailingScheduleTradeLaneShipSVG from '~/components/SailingScheduleTradeLaneShipSVG.vue';

import type {
  BaseTagContrastType,
  ITradeLanePort,
  ITradeLaneVessel,
} from '~/types';

const {
  isOpen = false,
  idle = false,
  noData = false,
  color,
  vessels = [],
  ports = [],

  tradelane,
  tradeEmail,
  loadingPort,
  dischargePort,
  callingAtPorts,
} = defineProps<{
  isOpen?: boolean;
  idle?: boolean;
  noData?: boolean;
  color: string;
  vessels?: ITradeLaneVessel[];
  ports?: ITradeLanePort[];
  tagVariant: BaseTagContrastType;

  tradelane: string;
  tradeEmail: string;
  loadingPort: string;
  dischargePort: string;
  callingAtPorts: string[];
}>();

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'updated', vessels: ITradeLaneVessel[]): void;
  (e: 'reset'): void;
}>();
</script>

<template>
  <SailingScheduleCard :isOpen="isOpen" @click="emit('click')">
    <template #heading>
      <SailingScheduleTradeLaneDescription
        :trade-lane="tradelane"
        :tagVariant="tagVariant"
      />
    </template>

    <template #actions>
      <SailingScheduleTradeLaneActions v-if="isOpen" :idle="idle">
        <SailingScheduleTradelaneButton
          v-if="!noData"
          :trade-lane="tradelane"
          :tagVariant="tagVariant"
          :vessels="vessels"
          :ports="ports"
        />

        <InfoTooltip
          v-if="!noData"
          title="Trade Desk email"
          :description="getTradeDeskEmail(tradeEmail)"
        />
      </SailingScheduleTradeLaneActions>
    </template>

    <SailingScheduleCardContent :isOpen="isOpen">
      <BaseItem label="Departure" fallback="-" :value="loadingPort" />
      <SailingScheduleTradeLaneShipSVG class="hidden xs:block" :color="color" />
      <BaseItem label="Arrival" fallback="-" :value="dischargePort" />

      <template #append>
        <BaseItem
          label="Calling At"
          fallback="-"
          :value="getCallingAtPorts(callingAtPorts)"
        />
      </template>
    </SailingScheduleCardContent>

    <SailingScheduleCardFooter :isOpen="isOpen" />
  </SailingScheduleCard>
</template>
