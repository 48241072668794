<script lang="ts" setup name="NavBarLinkCollapsible">
import { computed, ref, watchEffect } from 'vue';
import { type RouteLocationNamedRaw } from 'vue-router';
import { useRoute } from 'vue-router';

import { injectSidebarState } from '~/features/useSidebarState';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import Icon from '~/components/Icon.vue';
import NavBarLinkStyle from '~/components/NavBarLinkStyle.vue';

import type { IconType, ISidebarLinkChild } from '~/types';

const {
  label,
  to,
  icon,
  dataTest = 'nav-item',
  children,
} = defineProps<{
  label: string;
  to: RouteLocationNamedRaw;
  icon?: IconType;
  dataTest?: string;
  children: ISidebarLinkChild[];
}>();

const sidebarState = injectSidebarState();

const collapsibleOpen = ref(false);
const currentRoute = useRoute();

const visitedOrganisationStatistics = ref(false);

const routeIsActive = computed(() => {
  const name = (currentRoute?.name as string) ?? 'unknown';
  const toName = (to?.name as string) ?? 'toUnknown';

  return name.startsWith(toName.replace('Index', ''));
});

const onClose = () => {
  collapsibleOpen.value = false;
  visitedOrganisationStatistics.value = false;
};

const currentRouteIsOneOfChildren = computed(() =>
  children.some((child) => child.to.name === currentRoute.name),
);

const onToggle = (toggle: () => void) => {
  collapsibleOpen.value = !collapsibleOpen.value;

  toggle();

  sidebarState.openSidebar();
};

watchEffect(() => {
  if (sidebarState.closed.value) {
    onClose();
    return;
  }

  if (currentRouteIsOneOfChildren.value) {
    collapsibleOpen.value = true;
    visitedOrganisationStatistics.value = true;
  }
});
</script>

<template>
  <BaseCollapsible :openByDefault="collapsibleOpen" @close="onClose">
    <template #trigger="{ toggle, isOpen }">
      <button
        type="button"
        :data-test="dataTest"
        class="block w-full"
        @click="onToggle(toggle)"
      >
        <NavBarLinkStyle
          :active="routeIsActive && !isOpen"
          :label="label"
          :icon="icon"
        >
          <Icon
            v-if="!sidebarState.closed.value"
            :icon="isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'"
            class="ml-auto text-ocean-2"
          />
        </NavBarLinkStyle>
      </button>
    </template>
    <div>
      <slot />
    </div>
  </BaseCollapsible>
</template>
