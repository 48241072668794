<script setup name="PublicCookiePolicy">
import BaseCardLink from '~/components/BaseCardLink.vue';
import CookiePolicy from '~/components/CookiePolicy.vue';
import G2oPublicLayout from '~/components/G2oPublicLayout.vue';

const items = [
  {
    label: 'Privacy Policy',
    href: 'https://www.g2ocean.com/corporate-responsibility/privacy-notice/',
    target: '_blank',
  },
  {
    label: 'Terms of Service',
    href: '/pdfs/TermsandConditionsMyG2.pdf',
    target: '_blank',
  },
  {
    label: 'Cookie Policy',
    disabled: true,
  },
];
</script>

<template>
  <G2oPublicLayout>
    <p class="text-h3">Legal</p>
    <div class="mb-20 flex flex-col-reverse lg:flex-row lg:space-x-16">
      <div>
        <div class="flex flex-col divide-y divide-charcoal-2">
          <div v-for="item in items" :key="item.label" class="py-4">
            <BaseCardLink v-bind="item">
              {{ item.label }}
            </BaseCardLink>
          </div>
        </div>
        <div class="mt-8 min-w-[290px] rounded-md bg-charcoal-1 p-6">
          <p class="text-xl font-bold text-primary-8">Get help</p>
          <a
            href="mailto:myg2support@g2ocean.com"
            class="block py-6 text-primary-8"
            target="_blank"
          >
            myg2support@g2ocean.com
          </a>
        </div>
      </div>
      <CookiePolicy />
    </div>
  </G2oPublicLayout>
</template>
