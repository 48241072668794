import { reactive, type Ref, ref, watch } from 'vue';

import type { ISailingScheduleQuery } from '~/types';

export const useSailingScheduleMobileFilters = (
  query: Ref<ISailingScheduleQuery>,
) => {
  const activeQuery = reactive<ISailingScheduleQuery>({
    tradelanes: query.value.tradelanes,
    loadingPorts: query.value.loadingPorts,
    dischargePorts: query.value.dischargePorts,
    vessels: query.value.vessels,
  });

  const initialActiveQuery = ref(JSON.parse(JSON.stringify(activeQuery)));

  const applyQuery = () => {
    for (const key in activeQuery) {
      query.value[key] = activeQuery[key];
    }

    initialActiveQuery.value = JSON.parse(JSON.stringify(activeQuery));
  };

  const resetQuery = () => {
    for (const key in activeQuery) {
      activeQuery[key] = JSON.parse(
        JSON.stringify(initialActiveQuery.value[key]),
      );
    }
  };

  watch(
    query,
    (newQuery) => {
      for (const key in newQuery) {
        activeQuery[key] = newQuery[key];
      }
    },
    { deep: true },
  );

  return {
    activeQuery,

    applyQuery,
    resetQuery,
  };
};
