<script lang="ts" setup name="SailingScheduleTradeLaneShipSVG">
import { computed } from 'vue';

const { color } = defineProps<{
  color?: string;
}>();

const iconStyle = computed(() => `color: ${color}; `);
</script>

<template>
  <svg
    viewBox="0 0 95 17"
    :style="iconStyle"
    class="h-4 w-24 group-hover:!text-primary-8"
  >
    <path
      d="M0 16.5312H0.486111L0.486111 15.5312H0L0 16.5312ZM1.45833 16.5312H2.43056V15.5312H1.45833L1.45833 16.5312ZM3.40278 16.5312H4.375V15.5312L3.40278 15.5312V16.5312ZM5.34722 16.5312H6.31944V15.5312H5.34722V16.5312ZM7.29167 16.5312L8.26389 16.5312V15.5312H7.29167V16.5312ZM9.23611 16.5312H10.2083V15.5312H9.23611V16.5312ZM11.1806 16.5312H12.1528V15.5312H11.1806V16.5312ZM13.125 16.5312H14.0972V15.5312H13.125V16.5312ZM15.0694 16.5312H16.0417V15.5312H15.0694V16.5312ZM17.0139 16.5312H17.9861V15.5312H17.0139V16.5312ZM18.9583 16.5312H19.9306V15.5312H18.9583V16.5312ZM20.9028 16.5312L21.875 16.5312V15.5312H20.9028V16.5312ZM22.8472 16.5312H23.8194V15.5312H22.8472V16.5312ZM24.7917 16.5312H25.7639V15.5312L24.7917 15.5312V16.5312ZM26.7361 16.5312H27.7083V15.5312H26.7361V16.5312ZM28.6805 16.5312H29.6528V15.5312H28.6805V16.5312ZM30.625 16.5312H31.5972V15.5312H30.625V16.5312ZM32.5694 16.5312H33.5417V15.5312L32.5694 15.5312V16.5312ZM34.5139 16.5312H35V15.5312H34.5139V16.5312Z"
      fill="currentColor"
    />
    <path
      d="M57 16.5313L57.5 16.5313L57.5 15.5313L57 15.5313L57 16.5313ZM58.5 16.5312L59.5 16.5312L59.5 15.5312L58.5 15.5312L58.5 16.5312ZM60.5 16.5312L61.5 16.5312L61.5 15.5312L60.5 15.5312L60.5 16.5312ZM62.5 16.5312L63.5 16.5312L63.5 15.5312L62.5 15.5312L62.5 16.5312ZM64.5 16.5312L65.5 16.5312L65.5 15.5312L64.5 15.5312L64.5 16.5312ZM66.5 16.5312L67.5 16.5312L67.5 15.5312L66.5 15.5312L66.5 16.5312ZM68.5 16.5312L69.5 16.5312L69.5 15.5312L68.5 15.5312L68.5 16.5312ZM70.5 16.5312L71.5 16.5312L71.5 15.5312L70.5 15.5312L70.5 16.5312ZM72.5 16.5312L73.5 16.5312L73.5 15.5312L72.5 15.5312L72.5 16.5312ZM74.5 16.5312L75.5 16.5312L75.5 15.5312L74.5 15.5312L74.5 16.5312ZM76.5 16.5312L77.5 16.5312L77.5 15.5312L76.5 15.5312L76.5 16.5312ZM78.5 16.5312L79.5 16.5312L79.5 15.5312L78.5 15.5312L78.5 16.5312ZM80.5 16.5312L81.5 16.5312L81.5 15.5312L80.5 15.5312L80.5 16.5312ZM82.5 16.5312L83.5 16.5312L83.5 15.5312L82.5 15.5312L82.5 16.5312ZM84.5 16.5312L85.5 16.5312L85.5 15.5312L84.5 15.5312L84.5 16.5312ZM86.5 16.5312L87.5 16.5312L87.5 15.5312L86.5 15.5312L86.5 16.5312ZM88.5 16.5312L89.5 16.5312L89.5 15.5312L88.5 15.5312L88.5 16.5312ZM90.5 16.5312L91.5 16.5312L91.5 15.5312L90.5 15.5312L90.5 16.5312ZM92.5 16.5312L93.5 16.5312L93.5 15.5312L92.5 15.5312L92.5 16.5312ZM94.5 16.5312L95 16.5312L95 15.5312L94.5 15.5312L94.5 16.5312Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.0933 16.4974C32.0933 16.4974 30.1767 15.0926 29.0136 14.2102C28.983 12.613 29.0136 10.6161 29.0136 10.6161H31.6497V3.04883H33.482V6.71291H34.7564V5.16069L36.5087 5.11427V0.5H37.863V5.12017H40.1867L40.2166 7.26261L39.2174 7.27072V10.5877L45.9412 10.5949V3.35294H47.8235V3.35396L48.5127 3.35313L50.8656 7.42854L49.2355 8.36972L47.8235 5.92418V10.5969L55.3529 10.6049V3.35294H57.2353V3.35396L57.9244 3.35313L60.2774 7.42854L58.6472 8.36972L57.2353 5.92418V10.6069L65.8445 10.6161C64.8129 11.9502 61.2965 16.5 61.2965 16.5C60.8209 16.4996 32.0933 16.4974 32.0933 16.4974Z"
      fill="currentColor"
    />
  </svg>
</template>
