<script setup lang="ts" name="PublicSidebar">
import { computed } from 'vue';

import { usePublicNavBarLinks } from '~/features/useNavBarLinks';

import Icon from '~/components/Icon.vue';
import LogoG2OVertical from '~/components/LogoG2OVertical.vue';
import NavBarLink from '~/components/NavBarLink.vue';
import SidebarLayout from '~/components/SidebarLayout.vue';
import SidebarToggle from '~/components/SidebarToggle.vue';

const { isOpen } = defineProps<{
  isOpen: boolean;
}>();
const emit = defineEmits<{
  (e: 'close'): void;
}>();
const activeLinks = computed(() => usePublicNavBarLinks());
</script>

<template>
  <SidebarLayout :open="isOpen">
    <div class="flex h-16 w-full items-center">
      <RouterLink :to="{ name: 'PublicSailingScheduleIndex' }">
        <LogoG2OVertical class="ml-4" />
      </RouterLink>
      <button
        class="ml-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-primary-6 lg:hidden"
        @click.stop="emit('close')"
      >
        <Icon icon="ic:round-close" />
      </button>
    </div>
    <nav class="my-4 space-y-1">
      <NavBarLink
        v-for="link in activeLinks"
        v-bind="link"
        :key="link.dataTest"
        @close="emit('close')"
      />
    </nav>
    <div class="flex flex-1 flex-col justify-end">
      <SidebarToggle />
    </div>
  </SidebarLayout>
</template>
