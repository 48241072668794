<script lang="ts" setup name="OrganisationHomeCarbonWidget">
import { computed } from 'vue';

import { BASE_TAG_CONTRAST } from '~/constants';

import { sendManageOffsetEvent } from '~/features/useAnalytics';
import { formatNumber } from '~/features/useNumbers';

import BaseButton from '~/components/BaseButton.vue';
import BaseTag from '~/components/BaseTag.vue';
import CarbonOffsetButton from '~/components/CarbonOffsetButton.vue';
import Icon from '~/components/Icon.vue';
import TitleCaption from '~/components/TitleCaption.vue';

const {
  organisationId,
  total = 0,
  offsetted,
  draft,
  emissionsFeatureEnabled = false,
  isG2OCustomerUser = false,
  loading,
} = defineProps<{
  organisationId?: string;
  total?: number;
  offsetted?: number;
  draft?: number;
  emissionsFeatureEnabled?: boolean;
  isG2OCustomerUser?: boolean;
  loading: boolean;
}>();

const toShipmentsOffsetPage = computed(() => ({
  name: 'OffsetOrganisationShipmentsEmissionsIndex',
  params: {
    organisationId: organisationId,
  },
}));

const toStatisticsPage = computed(() => ({
  name: 'OrganisationStatisticsV2Emissions',
  params: {
    organisationId: organisationId,
  },
}));

const onManageOffsetClick = () => {
  sendManageOffsetEvent();
};
</script>

<template>
  <div class="mb-2 overflow-hidden rounded-md bg-white drop-shadow">
    <div class="relative flex items-end">
      <img
        class="h-36 w-full object-cover"
        alt="widget cover image"
        src="/images/carbonWidgetCover.jpg"
      />
      <div class="absolute bottom-0 left-0 w-full">
        <h2
          class="pb-4 pl-6 text-left font-body text-xl font-bold text-white lg:w-full lg:pl-0 lg:text-center xl:pl-6 xl:text-left"
        >
          CO2 Emissions
        </h2>
      </div>
    </div>

    <div class="flex flex-wrap gap-x-2 p-6">
      <div v-if="loading" class="flex flex-col gap-y-1">
        <p class="text-loading h-5 w-32"></p>
        <p class="text-loading h-5 w-32"></p>
      </div>
      <div v-else class="flex min-w-[150px] flex-1 flex-col space-y-4">
        <div>
          <TitleCaption truncate> Total CO2 MT YTD </TitleCaption>
          <p>
            {{ formatNumber(total) }}
            <span class="text-charcoal-6"> MT </span>
          </p>
        </div>

        <div v-if="offsetted">
          <TitleCaption truncate> Offsetted CO2 MT </TitleCaption>
          <div class="mt-2">
            <BaseTag
              :name="`${formatNumber(offsetted)} MT`"
              :variant="BASE_TAG_CONTRAST.GREEN_1"
            >
              <template #prepend>
                <Icon icon="ic:baseline-check-circle" class="text-green-6" />
              </template>
            </BaseTag>
          </div>
        </div>

        <div v-if="draft">
          <TitleCaption truncate> Offset In Draft </TitleCaption>
          <div class="mt-2">
            <BaseTag
              :name="`${formatNumber(draft)} MT`"
              :variant="BASE_TAG_CONTRAST.CHARCOAL_1"
            >
              <template #prepend>
                <Icon icon="ic:baseline-edit" class="text-charcoal-6" />
              </template>
            </BaseTag>
          </div>
        </div>
      </div>

      <div class="my-4 w-full space-y-2">
        <BaseButton
          v-if="isG2OCustomerUser && total"
          class="flex w-full gap-x-4"
          :to="toShipmentsOffsetPage"
          :skeletonLoading="loading"
          @click="onManageOffsetClick"
        >
          <p class="-mr-6 flex-grow text-center">Manage Offset</p>
          <template #rightIcon>
            <Icon icon="mdi:chevron-right" class="ml-auto" />
          </template>
        </BaseButton>
        <BaseButton
          v-if="emissionsFeatureEnabled"
          class="w-full"
          variant="btn-secondary"
          :to="toStatisticsPage"
          :skeletonLoading="loading"
        >
          see statistics
        </BaseButton>
      </div>

      <p v-if="loading" class="text-loading h-5 w-32"></p>
      <CarbonOffsetButton v-else />
    </div>
  </div>
</template>
