<script setup lang="ts" name="App">
import { computed, watch } from 'vue';
import { RouterView, useRoute, useRouter } from 'vue-router';

import {
  useAnalyticsStore,
  useAuthStore,
  useMaintenanceStore,
  useProfileStore,
  useSnackbarStore,
} from '~/store';

import { useModalsProvider } from '~/features/useModalsProvider';

import CookieLaw from '~/components/CookieLaw.vue';
import G2oTour from '~/components/G2oTour.vue';
import ModalsProvider from '~/components/ModalsProvider.vue';
import TheSnackbar from '~/components/TheSnackbar.vue';
import TheSnackbarPosition from '~/components/TheSnackbarPosition.vue';

import type { RouteKeyBuilder } from '~/types';

import { useSidebarStateProvider } from './features/useSidebarState';

useModalsProvider();
useSidebarStateProvider();

const authStore = useAuthStore();
const profileStore = useProfileStore();
const maintenanceStore = useMaintenanceStore();
const snackbarStore = useSnackbarStore();
const router = useRouter();
const route = useRoute();

const routeKey = computed(() => {
  if (!route.matched.length) {
    return route.path;
  }
  const keyBuilder = route.matched[0].meta?.keyBuilder as
    | RouteKeyBuilder
    | undefined;

  return keyBuilder?.(route) ?? route.path;
});

const isAuthenticated = computed(() => profileStore.isAuthenticated);

watch(
  () => [isAuthenticated.value, router.currentRoute.value.meta],
  ([isAuthenticated, meta]) => {
    if (isAuthenticated && meta?.requiresAuth) {
      useAnalyticsStore().toggleSleekPlanWidget(true);
    } else {
      useAnalyticsStore().toggleSleekPlanWidget(false);
    }
  },
  { immediate: true },
);

watch(
  () => maintenanceStore.inPublicMaintenance,
  (val) => {
    if (val) {
      authStore.logout();
    }
  },
);
</script>

<template>
  <div class="h-full">
    <component :is="route.meta.layout || 'div'" class="h-full">
      <RouterView :key="routeKey" />
    </component>
    <ModalsProvider />
    <G2oTour v-if="profileStore.tourIsVisible" class="z-40" />
    <CookieLaw />

    <TheSnackbarPosition :snackbar="snackbarStore.state">
      <TheSnackbar
        :snackbar="snackbarStore.state"
        @close="snackbarStore.remove"
      />
    </TheSnackbarPosition>
  </div>
</template>
