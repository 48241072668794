import { computed, type Ref, ref, watch } from 'vue';

interface IScreen<T> {
  name: string;
  screen: T;
}

export function useScreenStack<T extends string>(
  screens: IScreen<T>[],
  overlay?: Ref<boolean>,
) {
  const screenStack = ref([]) as Ref<IScreen<T>[]>;

  const overlayVisible = computed(() => screenStack.value.length > 0);

  const screenIs = (screenName: T) =>
    currentScreen.value?.screen === screenName;

  const pushScreen = (upcomingScreen: T) => {
    const screen = screens.find((s) => s.screen === upcomingScreen);

    if (screen) {
      screenStack.value.push(screen);
    }
  };

  const popScreen = () => {
    screenStack.value.pop();

    if (!overlayVisible.value && overlay) {
      overlay.value = false;
    }
  };

  const currentScreen = computed<IScreen<T> | undefined>(
    () => screenStack.value[screenStack.value.length - 1],
  );

  watch(
    () => overlay?.value,
    (val) => {
      if (val) {
        pushScreen(screens[0].screen);
      }
    },
    {
      immediate: true,
    },
  );

  return {
    screenIs,
    screenStack,
    pushScreen,
    popScreen,
    currentScreen,
    overlayVisible,
  };
}
