<script lang="ts" setup name="VesselPreviewButton">
import Icon from '~/components/Icon.vue';

import type { IconType } from '~/types';

const {
  icon,
  size = 'lg',
  disabled = false,
} = defineProps<{
  icon: IconType;
  disabled?: boolean;
  size?: 'sm' | 'lg';
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <button
    type="button"
    class="flex flex-shrink-0 items-center justify-center rounded-full"
    :class="{
      'h-09 w-09': size === 'lg',
      'h-6 w-6': size === 'sm',
      'bg-white shadow transition-colors duration-300 hover:bg-primary-8 hover:text-white':
        !disabled,
      'cursor-not-allowed bg-charcoal-1': disabled,
    }"
    @click="emit('click')"
  >
    <Icon :icon="icon" />
  </button>
</template>
