<script setup lang="ts" name="LayoutPublicUser">
import { ref } from 'vue';

import Icon from '~/components/Icon.vue';
import ProfileMenu from '~/components/ProfileMenu.vue';
import PublicSidebar from '~/components/PublicSidebar.vue';
import SupportButton from '~/components/SupportButton.vue';

const isOpen = ref(false);
</script>

<template>
  <div class="flex items-start">
    <div
      class="sticky bottom-0 top-0 z-10 flex-shrink-0 bg-navy-blue-4"
      :class="{
        'hidden lg:block': !isOpen,
        block: isOpen,
      }"
    >
      <div
        v-if="isOpen"
        class="fixed inset-0 z-10 bg-black opacity-75 lg:hidden"
        data-testid="navbar-bg-element"
        @click.stop="isOpen = false"
      />
      <PublicSidebar :isOpen="isOpen" @close="isOpen = false" />
    </div>

    <div
      class="flex-grow overflow-x-hidden"
      style="background: rgb(245, 245, 245)"
    >
      <div class="flex h-header flex-shrink-0 items-stretch bg-white shadow">
        <button
          data-test="navbar-toggle"
          class="mr-4 flex h-header w-header items-center justify-center hover:bg-charcoal-1 lg:hidden"
          @click.stop="isOpen = true"
        >
          <Icon icon="ic:baseline-menu" />
        </button>
        <div
          class="flex flex-grow justify-end"
          :class="{
            'pointer-events-none': isOpen,
          }"
        >
          <SupportButton :to="{ name: 'PublicSupportIndex' }" />

          <ProfileMenu class="lg:ml-auto" />
        </div>
      </div>

      <div
        class="w-full overflow-x-hidden"
        :class="{
          'pointer-events-none': isOpen,
        }"
      >
        <main class="w-full flex-grow p-2 sm:p-6">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>
