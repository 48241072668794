import type { RouteRecordRaw } from 'vue-router';

import { makePrivateRoute } from '~/features/useRouteMeta';

import CookiePolicy from '~/pages/CookiePolicy.vue';
import SupportIndex from '~/pages/SupportIndex.vue';

export const supportRoutes: Readonly<RouteRecordRaw[]> = [
  makePrivateRoute({
    name: 'SupportIndex',
    path: '/support',
    component: SupportIndex,
  }),
  makePrivateRoute({
    name: 'CookiePolicy',
    path: '/support/cookie-policy',
    component: CookiePolicy,
  }),
];
