import type { Directive } from 'vue';

// add all the elements inside modal which you want to make focusable
const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

type HTMLDivElement = HTMLElement & {
  __trapFocus: (e: KeyboardEvent) => void;
};

type FocusableElement = HTMLElement & {
  focus: () => void;
};

function mounted(el: HTMLDivElement) {
  const modal = el;

  // get first element to be focused inside modal
  const firstFocusableElement = modal.querySelectorAll(
    focusableElements,
  )[0] as FocusableElement;

  const focusableContent = modal.querySelectorAll(focusableElements);

  // get last element to be focused inside modal
  const lastFocusableElement = focusableContent[
    focusableContent.length - 1
  ] as FocusableElement;

  const trapFocus = (e: KeyboardEvent) => {
    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else {
      // if tab key is pressed
      if (document.activeElement === lastFocusableElement) {
        // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement.focus(); // add focus for the first focusable element
        e.preventDefault();
      }
    }
  };

  el.__trapFocus = trapFocus;
  document.addEventListener('keydown', el.__trapFocus.bind(el));
  firstFocusableElement?.focus();
}

function unmounted(el: HTMLDivElement) {
  document.removeEventListener('keydown', el.__trapFocus);
}

const focusTrap: Directive<HTMLDivElement> = {
  mounted,
  unmounted,
};

export default focusTrap;
