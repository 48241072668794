<script lang="ts" setup name="SailingScheduleTradeLane">
import { sendSailingScheduleCustomisedEvent } from '~/features/useAnalytics';
import { useShowModal } from '~/features/useModalsProvider';
import {
  getCallingAtPorts,
  getTradeDeskEmail,
} from '~/features/useSailingSchedule';

import BaseItem from '~/components/BaseItem.vue';
import Icon from '~/components/Icon.vue';
import InfoTooltip from '~/components/InfoTooltip.vue';
import SailingScheduleCard from '~/components/SailingScheduleCard.vue';
import SailingScheduleCardContent from '~/components/SailingScheduleCardContent.vue';
import SailingScheduleCardFooter from '~/components/SailingScheduleCardFooter.vue';
import SailingScheduleTradeLaneActions from '~/components/SailingScheduleTradeLaneActions.vue';
import SailingScheduleTradelaneButton from '~/components/SailingScheduleTradelaneButton.vue';
import SailingScheduleTradeLaneDescription from '~/components/SailingScheduleTradeLaneDescription.vue';
import SailingScheduleTradeLaneSettingsModal from '~/components/SailingScheduleTradeLaneSettingsModal.vue';
import SailingScheduleTradeLaneShipSVG from '~/components/SailingScheduleTradeLaneShipSVG.vue';

import type {
  BaseTagContrastType,
  ITradeLanePort,
  ITradeLaneVessel,
} from '~/types';

const {
  organisationId,
  isOpen = false,
  idle = false,
  settingsApplied = false,
  vessels = [],
  ports = [],

  tradelane,
  tradeEmail,
  loadingPort,
  dischargePort,
  callingAtPorts,
} = defineProps<{
  organisationId: OrganisationId;
  isOpen?: boolean;
  idle?: boolean;
  settingsApplied?: boolean;
  tagVariant: BaseTagContrastType;
  color: string;
  vessels?: ITradeLaneVessel[];
  ports?: ITradeLanePort[];

  tradelane: string;
  tradeEmail: string;
  loadingPort: string;
  dischargePort: string;
  callingAtPorts: string[];
}>();

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'updated', vessels: ITradeLaneVessel[]): void;
  (e: 'reset'): void;
}>();

const showModal = useShowModal();

const onOpenSettingsModal = async () => {
  sendSailingScheduleCustomisedEvent();

  const result = await showModal(SailingScheduleTradeLaneSettingsModal, {
    vessels: vessels,
  });

  if (result.action === 'SAVE') {
    emit('updated', result.payload);
  }
};
</script>

<template>
  <SailingScheduleCard :isOpen="isOpen" @click="emit('click')">
    <template #heading>
      <SailingScheduleTradeLaneDescription
        :trade-lane="tradelane"
        :tagVariant="tagVariant"
      />
    </template>

    <template #actions>
      <SailingScheduleTradeLaneActions v-if="isOpen" :idle="idle">
        <button
          v-tooltip.bottom.min="'Customise List'"
          type="button"
          data-testid="settings-button"
          class="grid h-08 w-08 place-items-center rounded-md outline-none"
          :class="{
            'bg-primary-8 text-white hover:bg-ocean-5': settingsApplied,
            'text-primary-8 hover:bg-charcoal-2': !settingsApplied,
          }"
          @click.stop="onOpenSettingsModal()"
        >
          <Icon icon="g2o:tune-rounded" size="3xl" />
        </button>

        <SailingScheduleTradelaneButton
          :organisation-id="organisationId"
          :trade-lane="tradelane"
          :tagVariant="tagVariant"
          :vessels="vessels"
          downloadable
          :ports="ports"
        />

        <InfoTooltip
          title="Trade Desk email"
          :description="getTradeDeskEmail(tradeEmail)"
        />

        <button
          v-if="settingsApplied"
          type="button"
          class="h-08 rounded-md px-2 text-xs font-bold uppercase tracking-widest text-red-6 hover:bg-charcoal-1"
          @click.stop="emit('reset')"
        >
          Reset List
        </button>
      </SailingScheduleTradeLaneActions>
    </template>

    <SailingScheduleCardContent :isOpen="isOpen">
      <BaseItem label="Departure" fallback="-" :value="loadingPort" />
      <SailingScheduleTradeLaneShipSVG class="hidden xs:block" :color="color" />
      <BaseItem label="Arrival" fallback="-" :value="dischargePort" />

      <template #append>
        <BaseItem
          label="Calling At"
          fallback="-"
          :value="getCallingAtPorts(callingAtPorts)"
        />
      </template>
    </SailingScheduleCardContent>

    <SailingScheduleCardFooter :isOpen="isOpen" />
  </SailingScheduleCard>
</template>
