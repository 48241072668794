<script setup lang="ts" name="InviteUserModal">
import { computed, onMounted, reactive, ref, watch } from 'vue';

import { EVENTS } from '~/constants';
import { useProfileStore, useSnackbarStore } from '~/store';

import { useEvents } from '~/features/useEvents';

import Service from '~/services/Service.js';

import BaseModal from '~/components/BaseModal.vue';
import BaseModalActions from '~/components/BaseModalActions.vue';
import Field from '~/components/Field.vue';
import ModalForm from '~/components/ModalForm.vue';

import {
  type IOptionItem,
  type PromiseResolvePayload,
  type Schema,
} from '~/types';

const { organisationId, closeModal } = defineProps<{
  organisationId: OrganisationId;
  closeModal: (data: PromiseResolvePayload<'CLOSE'>) => void;
}>();

const profileStore = useProfileStore();
const snackbar = useSnackbarStore();

const { fire } = useEvents();

const roles = ref<IOptionItem[]>([]);
const errors = ref(null);
const isLoading = ref(false);
const rolesIsLoading = ref(false);

type Form = {
  email: string;
  role: IOptionItem | null;
};

const form = reactive<Form>({
  email: '',
  role: null,
});

const schema = computed<Schema<Form, IOptionItem>>(() => ({
  email: {
    name: 'email',
    label: 'Email',
    component: 'FieldText',
    rules: `required|email|max:128|invalid_invite:${profileStore.isG2OEmployee}`,
    trim: true,
  },
  role: {
    name: 'role',
    label: 'Role',
    component: 'FieldSingleSelect',
    disabled: rolesIsLoading.value,
    options: roles.value,
  },
}));

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};

const fetchRoles = () => {
  Service.organisation(organisationId)
    .users()
    .createV2()
    .onStart(() => {
      rolesIsLoading.value = true;
    })
    .onSuccess(({ data }) => {
      roles.value = data;
    })
    .onFinish(() => {
      rolesIsLoading.value = false;
    })
    .execute();
};

const setValidationErrors = (data) => {
  errors.value = data.response.data?.errors;
};

const onSubmit = () => {
  Service.organisation(organisationId)
    .users()
    .storeV2(form)
    .onStart(() => {
      isLoading.value = true;
    })
    .onSuccess(() => {
      fire(EVENTS.ORGANISATION_USER_ADDED);

      snackbar.add({
        type: 'success',
        html: `<p>The user <span class="font-bold">${form.email}</span> invited.</p>`,
      });

      onClose();
    })
    .onError(() => {
      snackbar.add({
        type: 'error',
        text: 'Oops, something went wrong.',
      });
    })
    .onErrorValidation(setValidationErrors)
    .onFinish(() => {
      isLoading.value = false;
    })
    .execute();
};

watch(
  () => roles.value,
  (value) => {
    form.role = value[0];
  },
);

onMounted(() => {
  fetchRoles();
});
</script>

<template>
  <BaseModal
    max-width="362px"
    title="Invite user"
    content="compact"
    @close="onClose"
  >
    <p class="px-6 pb-2 text-body-1">
      Invite user by entering email and role. Login details will be sent to the
      user’s email.
    </p>
    <ModalForm @submit="onSubmit">
      <div class="space-y-4 overflow-y-auto px-6">
        <Field v-model="form.email" :field="schema.email" />
        <Field v-model="form.role" :field="schema.role" />
      </div>

      <BaseModalActions cancel="Cancel" submit="Invite" @cancel="onClose" />
    </ModalForm>
  </BaseModal>
</template>
