import { ICONS } from '~/constants';

import type {
  INotificationItem,
  INotificationType,
  IShipmentContent,
  IVesselPortContent,
  IVesselPortContentAfterChanged,
} from '~/types';

const BUTTON_TEXT = {
  SHIPMENT: 'see shipment',
  VESSEL: 'see details',
};

const getPortLabel = (portType: 'L' | 'D') =>
  portType === 'L' ? 'load' : 'discharge';

const bold = (...text: unknown[]) => {
  if (!Array.isArray(text)) {
    return `<strong>${text}</strong>`;
  }

  return `<strong>${text.join(' ')}</strong>`;
};

const getVesselPortMessage = (
  vessel: string,
  port: string,
  type: 'L' | 'D',
  message: string,
  date: string | number,
) =>
  `${bold(vessel)} ${message} ${getPortLabel(type)} port ${bold(
    port,
  )} ${date}. AGW WP WG`;

const getVesselPortItem =
  (message: string) =>
  ({
    vesselName,
    portName,
    actionUrl,
    portType,
    date,
    daysLeft,
  }: IVesselPortContent) => ({
    message: getVesselPortMessage(
      vesselName,
      portName,
      portType,
      message,
      daysLeft
        ? `in ${bold(`${daysLeft} ${daysLeft === 1 ? 'day' : 'days'}`)}`
        : `on ${bold(date)}`,
    ),
    icon: ICONS['mdi:ferry'],
    path: actionUrl,
    buttonText: BUTTON_TEXT.VESSEL,
  });

const getVesselPortItemAfterChanged =
  (message: string) =>
  ({
    vesselName,
    actionUrl,
    beforeDate,
    afterDate,
  }: IVesselPortContentAfterChanged) => ({
    message: `${message} of ${bold(
      vesselName,
    )} has changed from ${beforeDate} to ${bold(afterDate)}. AGW WP WG`,
    icon: ICONS['mdi:ferry'],
    path: actionUrl,
    buttonText: BUTTON_TEXT.VESSEL,
  });

export const useNotification = (
  key: INotificationType,
  content: unknown,
): INotificationItem => {
  const TYPE_TO_NOTIFICATION: Record<
    INotificationType,
    (content: any) => INotificationItem
  > = {
    'vessel.port.arrived': getVesselPortItem('scheduled to arrive at'),
    'vessel.port.arrival': getVesselPortItem('is scheduled to arrive at'),
    'vessel.port.arriving': getVesselPortItem('is scheduled to arrive at'),
    'vessel.port.arrival_changed': getVesselPortItemAfterChanged('ETA'),
    'vessel.port.berthed_changed': getVesselPortItemAfterChanged('ETB'),
    'vessel.port.departure_changed': getVesselPortItemAfterChanged('ETD'),

    'shipment.coa': ({
      vessel_name,
      port_name,
      action_url,
    }: IShipmentContent) => ({
      message: `Your shipment aboard ${vessel_name} has a new ETA at ${port_name}`,
      icon: ICONS['mdi:ferry'],
      path: action_url,
      buttonText: BUTTON_TEXT.SHIPMENT,
    }),
    'shipment.cod': ({
      vessel_name,
      port_name,
      action_url,
    }: IShipmentContent) => ({
      message: `Your shipment aboard ${vessel_name} has a new ETD from ${port_name}`,
      icon: ICONS['mdi:ferry'],
      path: action_url,
      buttonText: BUTTON_TEXT.SHIPMENT,
    }),
  };

  return TYPE_TO_NOTIFICATION[key](content);
};
