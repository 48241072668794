<script setup lang="ts" name="PublicSailingScheduleSelectMultiple">
import type { IStatisticsTradeLane } from '~/types';

import RegistrationCheckboxInput from './RegistrationCheckboxInput.vue';

defineProps<{
  options: IStatisticsTradeLane[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', options: IStatisticsTradeLane): void;
  (e: 'selectTrade', option: IStatisticsTradeLane): void;
}>();
</script>

<template>
  <RegistrationCheckboxInput
    v-for="option in options"
    :key="option.id"
    :native-value="option"
    @update:modelValue="emit('selectTrade', option)"
  >
    <div>
      <p
        data-test="tradelane-name"
        class="text-secondary-9 group-hover:text-primary-8"
      >
        {{ option.name }}
      </p>
      <p class="truncate text-charcoal-6 group-hover:text-primary-8">
        {{ option.continents }}
      </p>
    </div>
  </RegistrationCheckboxInput>
</template>
