<script setup lang="ts" name="NavBarOrganisationSelectOption">
const { text, isActive = false } = defineProps<{
  text: string;
  isActive?: boolean;
}>();

const emit = defineEmits<{
  (e: 'select'): void;
}>();
</script>

<template>
  <button
    type="button"
    class="w-full max-w-full truncate px-3 py-4 text-left"
    :class="{
      'text-black hover:bg-charcoal-1 hover:text-primary-8': !isActive,
      'bg-primary-8/10 text-primary-8 hover:bg-primary-8/20': isActive,
    }"
    @click.stop="emit('select')"
  >
    {{ text }}
  </button>
</template>
