<script setup lang="ts" name="TextToggle" generic="T extends string">
import { useVModel } from '@vueuse/core';

const props = defineProps<{
  modelValue: string;
  options: T[] | readonly T[];
  disabled?: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: T];
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <div
    data-testid="toggle"
    class="flex h-06 items-center rounded border border-ocean-6"
    :class="{ 'opacity-60': disabled }"
  >
    <label
      v-for="option in options"
      class="relative flex h-06 items-center justify-center rounded bg-charcoal-0 px-2 text-button-2 transition duration-300"
      :key="option"
      :class="{
        'bg-primary-6 text-white shadow-sm': inputValue === option,
        'bg-transparent text-primary-8': inputValue !== option,
        'cursor-not-allowed': disabled,
        'cursor-pointer': !disabled,
      }"
    >
      <input
        v-model="inputValue"
        type="radio"
        class="sr-only"
        :value="option"
        :disabled="disabled"
      />

      <span class="uppercase">{{ option }}</span>
    </label>
  </div>
</template>
