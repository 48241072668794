import type { RouteRecordRaw } from 'vue-router';

import { useProfileStore } from '~/store';

import { getHomeRouteBasedOnUser } from '~/features/useRouteHelpers';
import { makeFallbackRoute } from '~/features/useRouteMeta';

import ErrorInvitationExpired from '~/pages/ErrorInvitationExpired.vue';
import ErrorNotAuthorized from '~/pages/ErrorNotAuthorized.vue';
import ErrorNotFound from '~/pages/ErrorNotFound.vue';
import ErrorServer from '~/pages/ErrorServer.vue';

export const fallbackRoutes: Readonly<RouteRecordRaw[]> = [
  {
    name: 'Default',
    path: '/',
    redirect: () => getHomeRouteBasedOnUser(),
  },
  {
    name: 'Expired',
    path: '/expired',
    component: ErrorInvitationExpired,
  },
  makeFallbackRoute({
    name: '403',
    path: '/403',
    component: ErrorNotAuthorized,
  }),
  makeFallbackRoute({
    name: '404',
    path: '/404',
    component: ErrorNotFound,
  }),
  {
    name: '500',
    path: '/500',
    component: ErrorServer,
  },
  // This is a catch all route and always has to defined last.
  {
    name: 'Nothing',
    path: '/:pathMatch(.*)*',
    redirect: (to) => {
      if (useProfileStore().isAuthenticated) {
        return '/404';
      }

      return {
        name: 'AuthLogin',
        query: { redirect: to.fullPath },
      };
    },
  },
];
