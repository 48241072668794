<script setup lang="ts" name="SailingsScheduleViewButton">
import { computed } from 'vue';

import BaseButton from '~/components/BaseButton.vue';

const { fullVoyageView } = defineProps<{
  fullVoyageView: boolean;
}>();

const emit = defineEmits<{ (e: 'toggleFullScheduleView'): void }>();

const detailsButtonText = computed(() =>
  fullVoyageView ? ' close full schedule' : 'see full schedule',
);
</script>

<template>
  <div class="w-full border-r border-charcoal-2">
    <BaseButton variant="btn-tertiary" @click="emit('toggleFullScheduleView')">
      {{ detailsButtonText }}
    </BaseButton>
  </div>
</template>
