<script setup lang="ts" name="BaseModalActions">
import BaseButton from '~/components/BaseButton.vue';

defineProps<{
  loading?: boolean;
  cancel?: string;
  submit?: string;
  disabled?: boolean;
  tooltipDescription?: string;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'submit'): void;
}>();
</script>

<template>
  <div class="flex w-full items-center justify-end space-x-4 p-6">
    <slot>
      <BaseButton
        v-if="cancel"
        variant="btn-secondary"
        :skeletonLoading="loading"
        @click="emit('cancel')"
      >
        {{ cancel }}
      </BaseButton>
      <BaseButton
        v-if="submit"
        type="submit"
        :skeletonLoading="loading"
        :disabled="disabled"
        :tooltipDescription="tooltipDescription"
        @click="emit('submit')"
      >
        {{ submit }}
      </BaseButton>
    </slot>
  </div>
</template>
