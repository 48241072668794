<script setup lang="ts" name="OrganisationHomeWidgetsSettingsModal">
import { computed, reactive, ref } from 'vue';

import { HOME_CUSTOMIZATION_WIDGET_TYPE_TO_LABEL_TEXT } from '~/constants';

import { useService } from '~/features/useService.js';

import Service from '~/services/Service';

import OrganisationHomeWidgetsSettingsCheckboxRow from '~/components/OrganisationHomeWidgetsSettingsCheckboxRow.vue';
import OrganisationHomeWidgetsSettingsExpandedRadioList from '~/components/OrganisationHomeWidgetsSettingsExpandedRadioList.vue';
import StateModal from '~/components/StateModal.vue';

import type {
  ICustomizationWidget,
  ICustomizationWidgetTransitTime,
  PromiseResolvePayload,
} from '~/types';

const { closeModal, organisationId } = defineProps<{
  organisationId: OrganisationId;
  closeModal: (
    data:
      | PromiseResolvePayload<'SAVE', number>
      | PromiseResolvePayload<'CLOSE'>,
  ) => void;
}>();

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};

const NONE = 'None';
const originalTradeLane = ref('');
const originalWidgets = ref({} as ICustomizationWidget);

const form = reactive({
  widgets: {} as ICustomizationWidget,
  tradeLane: '',
});

const hasTradeLanes = computed(
  () => Object.keys(form.widgets.transit_times).length !== 0,
);

const widgetsCount = computed(() =>
  Object.values(form.widgets).reduce((acc, curr) => {
    if (typeof curr === 'object') {
      return acc + Object.values(curr).filter(Boolean).length;
    }

    return acc + Number(curr);
  }, 0),
);

const serviceState = useService(
  Service.organisation(organisationId).home().customize().show(),
  ({ data }: { data: ICustomizationWidget }) => {
    originalWidgets.value = data;
    form.widgets = JSON.parse(JSON.stringify(data));

    getDefaultTradeLane(data.transit_times);
    return true;
  },
);

const getDefaultTradeLane = (
  transitTimes: ICustomizationWidgetTransitTime | boolean = {},
) => {
  if (transitTimes === false || !Object.keys(transitTimes).length) {
    originalTradeLane.value = NONE;
    form.tradeLane = NONE;
    return;
  }

  const defaultTradeLane =
    Object.keys(transitTimes).find((key) => transitTimes[key]) || NONE;

  originalTradeLane.value = defaultTradeLane;
  form.tradeLane = defaultTradeLane;
};

const onSubmit = () => {
  if (
    JSON.stringify(form.widgets) === JSON.stringify(originalWidgets.value) &&
    form.tradeLane === originalTradeLane.value
  ) {
    onClose();
    return;
  }

  updateOriginalData();
  postNewSettings();
};

const updateOriginalData = () => {
  originalWidgets.value = JSON.parse(JSON.stringify(form.widgets));

  originalTradeLane.value = form.tradeLane;
};

const postNewSettings = () => {
  let transitTimes: boolean | string;

  if (form.widgets.transit_times === true) {
    transitTimes = true;
  } else {
    transitTimes = form.tradeLane === NONE ? false : form.tradeLane;
  }

  Service.organisation(organisationId)
    .home()
    .customize()
    .update()
    .data({ ...form.widgets, transit_times: transitTimes })
    .onFinish(() => {
      closeModal({ action: 'SAVE', payload: widgetsCount.value });
    })
    .execute();
};
</script>

<template>
  <StateModal
    max-width="470px"
    title="Customise Statistics"
    :loading="serviceState.stateIsLoading"
    :on-close="onClose"
    @submit="onSubmit"
  >
    <div v-for="(widget, key) in form.widgets" :key="key">
      <OrganisationHomeWidgetsSettingsCheckboxRow
        v-if="key !== 'transit_times' || !hasTradeLanes"
        v-model="form.widgets[key]"
        :label="HOME_CUSTOMIZATION_WIDGET_TYPE_TO_LABEL_TEXT[key]"
      />

      <OrganisationHomeWidgetsSettingsExpandedRadioList
        v-else
        :label="HOME_CUSTOMIZATION_WIDGET_TYPE_TO_LABEL_TEXT[key]"
        v-model="form.tradeLane"
        :tradeLanes="widget as ICustomizationWidgetTransitTime"
      />
    </div>
  </StateModal>
</template>
