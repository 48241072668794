<script setup lang="ts" name="OrganisationShipmentsVoyageRowTotals">
import { computed } from 'vue';

import { formatNumber } from '~/features/useNumbers';

const props = defineProps<{
  totals: { unit: string; quantity: number }[];
  isOpen?: boolean;
  homeView?: boolean;
}>();

const isLastElement = computed(
  () => (index: number) => props.totals.length === index + 1,
);
</script>

<template>
  <div
    class="col-span-2 self-end"
    :class="homeView ? 'xl:col-span-1 xl:px-2' : 'lg:col-span-1 lg:px-2'"
  >
    <p class="text-sm text-charcoal-6">Total Qty</p>
    <div
      v-for="(total, index) in totals"
      :key="total.quantity"
      class="mr-1 inline-flex"
    >
      <div class="flex group-hover:text-primary-8">
        {{ formatNumber(total.quantity) }}

        <span
          class="ml-1 group-hover:text-primary-8"
          :class="{ 'text-charcoal-6': !isOpen }"
        >
          {{ total.unit }}{{ isLastElement(index) ? '' : ',  ' }}
        </span>
      </div>
    </div>
  </div>
</template>
