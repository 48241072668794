<script setup lang="ts" name="SailingScheduleTradeLaneSettingsModal">
import { computed, reactive, ref, watch } from 'vue';

import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import BaseCheckboxToggle from '~/components/BaseCheckboxToggle.vue';
import SailingScheduleTradeLaneDraggable from '~/components/SailingScheduleTradeLaneDraggable.vue';
import SearchField from '~/components/SearchField.vue';
import StateModal from '~/components/StateModal.vue';

import type {
  ITradeLaneVessel,
  IVesselOption,
  PromiseResolvePayload,
} from '~/types';

const { closeModal, vessels = [] } = defineProps<{
  closeModal: (
    data:
      | PromiseResolvePayload<'SAVE', ITradeLaneVessel[]>
      | PromiseResolvePayload<'CLOSE'>,
  ) => void;
  vessels: ITradeLaneVessel[];
}>();

const search = ref('');
const originalVessels = ref<IVesselOption[]>([]);
const form = reactive<{
  vessels: IVesselOption[];
}>({
  vessels: [],
});

const visibleVoyages = computed<IVesselOption[]>(() =>
  form.vessels.filter((x) => x.visible),
);

const filteredVessels = computed<IVesselOption[]>({
  get: () =>
    form.vessels.filter((vessel) =>
      vessel.label.toLowerCase().includes(search.value.toLowerCase()),
    ),
  set: (value) => {
    form.vessels = value;
  },
});

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};

watch(
  () => vessels,
  (allVessels) => {
    const vesselOptions: IVesselOption[] = allVessels.map((vessel) => ({
      value: vessel.id,
      label: `${vessel.vessel} ${vessel.vvn || ''}`,
      visible: typeof vessel.visible === 'boolean' ? vessel.visible : true,
    }));

    originalVessels.value = vesselOptions.map((vessel) =>
      Object.assign({}, vessel),
    );
    form.vessels = vesselOptions;
  },
  {
    deep: true,
    immediate: true,
  },
);

const onSubmit = () => {
  const updatedVessels = form.vessels
    .map((vessel) => {
      const originalVessel = vessels.find((x) => x.id === vessel.value);
      if (!originalVessel) return null;
      return {
        ...originalVessel,
        visible: vessel.visible,
      };
    })
    .filter(Boolean) as ITradeLaneVessel[];

  closeModal({ action: 'SAVE', payload: updatedVessels });
};

const onClear = () => {
  search.value = '';
};

const toVisible = (visible: boolean) => (vessel: IVesselOption) => ({
  ...vessel,
  visible,
});

const onToggleAll = () => {
  if (visibleVoyages.value.length === form.vessels.length) {
    form.vessels = form.vessels.map(toVisible(false));
    return;
  }

  form.vessels = form.vessels.map(toVisible(true));
};
</script>

<template>
  <StateModal
    title="Customise Voyages"
    data-testid="settings-modal"
    maxWidth="448px"
    @close="onClose"
    @submit="onSubmit"
  >
    <SearchField v-model="search" placeholder="Search by Vessel Name" />
    <div class="mx-08 mt-3">
      <BaseCheckboxToggle
        v-if="!search"
        :items="form.vessels"
        :selected-items="visibleVoyages"
        @toggle="onToggleAll"
      />
    </div>

    <div v-if="filteredVessels.length === 0" class="px-6 text-center">
      <BaseCardNoResults
        message="Please clear the search field to see all available vessels."
        button="Clear all"
        @clear="onClear"
      />
    </div>
    <SailingScheduleTradeLaneDraggable
      v-else-if="filteredVessels.length !== 0"
      v-model="filteredVessels"
      :search="search"
      :disabled="!!search"
    />
    <SailingScheduleTradeLaneDraggable
      v-else
      v-model="form.vessels"
      :search="search"
      :disabled="!!search"
    />
  </StateModal>
</template>
