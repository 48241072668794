import { type InjectionKey, provide, type Ref, ref } from 'vue';

import { useStrictInject } from './useStrictInject';

interface ISidebarState {
  preference: Ref<boolean>;
  closed: Ref<boolean>;
  transitioned: Ref<boolean>;

  toggleSidebarState: () => void;
  setSidebarVisible: (visible: boolean) => void;
  setTransitioned: (visible: boolean) => void;
  closeSidebar: () => void;
  openSidebar: () => void;
}

const provideSidebarState = (state: ISidebarState) =>
  provide(SidebarStateInject, state);

export const SidebarStateInject: InjectionKey<ISidebarState> =
  Symbol('SidebarStateInject');

export const injectSidebarState = () => useStrictInject(SidebarStateInject);

export const useSidebarState = () => {
  const preference = ref(true);
  const closed = ref(false);
  const transitioned = ref(false);

  const toggleSidebarState = () => {
    if (!preference.value && !closed.value) {
      setSidebarVisible(false);
      return;
    }

    preference.value = !preference.value;
    closed.value = !closed.value;
  };

  const setSidebarVisible = (visible: boolean) => {
    if (!preference.value) {
      closed.value = !visible;
    }
  };

  const closeSidebar = () => {
    closed.value = true;
  };

  const openSidebar = () => {
    closed.value = false;
  };

  const setTransitioned = (state: boolean) => {
    transitioned.value = state;
  };

  return {
    closed,
    preference,
    transitioned,

    toggleSidebarState,
    setSidebarVisible,
    setTransitioned,
    closeSidebar,
    openSidebar,
  };
};

export const useSidebarStateProvider = () =>
  provideSidebarState(useSidebarState());
