import type { RouteRecordRaw } from 'vue-router';

import { ORG_FEATURES } from '~/constants';

import { makeOrganisationRoute } from '~/features/useRouteMeta';

import OrganisationVesselsIndex from '~/pages/OrganisationVesselsIndex.vue';
import OrganisationVesselsShow from '~/pages/OrganisationVesselsShow.vue';

export const organisationVesselsRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    name: 'OrganisationVesselsIndex',
    path: '/vessels',
    component: OrganisationVesselsIndex,
    orgFeature: ORG_FEATURES.VESSELS,
  }),
  makeOrganisationRoute({
    name: 'OrganisationVesselsShow',
    path: '/vessels/:id/name/:name',
    component: OrganisationVesselsShow,
    orgFeature: ORG_FEATURES.VESSELS,
    props: (route) => ({
      id: route.params.id as string,
      name: route.params.name as string,
    }),
  }),
];
