<script lang="ts" setup name="SailingScheduleTradeLaneCollapsible">
import { computed, ref } from 'vue';

import { useService } from '~/features/useService.js';

import Service from '~/services/Service.js';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import SailingScheduleTradeLane from '~/components/SailingScheduleTradeLane.vue';
import SailingScheduleTradeLaneVoyages from '~/components/SailingScheduleTradeLaneVoyages.vue';

import type {
  BaseTagContrastType,
  ITradeLanePort,
  ITradeLaneVessel,
  SailingScheduleTradeLane as TradeLane,
  SailingScheduleV2Response,
} from '~/types';

const { organisationId, tradeLane } = defineProps<{
  organisationId: OrganisationId;
  tradeLane: TradeLane;
  tagVariant: BaseTagContrastType;
  color: string;
}>();

const dataLoaded = ref(false);
const originalVessels = ref<ITradeLaneVessel[]>([]);
const vessels = ref<ITradeLaneVessel[]>([]);
const ports = ref<ITradeLanePort[]>([]);

const settingsApplied = computed(
  () => JSON.stringify(vessels.value) !== JSON.stringify(originalVessels.value),
);

const serviceState = useService(
  Service.organisation(organisationId)
    .sailingScheduleV2()
    .voyages(tradeLane.tradelane)
    .index(),
  ({ data }: SailingScheduleV2Response) => {
    const vesselsWithVisible = data.vessels.map((x) => ({
      ...x,
      visible: true,
    }));

    originalVessels.value = [...vesselsWithVisible];
    vessels.value = vesselsWithVisible;
    ports.value = data.ports;
    return true;
  },
  { preventFetch: true, state: 'IDLE' },
);

const onUpdated = (updatedVessels: ITradeLaneVessel[]) => {
  vessels.value = updatedVessels;
};

const onReset = () => {
  vessels.value = [...originalVessels.value];
};

const showVoyages = () => {
  if (dataLoaded.value) return;
  dataLoaded.value = true;
  serviceState.fetchData();
};
</script>

<template>
  <div class="w-full rounded drop-shadow">
    <BaseCollapsible @open="showVoyages">
      <template #trigger="{ toggle, isOpen }">
        <SailingScheduleTradeLane
          v-bind="tradeLane"
          :organisation-id="organisationId"
          :settings-applied="settingsApplied"
          :vessels="vessels"
          :ports="ports"
          :is-open="isOpen"
          :tagVariant="tagVariant"
          :color="color"
          :class="{
            'rounded hover:drop-shadow-2': !isOpen,
            'rounded-b-none rounded-t': isOpen,
          }"
          :idle="serviceState.stateIsIdle"
          @click="toggle"
          @updated="onUpdated"
          @reset="onReset"
        />
      </template>
      <SailingScheduleTradeLaneVoyages
        :service-state="serviceState"
        :vessels="vessels"
        :ports="ports"
      />
    </BaseCollapsible>
  </div>
</template>
