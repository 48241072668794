<script setup lang="ts" name="SailingScheduleCard">
import Icon from '~/components/Icon.vue';

const { isOpen } = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{ (e: 'click'): void }>();
</script>

<template>
  <div
    class="group block w-full cursor-pointer bg-white text-left lg:p-4"
    :class="{
      'rounded-b-none rounded-t text-primary-8': isOpen,
      'rounded hover:text-primary-8 hover:drop-shadow-2': !isOpen,
    }"
    @click="emit('click')"
  >
    <div class="flex items-center gap-2 px-4 pt-4 lg:p-0">
      <Icon
        icon="mdi:chevron-right"
        class="hidden text-primary-6 sm:block"
        :class="{
          '-rotate-90': isOpen,
          'rotate-90': !isOpen,
        }"
      />

      <slot name="heading" />

      <slot name="actions" />
    </div>
    <slot />
  </div>
</template>
