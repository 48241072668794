<script setup lang="ts" name="FieldForm">
import { computed, ref, watch } from 'vue';
import { useForm } from 'vee-validate';

import BaseButton from '~/components/BaseButton.vue';

import type { IFieldFormSubmit } from '~/types';

const props = defineProps({
  dataTest: {
    type: String,
    default: 'x',
  },
  confirm: {
    type: String,
    default: 'Submit',
  },
  cancel: {
    type: Object,
    default: null,
  },
  errors: {
    type: Object,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  full: {
    type: Boolean,
    default: false,
  },
  buttonFull: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  initialValues: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits<{
  (e: 'submit', payload: IFieldFormSubmit): void;
  (e: 'errors', values: string[]): void;
  (e: 'change', values: any): void;
}>();

const { handleSubmit, setErrors, values } = useForm({
  initialValues: props.initialValues,
});

const formErrors = ref({});
const formDataTest = computed(() => `${props.dataTest}-form`);
const cancelDataTest = computed(() => `${props.dataTest}-cancel`);
const confirmDataTest = computed(() => `${props.dataTest}-submit`);

function onInvalidSubmit({ errors }: any) {
  formErrors.value = errors;
}

const onSubmit = handleSubmit((values, { resetForm }) => {
  if (props.loading) return;

  emit('submit', {
    values,
    resetForm,
  });
}, onInvalidSubmit);

watch(
  () => props.errors,
  (errors) => {
    formErrors.value = errors;
    setErrors(errors);
  },
);

watch(
  () => formErrors.value,
  (error) => {
    emit('errors', formErrors.value);
  },
);

watch(
  () => values,
  (newValue) => {
    emit('change', newValue);
  },
  { deep: true },
);
</script>

<template>
  <form
    class="bg-white"
    :class="{
      'max-w-form': !full,
    }"
    :data-test="formDataTest"
    @submit.prevent="onSubmit"
  >
    <div class="relative space-y-4">
      <slot :errors="formErrors" />
      <div v-if="loading" class="absolute inset-0 bg-white bg-opacity-25" />
    </div>

    <slot name="footer">
      <div class="mt-8 flex w-full items-center justify-end space-x-4">
        <slot name="cancel">
          <BaseButton
            v-if="cancel"
            variant="btn-tertiary"
            :to="cancel.to"
            :data-test="cancelDataTest"
          >
            {{ cancel.label }}
          </BaseButton>
        </slot>
        <slot name="confirm">
          <BaseButton
            type="submit"
            :loading="loading"
            :disabled="disabled || loading"
            :data-test="confirmDataTest"
            :class="{
              'w-full': !full || buttonFull,
              'min-w-36': full,
            }"
          >
            {{ confirm }}
          </BaseButton>
        </slot>
      </div>
    </slot>
  </form>
</template>
