<script setup lang="ts" name="SidebarToggle">
import { injectSidebarState } from '~/features/useSidebarState';

import Icon from '~/components/Icon.vue';

const sidebarState = injectSidebarState();
</script>

<template>
  <button
    type="button"
    class="flex items-center justify-end gap-x-2 border-t border-ocean-4 px-6 pb-6 pt-[18px]"
    @click="sidebarState.toggleSidebarState"
  >
    <p
      class="text-opacity-transition text-subtitle-2 !text-white"
      :class="sidebarState.closed.value ? 'opacity-0' : 'opacity-100'"
    >
      Collapsed View
    </p>
    <Icon icon="material-symbols:menu-open-rounded" />
  </button>
</template>
