import type { RouteRecordRaw } from 'vue-router';

import { ORG_FEATURES, PERMISSION } from '~/constants';

import { makeOrganisationRoute } from '~/features/useRouteMeta';
import { getSailingScheduleFilters } from '~/features/useSailingScheduleFilters';

import SailingScheduleIndex from '~/pages/SailingScheduleIndex.vue';

export const sailingScheduleRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    name: 'SailingScheduleIndex',
    path: '/sailing-schedule',
    component: SailingScheduleIndex,
    orgFeature: ORG_FEATURES.SAILING_SCHEDULE,
    permission: PERMISSION.SAILING_SCHEDULE_VIEW_OWN,
    beforeEnter: async (to) => {
      return await getSailingScheduleFilters(
        to.params.organisationId as string,
      );
    },
  }),
];
