<script setup lang="ts" name="SidebarLayout">
import { onMounted, onUnmounted, ref } from 'vue';

import { injectSidebarState } from '~/features/useSidebarState';

const { open } = defineProps<{
  open?: boolean;
}>();

const sidebarRef = ref();

const sidebarState = injectSidebarState();

const handleTransitionEnd = () => {
  if (sidebarState.closed.value) {
    sidebarState.setTransitioned(true);
  } else {
    sidebarState.setTransitioned(false);
  }
};

onMounted(() => {
  sidebarRef.value?.addEventListener('transitionend', handleTransitionEnd);
});

onUnmounted(() => {
  sidebarRef.value?.removeEventListener('transitionend', handleTransitionEnd);
});
</script>

<template>
  <div
    ref="sidebarRef"
    class="flex h-screen flex-col overflow-y-auto overflow-x-hidden bg-primary-8 p-1 text-white transition-all duration-1000 ease-in-out"
    :class="{
      'w-sidebar': !sidebarState.closed.value,
      'w-sidebar-closed': sidebarState.closed.value,
      'absolute left-0 top-0 z-20': open,
    }"
  >
    <slot />
  </div>
</template>
