<script lang="ts" setup name="BaseCardLink">
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { RouterLink } from 'vue-router';

import BaseLink from '~/components/BaseLink.vue';

import type { IconType } from '~/types';

const {
  to,
  href,
  disabled,
  loading,
  icon = 'mdi:chevron-right',
} = defineProps<{
  to?: RouteLocationRaw;
  href?: string;
  target?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: IconType | null;
}>();

const tag = computed(() => {
  if (disabled) {
    return 'button';
  }

  if (href) {
    return 'a';
  }

  if (to) {
    return RouterLink;
  }

  return 'button';
});

const isDisabled = computed(() => disabled || loading);
</script>

<template>
  <component
    :is="tag"
    type="button"
    class="w-full"
    :to="to"
    :href="href"
    :target="target"
    :disabled="isDisabled"
  >
    <BaseLink :disabled="isDisabled" :loading="loading" :icon="icon">
      <slot />
    </BaseLink>
  </component>
</template>
