import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import { hasQuery, isSamePath } from '~/features/useRouteHelpers';

import { adminRoutes } from './adminRoutes';
import { authRoutes } from './authRoutes';
import { fallbackRoutes } from './fallbackRoutes';
import { notificationsRoutes } from './notificationsRoutes';
// Org dependant
import { organisationBookingsRoutes } from './organisationBookingsRoutes';
import { organisationKpisRoutes } from './organisationKpisRoutes';
import { organisationRoutes } from './organisationRoutes';
import { organisationSettingsRoutes } from './organisationSettingsRoutes';
import { organisationShipmentsRoutes } from './organisationShipmentsRoutes';
import { organisationStatisticsRoutes } from './organisationStatisticsRoutes';
import { organisationVesselsRoutes } from './organisationVesselsRoutes';
// Other
import { publicRoutes } from './publicRoutes';
import { publicUserRoutes } from './publicUserRoutes';
import { sailingScheduleRoutes } from './sailingScheduleRoutes';
import { supportRoutes } from './supportRoutes';

const routes: Readonly<RouteRecordRaw[]> = [
  // Independent routes
  ...publicUserRoutes,
  ...publicRoutes,
  ...authRoutes,
  ...supportRoutes,
  ...sailingScheduleRoutes,
  ...notificationsRoutes,
  // System Admin
  ...adminRoutes,
  // Org dependant
  ...organisationRoutes,
  ...organisationStatisticsRoutes,
  ...organisationBookingsRoutes,
  ...organisationShipmentsRoutes,
  ...organisationVesselsRoutes,
  ...organisationKpisRoutes,
  ...organisationSettingsRoutes,
  // Fallback routes need to be last
  ...fallbackRoutes,
];

export const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior(_to, _from, savedPosition) {
    if (_from && hasQuery(_to) && isSamePath(_to, _from)) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0, left: 0 };
  },
});
