<script setup lang="ts" name="OrganisationHomeWidgetsSettingsCheckboxRow">
import { useVModel } from '@vueuse/core';

import BaseCheckbox from '~/components/BaseCheckbox.vue';

import type { ICustomizationWidgetTransitTime } from '~/types';

const props = defineProps<{
  modelValue: boolean | ICustomizationWidgetTransitTime;
  label: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): () => void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <BaseCheckbox class="px-7 py-3 hover:bg-charcoal-1" v-model="inputValue">
    {{ label }}
  </BaseCheckbox>
</template>
