export function useSortA1ByA2<T>(
  toBeSorted: T[],
  sortBy: (string | number)[],
  key?: keyof T,
): T[] {
  const answer = new Array(toBeSorted.length);
  const hashMap = new Map();
  let currentIndex = 0;

  toBeSorted.forEach((element) => {
    const keyToUse = key ? element[key] : element;
    hashMap.set(keyToUse, element);
  });

  sortBy.forEach((element) => {
    if (!hashMap.has(element)) return;

    answer[currentIndex] = hashMap.get(element);
    currentIndex++;

    hashMap.delete(element);
  });

  hashMap.forEach((value) => {
    answer[currentIndex] = value;
    currentIndex++;
  });

  return answer;
}
