import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import {
  useAuthStore,
  useCargoBookingCreateStore,
  useMaintenanceStore,
  useOrganisationsStore,
  useProfileStore,
  useUnreadNotificationsStore,
} from '~/store';

import Service from '~/services/Service.js';

import type {
  CargoBookingTransferError,
  IMaintenanceNotification,
  IOrgFeature,
} from '~/types';

export type OrgUpdatedSettings = {
  organisationId: OrganisationId;
  features?: IOrgFeature[];
  kpisCount?: number;
  canNominate?: boolean;
  name?: string;
};

type EchoChannel = {
  name: string;
};

export interface EventListener<T = any> {
  channel: string;
  event: string;
  callback: (data: T) => void;
}

export interface WhisperListener<T = any> {
  channel: string;
  event: string;
  data: T;
}

let isEchoConnected = false;
let deferredListeners: EventListener[] = [];

const WEBSOCKET_ENABLED = import.meta.env.VITE_WS_APP_KEY;

const ECHO_CONFIG = {
  broadcaster: 'pusher',
  key: import.meta.env.VITE_WS_APP_KEY,
  wsHost: import.meta.env.VITE_WS_HOST,
  wsPort: import.meta.env.VITE_WS_PORT,
  wssPort: import.meta.env.VITE_WS_PORT,
  encrypted: true,
  forceTLS: false,
  enabledTransports: ['ws', 'wss'],
  clientMessages: true,
};

export const CHANNELS = {
  USER: (id: number) => `user.${id}`,
  MAINTENANCE: () => 'maintenance',
  NOMINATION_INTERNAL: (organisation_id: string) =>
    `organisation.${organisation_id}.nomination.list.internal`,
  NOMINATION_EXTERNAL: (organisation_id: string) =>
    `organisation.${organisation_id}.nomination.list.external`,
  NOMINATION_MESSAGES_INTERNAL: (
    organisation_id: string,
    nomination_id: string | null,
  ) => `organisation.${organisation_id}.nomination.${nomination_id}.internal`,
  NOMINATION_MESSAGES_EXTERNAL: (
    organisation_id: string,
    nomination_id: string | null,
  ) => `organisation.${organisation_id}.nomination.${nomination_id}.external`,
  NOMINATION_MESSAGES_ALL: (
    organisation_id: string,
    nomination_id: string | null,
  ) => `organisation.${organisation_id}.nomination.${nomination_id}.all`,
} as const;

const updateProfile = () => {
  useProfileStore().fetchProfile(true);
};

const updateOrgSettings = (data: OrgUpdatedSettings) => {
  useOrganisationsStore().updateOrganisationSettings(data);
};

const deleteOrganisation = (data: { organisationId: OrganisationId }) => {
  const store = useOrganisationsStore();
  const filtered = store.organisations.filter(
    (org) => org.id !== data.organisationId,
  );

  store.updateOrganisations(filtered, true);
};

const updateBookingTransferError = ({
  organisationId,
  data,
}: {
  organisationId: string;
  data: CargoBookingTransferError[];
}) => {
  if (useOrganisationsStore().organisation?.id === organisationId)
    useCargoBookingCreateStore().setBookingTransferError(data);
};

const setAuthorizationMeta = () => {
  if (window.Echo) {
    window.Echo.disconnect();
  }

  window.Echo = new Echo({
    ...ECHO_CONFIG,
    authorizer: (channel: EchoChannel) => ({
      authorize: (
        socketId: string,
        callback: (success: boolean, result: unknown) => void,
      ) => {
        Service.websocket()
          .connect(socketId, channel.name)
          .onSuccess((response: unknown) => {
            callback(false, response);
          })
          .onError((error: unknown) => {
            callback(true, error);
          })
          .execute();
      },
    }),
  });

  window.Echo.connector.pusher.connection.bind('connected', () => {
    isEchoConnected = true;

    if (!deferredListeners.length) {
      return;
    }

    deferredListeners.forEach(({ channel, event, callback }) => {
      subscribe(channel).listen(event, callback);
    });

    deferredListeners = [];
  });
};

const subscribe = (channel: string) => {
  return window.Echo.private(channel);
};

const getChannel = (channel: string) => {
  return window.Echo?.connector.channels?.[`private-${channel}`];
};

export const addListener = <T>({
  channel,
  event,
  callback,
}: EventListener<T>) => {
  if (isEchoConnected) {
    subscribe(channel).listen(event, callback);
  } else {
    deferredListeners.push({ channel, event, callback });
  }
};

export const stopListening = <T>({
  channel,
  event,
  callback,
}: EventListener<T>) => {
  if (!isEchoConnected) {
    return;
  }

  subscribe(channel).stopListening(event, callback);
};

export const leaveChannel = (channel: string) => {
  window.Echo?.leave(channel);
};

export const whisper = <T>({ channel, event, data }: WhisperListener<T>) => {
  getChannel(channel)?.whisper(event, data);
};

export const listenForWhisper = <T>({
  channel,
  event,
  callback,
}: EventListener<T>) => {
  getChannel(channel)?.listenForWhisper(event, callback);
};

export const useWebsocketChannels = () => {
  const setupUserChannel = () => {
    const profile = useProfileStore().profile;
    if (!profile) {
      return;
    }

    subscribe(CHANNELS.USER(profile.id))
      .listen('.updated', updateProfile)
      .listen('.deleted', () => {
        useAuthStore().logout();
      })
      .listen('.organisation.created', updateProfile)
      .listen('.organisation.updated', updateOrgSettings)
      .listen('.organisation.deleted', deleteOrganisation)
      .listen('.transfer.error', updateBookingTransferError)
      .notification(() => {
        useUnreadNotificationsStore().increment();
      });
  };

  const setupMaintenanceChannel = () => {
    subscribe(CHANNELS.MAINTENANCE()).listen(
      '.maintenance',
      (data: IMaintenanceNotification[]) => {
        useMaintenanceStore().setMessages(data);
      },
    );
  };

  const initializeWebsocket = () => {
    if (!WEBSOCKET_ENABLED) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Pusher = Pusher;

    window.Echo = new Echo(ECHO_CONFIG);
  };

  const leaveChannels = () => {
    window.Echo?.leaveAllChannels();
  };

  const disconnectChannels = () => {
    window.Echo?.disconnect();
  };

  const setupAuthChannels = () => {
    initializeWebsocket();
    leaveChannels();
    setAuthorizationMeta();

    setupUserChannel();
    setupMaintenanceChannel();
  };

  return {
    setupAuthChannels,
    disconnectChannels,
  };
};
