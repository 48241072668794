import { Icon } from 'leaflet';
import leafletIconUrl from 'leaflet/dist/images/marker-icon.png';
import leafletIconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import leafletShadowUrl from 'leaflet/dist/images/marker-shadow.png';

import type { UserModule } from '~/types';

// CSS
import 'leaflet/dist/leaflet.css';
import 'v-calendar/dist/style.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

// import type { UserModule } from '~/types';

// this part resolve an issue where the markers would not appear
Icon.Default.prototype._getIconUrl = undefined;

Icon.Default.mergeOptions({
  iconRetinaUrl: leafletIconRetinaUrl,
  iconUrl: leafletIconUrl,
  shadowUrl: leafletShadowUrl,
});

export const install: UserModule = () => {};
