<script setup name="CookiePolicy">
import { computed } from 'vue';

import { useProfileStore } from '~/store';

import BasePage from '~/components/BasePage.vue';
import CookiePolicy from '~/components/CookiePolicy.vue';

const page = computed(() => ({
  title: 'Cookie Policy for MyG2',
  backButton: {
    label: 'Support',
    to: {
      name: useProfileStore().isPublic ? 'PublicSupportIndex' : 'SupportIndex',
    },
  },
}));
</script>

<template>
  <BasePage v-bind="page">
    <div
      class="col-span-full w-auto rounded-md bg-white p-6 lg:w-[707px] lg:p-09"
    >
      <CookiePolicy>
        <template #heading>
          <p>This is the Cookie Policy for MyG2.</p>
        </template>
      </CookiePolicy>
    </div>
  </BasePage>
</template>
