import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

const path = `${baseEndpoint}/api/customer/organisation/1/cargo-bookings`;

export default [
  http.get(`${path}/cargo-declarations/download`, async () => {
    return HttpResponse.json({});
  }),
  http.get(`${path}/cargo-nominations/download`, async () => {
    return HttpResponse.json({});
  }),
] satisfies HttpHandler[];
