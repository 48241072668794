import type { UserModule } from '~/types';

export const install: UserModule = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      (async () => {
        try {
          if (import.meta.env?.DEV) {
            await navigator.serviceWorker.register('/service-worker.js', {
              type: 'module',
            });
          } else {
            await navigator.serviceWorker.register('/service-worker.js');
          }
        } catch (err) {
          console.log('Service worker registration failed: ', err);
        }
      })();
    });
  }
};
