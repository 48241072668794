<script setup lang="ts" name="PublicBillOfLadingSearch">
import { reactive, ref, watch } from 'vue';

import { usePortContacts } from '~/features/usePortContacts';
import { useService } from '~/features/useService';
import {
  type ShipmentMilestone,
  useShipmentMilestones,
} from '~/features/useShipmentMilestones';

import Service from '~/services/Service';

import BackButton from '~/components/BackButton.vue';
import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import CargoTrackerDetails from '~/components/CargoTrackerDetails.vue';
import G2oPublicLayout from '~/components/G2oPublicLayout.vue';
import PublicUserCargoTrackerNoData from '~/components/PublicUserCargoTrackerNoData.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';

import type {
  IBillOfLadingInfo,
  IBillOfLadingResponse,
  ISharedContactCard,
  IVesselVoyageData,
} from '~/types';

const query = reactive({
  search: '',
});

const bolInformation = ref<IBillOfLadingInfo>();
const portsAndDates = ref<ShipmentMilestone[]>([]);
const portContacts = ref<ISharedContactCard>();
const vesselVoyageData = ref<IVesselVoyageData>();

const serviceState = useService(
  Service.publicBol().index(),
  ({ data }: IBillOfLadingResponse) => {
    bolInformation.value = data.bolInformation;
    vesselVoyageData.value = data.tracking;

    portsAndDates.value = useShipmentMilestones(data.portsAndDates);

    const { portAgents } = data;

    portContacts.value = usePortContacts(
      portAgents,
      'Port Agents',
      'Port Agent',
      'port_agents',
    );

    return true;
  },
  {
    cancelOnNewRequest: true,
    query,
    state: 'NO_DATA',
    debounceTime: 1000,
    preventFetch: true,
    preventWatch: () => !query.search,
    getRouteQuery: () => ({
      search: query.search,
    }),
    getQuery: () => ({
      bol_name: query.search,
    }),
  },
);

watch(
  () => query.search,
  (val) => {
    if (!val) {
      serviceState.setStateNoData();
    }
  },
);

const onClearSearch = () => {
  query.search = '';
};
</script>

<template>
  <G2oPublicLayout variant="secondary" header-logo="g2o">
    <div class="my-14 flex w-full justify-center">
      <div class="w-full max-w-5xl space-y-6 md:w-08/12">
        <BackButton label="Back to G2Ocean" href="https://g2ocean.com" />

        <p class="!max-w-full text-center text-h3">Cargo Tracker</p>

        <SearchField
          class="shadow"
          placeholder="Search by Bill of Lading Number"
          v-model="query.search"
        />

        <ServiceCard
          v-bind="serviceState"
          styled
          @clear="onClearSearch"
          not-available-title="Shipment is No Longer Available"
          not-available-message="Shipment has been completed."
        >
          <template #no-data>
            <PublicUserCargoTrackerNoData :serviceState="serviceState" />
          </template>

          <template #validation-error>
            <BaseCardNoResults styled @clear="onClearSearch" />
          </template>

          <CargoTrackerDetails
            :serviceState="serviceState"
            :bolInformation="bolInformation"
            :portsAndDates="portsAndDates"
            portsAndDatesTooltip="Timestamps dates are displayed in 24-hour format and show the local port time."
            :portContacts="portContacts"
            :vesselVoyageData="vesselVoyageData"
          />
        </ServiceCard>
      </div>
    </div>
  </G2oPublicLayout>
</template>
