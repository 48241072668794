<script setup name="OrganisationShipmentPortContactsTrigger">
import BaseItem from '~/components/BaseItem.vue';
import Icon from '~/components/Icon.vue';

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);
</script>

<template>
  <button
    class="group flex w-full items-center gap-2 px-6 py-4 outline-none hover:text-primary-8"
    type="button"
    :class="{
      'border-b border-b-charcoal-2': open,
      'hover:bg-charcoal-1': !open,
    }"
    @click="emit('click')"
  >
    <Icon
      icon="mdi:chevron-right"
      class="text-primary-6"
      :class="{
        '-rotate-90': open,
        'rotate-90': !open,
      }"
    />
    <div
      class="ml-3 flex flex-col space-y-2 text-left sm:grid sm:grid-cols-[150px_minmax(0,_1fr)] sm:space-y-0"
    >
      <BaseItem
        v-for="(item, index) in items"
        v-bind="item"
        :key="index"
        inherit-color
        :class="{
          'text-primary-8': open,
          'text-secondary-9 group-hover:text-primary-8': !open,
        }"
      />
    </div>
  </button>
</template>
