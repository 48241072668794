<script setup lang="ts" name="NotifyBellButton">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import Icon from '~/components/Icon.vue';
import Tooltip from '~/components/Tooltip.vue';

const props = defineProps<{
  modelValue: boolean;
  dataTest?: string;
  description: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
}>();

const inputDataTest = computed(() => props.dataTest || 'checbox-input');
const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <Tooltip :description="description" placement="bottom">
    <template #trigger="{ show, hide, setRef }">
      <label
        :ref="setRef"
        class="cursor-pointer"
        :data-test="inputDataTest"
        @mouseover="show"
        @mouseleave="hide"
      >
        <input
          class="hidden"
          v-model="inputValue"
          type="checkbox"
          :value="inputValue"
          :data-test="`${inputDataTest}-field`"
        />
        <div
          class="flex items-center rounded-full p-2 text-ocean-8 hover:bg-ocean-0"
        >
          <Icon :icon="inputValue ? 'mdi:bell' : 'mdi:bell-outline'" />
        </div>
      </label>
    </template>
  </Tooltip>
</template>
