import type { RouteRecordRaw } from 'vue-router';

import { makePrivateRoute } from '~/features/useRouteMeta';

import ArchiveNotificationsIndex from '~/pages/ArchiveNotificationsIndex.vue';

export const notificationsRoutes: Readonly<RouteRecordRaw[]> = [
  makePrivateRoute({
    name: 'ArchiveNotificationsIndex',
    path: '/notifications',
    component: ArchiveNotificationsIndex,
  }),
];
