<script setup lang="ts" name="CarbonOffsetButton">
import { useShowModal } from '~/features/useModalsProvider';

import CarbonOffsetingProcessModal from '~/components/CarbonOffsetingProcessModal.vue';

const showModal = useShowModal();

const onOpenCarbonModal = () => {
  showModal(CarbonOffsetingProcessModal, {});
};
</script>

<template>
  <button
    type="button"
    class="flex w-full cursor-pointer items-center text-xs text-primary-8 hover:text-primary-6 focus-visible:text-primary-8"
    @click="onOpenCarbonModal"
  >
    NEED HELP?
  </button>
</template>
