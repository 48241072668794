<script lang="ts" setup name="SailingScheduleTradeLaneSettingsVessel">
import { useVModel } from '@vueuse/core';

import BaseCheckbox from '~/components/BaseCheckbox.vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    label?: string;
    showDragHandle?: boolean;
  }>(),
  {
    showDragHandle: false,
    label: '',
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
}>();

const visible = useVModel(props, 'modelValue', emit);

const blurOnChange = (checkbox: HTMLInputElement) => {
  if (checkbox) {
    checkbox.blur();
  }
};
</script>

<template>
  <BaseCheckbox
    v-model="visible"
    class="group rounded border border-charcoal-2 bg-white py-4 pl-4 drop-shadow hover:drop-shadow-2"
    :handle-change="blurOnChange"
  >
    <div class="flex items-center">
      <p>{{ label }}</p>
      <div
        v-show="showDragHandle"
        class="drag-handle ml-auto mr-4 flex items-center gap-2 self-center"
      >
        <div class="flex h-full flex-col justify-center gap-1">
          <div class="h-1 w-7 rounded-full bg-primary-8" />
          <div class="h-1 w-7 rounded-full bg-primary-8" />
        </div>
        <p class="hidden text-xs text-primary-8 group-hover:block">DRAG</p>
      </div>
    </div>
  </BaseCheckbox>
</template>
