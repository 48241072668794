import type { RouteRecordRaw } from 'vue-router';

import { DEV_FEATURE, ORG_FEATURES, PERMISSION } from '~/constants';

import { makeKeyBuilder, makeOrganisationRoute } from '~/features/useRouteMeta';

const OrganisationBookingsIndex = () =>
  import('~/pages/OrganisationBookingsIndex.vue');
const OrganisationBookingsOverview = () =>
  import('~/pages/OrganisationBookingsOverview.vue');
const OrganisationBookingsDeclaration = () =>
  import('~/pages/OrganisationBookingsDeclaration.vue');
const OrganisationBookingsNomination = () =>
  import('~/pages/OrganisationBookingsNomination.vue');
const OrganisationBookingsDeclarationNew = () =>
  import('~/pages/OrganisationBookingsDeclarationNew.vue');
const OrganisationBookingsHistory = () =>
  import('~/pages/OrganisationBookingsHistory.vue');
const OrganisationBookingsDeclarationVeson = () =>
  import('~/pages/OrganisationBookingsDeclarationVeson.vue');
const OrganisationBookingsNominationNew = () =>
  import('~/pages/OrganisationBookingsNominationNew.vue');

export const organisationBookingsRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    path: '/cargo-bookings',
    component: OrganisationBookingsIndex,
    keyBuilder: makeKeyBuilder('OrganisationBookingsIndex'),
    devFeature: DEV_FEATURE.CARGO_NOMINATION,
    orgFeature: ORG_FEATURES.BOOKINGS, // temporary check until bookings get released to all organisations
    permission: PERMISSION.BOOKINGS_VIEW_OWN,
    children: [
      {
        name: 'OrganisationBookingsIndex',
        path: '',
        redirect: { name: 'OrganisationBookingsOverview' },
      },
      {
        name: 'OrganisationBookingsOverview',
        path: 'overview',
        component: OrganisationBookingsOverview,
        props: true,
      },
      {
        name: 'OrganisationBookingsDeclaration',
        path: 'declaration',
        component: OrganisationBookingsDeclaration,
        props: true,
      },
      {
        name: 'OrganisationBookingsNomination',
        path: 'nomination',
        component: OrganisationBookingsNomination,
        props: true,
      },
    ],
  }),
  makeOrganisationRoute({
    name: 'OrganisationBookingsDeclarationNew',
    path: '/cargo-bookings/declaration/new',
    component: OrganisationBookingsDeclarationNew,
    devFeature: DEV_FEATURE.CARGO_NOMINATION,
    orgFeature: ORG_FEATURES.BOOKINGS,
    permission: PERMISSION.BOOKINGS_CREATE_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationBookingsNominationNew',
    path: '/cargo-bookings/nomination/new',
    component: OrganisationBookingsNominationNew,
    devFeature: DEV_FEATURE.CARGO_NOMINATION_NOMINATE,
    orgFeature: ORG_FEATURES.BOOKINGS,
    permission: PERMISSION.BOOKINGS_UPDATE_ANY,
  }),
  makeOrganisationRoute({
    name: 'OrganisationBookingsHistory',
    path: '/cargo-bookings/history',
    component: OrganisationBookingsHistory,
    devFeature: DEV_FEATURE.CARGO_NOMINATION,
    orgFeature: ORG_FEATURES.BOOKINGS,
    permission: PERMISSION.BOOKINGS_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationBookingsDeclarationVeson',
    path: '/cargo-bookings/declaration/:bookingId/transfer-to-veson',
    component: OrganisationBookingsDeclarationVeson,
    orgFeature: ORG_FEATURES.BOOKINGS,
    permission: PERMISSION.BOOKINGS_VIEW_OWN,
    props: (route) => ({
      bookingId: route.params.bookingId as string,
    }),
  }),
];
