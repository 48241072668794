<script setup lang="ts" name="OrganisationShipmentsVoyageRow">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import { SHIPMENTS_STATUS } from '~/constants';
import { useOrganisationsStore } from '~/store';

import { Item, useBaseItems } from '~/features/useBaseItem';
import { getVoyageTooltipText } from '~/features/useShipmentNotifications';

import BaseItem from '~/components/BaseItem.vue';
import BaseTag from '~/components/BaseTag.vue';
import Icon from '~/components/Icon.vue';
import NotifyBellButton from '~/components/NotifyBellButton.vue';
import OrganisationShipmentsVoyageRowTotals from '~/components/OrganisationShipmentsVoyageRowTotals.vue';
import ShipmentsCountIcon from '~/components/ShipmentsCountIcon.vue';

import type { BaseTagContrastType } from '~/types';
import type { IShipment, IShipmentStatus } from '~/types/shipments';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    isOpen: boolean;
    totals: {
      unit: string;
      quantity: number;
    }[];
    shipments: IShipment[];
    dateString: string;
    status: IShipmentStatus;
    tagVariant: BaseTagContrastType;
    tradeLane: string;
    vesselName: string;
    vesselVoyageNumber: number;
    homeView?: boolean;
  }>(),
  {
    totals: () => [],
    shipments: () => [],
  },
);

const emit = defineEmits<{
  (event: 'click'): void;
  (e: 'update:modelValue'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const SHIPMENT_TYPE_TO_TEXT = {
  ongoing: 'Arrival Discharge Port',
  upcoming: 'Est. Arrival Load Port',
  completed: 'Arrived Discharge Port',
};

const isUpcoming = computed(() => props.status === SHIPMENTS_STATUS.UPCOMING);

const portNames = computed(() => {
  const ports = isUpcoming.value
    ? props.shipments.map((shipment) => shipment.loadingPort.name)
    : props.shipments.map((shipment) => shipment.dischargePort.name);
  return [...new Set(ports)];
});

const vesselPorts = computed(() => {
  if (portNames.value.length <= 2) return portNames.value.join(', ');

  const [first, second] = portNames.value;
  return `${first}, ${second} (+${portNames.value.length - 2})`;
});

const portName = computed(() => {
  const port = isUpcoming.value ? 'Load Port' : 'Discharge Port';
  return `${port}${portNames.value.length > 1 ? 's' : ''}`;
});

const shipmentsCount = computed(() => props.shipments.length);

const voyageInformation = computed(() =>
  useBaseItems(
    Item.label(portName.value).value(vesselPorts.value),
    Item.label(SHIPMENT_TYPE_TO_TEXT[props.status]).value(props.dateString),
  ),
);
</script>

<template>
  <button
    type="button"
    class="group col-span-full grid grid-cols-subgrid border-b bg-white"
    :class="{
      'rounded-t border-charcoal-2 !text-primary-8': isOpen,
      'rounded border-transparent hover:drop-shadow-2': !isOpen,
    }"
    @click="emit('click')"
  >
    <div
      class="col-span-full grid grid-cols-subgrid items-center p-4 text-left sm:p-6"
    >
      <div class="col-span-full grid grid-cols-subgrid">
        <div
          class="col-span-2 flex items-center self-end truncate"
          :class="homeView ? 'xl:col-auto' : 'lg:col-auto'"
        >
          <Icon
            icon="mdi:chevron-right"
            class="mr-4 hidden text-primary-6"
            :class="{
              '-rotate-90': isOpen,
              'rotate-90': !isOpen,
              'xl:block': homeView,
              'lg:block': !homeView,
            }"
          />
          <div class="truncate">
            <BaseTag :variant="tagVariant" :name="tradeLane" />

            <p v-title class="truncate font-bold group-hover:text-primary-8">
              {{ vesselName }} {{ vesselVoyageNumber }}
            </p>
          </div>
        </div>

        <OrganisationShipmentsVoyageRowTotals
          :totals="totals"
          :is-open="isOpen"
          :homeView="homeView"
        />

        <div
          v-for="voyage in voyageInformation"
          v-bind="voyage"
          :key="voyage.id"
          :data-testId="voyage.id"
          class="col-span-2 self-end group-hover:text-primary-8"
          :class="homeView ? 'xl:col-span-1 xl:px-2' : 'lg:col-span-1 lg:px-2'"
        >
          <BaseItem v-bind="voyage" :inherit-color="isOpen" />
        </div>
        <div
          class="z-30 col-start-5 row-span-2 row-start-1 ml-auto flex items-center"
        >
          <div
            v-if="useOrganisationsStore().canReceiveShipmentsNotifications"
            @click.stop
          >
            <NotifyBellButton
              v-model="inputValue"
              :description="getVoyageTooltipText(inputValue)"
            />
          </div>
          <div class="hidden lg:block">
            <ShipmentsCountIcon
              class="ml-1"
              data-testid="shipments-count"
              :isOpen="isOpen"
              :value="shipmentsCount"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-span-full flex items-center border-t border-charcoal-2 p-4 sm:hidden"
    >
      <p class="whitespace-nowrap text-subtitle-3 uppercase text-primary-6">
        See shipment details ({{ shipmentsCount }})
      </p>

      <Icon
        icon="mdi:chevron-right"
        class="ml-auto text-primary-6"
        :class="{
          '-rotate-90': isOpen,
          'rotate-90': !isOpen,
        }"
      />
    </div>
  </button>
</template>
