<script lang="ts" setup name="SailingScheduleTradeLaneVoyagesLoading">
import VoyageTracker from '~/components/VoyageTracker.vue';

const PORTS_LENGTH = 6;
const PORT_DATES_LENGTH = 5;
</script>

<template>
  <div class="rounded-b-md">
    <div class="flex w-full flex-col">
      <div
        class="animate grid h-16 animate-pulse grid-cols-[224px_minmax(0,_1fr)]"
      >
        <div class="right-shadow border-r border-charcoal-2 bg-charcoal-2" />
        <div class="bg-charcoal-2" />
      </div>

      <div class="w-full rounded-b bg-white">
        <div
          v-for="i in PORTS_LENGTH"
          :key="i"
          class="grid h-14 w-full grid-cols-[224px_minmax(0,_1fr)] border-r border-charcoal-2"
        >
          <div class="right-shadow px-4">
            <VoyageTracker
              loading
              :hideTopBorder="i === 1"
              :hideBottomBorder="i === PORTS_LENGTH"
            />
          </div>

          <div class="flex w-full rounded-b-lg bg-white">
            <div
              v-for="x in PORT_DATES_LENGTH"
              :key="x"
              class="animate flex w-full animate-pulse items-center justify-evenly"
            >
              <div class="h-3 w-24 rounded-lg bg-charcoal-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
