<script lang="ts" setup name="PublicVesselsShow">
import { computed, ref } from 'vue';

import { useRouteBack } from '~/features/useRouteBack';
import { useService } from '~/features/useService.js';

import Service from '~/services/Service.js';

import VesselsShow from '~/components/VesselsShow.vue';

import type { IVesselsShow, IVesselsShowResponse } from '~/types/vessels';

const { name, id } = defineProps<{
  name: string;
  id: string | number;
}>();

const page = computed(() => ({
  title: name,
  backButton: {
    label: 'Vessels',
    to: useRouteBack({
      // TODO: Update to correct index route
      name: 'PublicVesselsIndex',
    }),
  },
}));

const vessel = ref<IVesselsShow | null>(null);

const serviceState = useService(
  Service.publicVessels().show(id),
  ({ data }: IVesselsShowResponse) => {
    vessel.value = data;
    return true;
  },
);
</script>

<template>
  <VesselsShow :page="page" :serviceState="serviceState" :vessel="vessel" />
</template>
