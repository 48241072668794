<script setup lang="ts" name="BaseOverlay">
import { useVModel } from '@vueuse/core';

import BaseButton from '~/components/BaseButton.vue';
import Icon from '~/components/Icon.vue';

const props = defineProps<{
  modelValue: boolean;
  backTo?: string;
  closable?: boolean;
  screen?: string;
  screenName?: string;
  fullscreen?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'back'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <div>
    <Transition name="fade">
      <div
        v-if="inputValue"
        class="fixed inset-0 z-50 bg-black bg-opacity-75"
      ></div>
    </Transition>

    <Transition name="slide-up">
      <div
        v-if="inputValue"
        data-testid="overlay"
        class="fixed bottom-0 left-0 z-50 flex w-full flex-col bg-white p-4 pt-6"
        :class="{
          'h-full': fullscreen,
          'rounded-t-md': !fullscreen,
        }"
        :key="screen ?? ''"
      >
        <div class="flex flex-col">
          <div v-if="closable" class="flex justify-end">
            <BaseButton
              data-testid="overlay-close"
              variant="btn-tertiary"
              icon="ic:round-close"
              @click="inputValue = false"
            />
          </div>

          <button
            v-if="backTo"
            type="button"
            data-testid="overlay-back"
            class="mx-4 mb-6 inline-flex items-center rounded pr-1 text-xs font-bold uppercase tracking-widest text-primary-8"
            @click="emit('back')"
          >
            <Icon icon="mdi:arrow-left-thin" />
            <span class="ml-2">{{ backTo }}</span>
          </button>
        </div>

        <div data-testid="overlay-content">
          <p v-if="screenName" class="mb-5 text-subtitle-2">{{ screenName }}</p>

          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
}
</style>
