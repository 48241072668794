import type { RouteRecordRaw } from 'vue-router';

import PublicBillOfLadingSearch from '~/pages/PublicBillOfLadingSearch.vue';
import PublicCookiePolicy from '~/pages/PublicCookiePolicy.vue';
import PublicSharedShipment from '~/pages/PublicSharedShipment.vue';

export const publicRoutes: Readonly<RouteRecordRaw[]> = [
  {
    name: 'PublicCookiePolicy',
    path: '/cookie-policy',
    component: PublicCookiePolicy,
  },
  {
    name: 'PublicSharedShipment',
    path: '/shipment/:id',
    component: PublicSharedShipment,
    props: (route) => ({
      id: route.params.id as string,
    }),
  },
  {
    name: 'PublicBillOfLadingSearch',
    path: '/cargo-tracking',
    component: PublicBillOfLadingSearch,
  },
];
