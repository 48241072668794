<script setup lang="ts" name="SailingScheduleVoyageCollapsible">
import { computed, ref } from 'vue';

import {
  getSailingSchedulePreviewPorts,
  getTradeDeskEmail,
} from '~/features/useSailingSchedule';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import InfoTooltip from '~/components/InfoTooltip.vue';
import SailingScheduleCard from '~/components/SailingScheduleCard.vue';
import SailingScheduleCardContent from '~/components/SailingScheduleCardContent.vue';
import SailingScheduleCardFooter from '~/components/SailingScheduleCardFooter.vue';
import SailingScheduleContentPort from '~/components/SailingScheduleContentPort.vue';
import SailingScheduleTradeLaneActions from '~/components/SailingScheduleTradeLaneActions.vue';
import SailingScheduleTradeLaneShipSVG from '~/components/SailingScheduleTradeLaneShipSVG.vue';
import SailingScheduleVoyageDescription from '~/components/SailingScheduleVoyageDescription.vue';
import SailingScheduleVoyageScheduleView from '~/components/SailingScheduleVoyageScheduleView.vue';

import type { BaseTagContrastType, SailingScheduleVoyages } from '~/types';

const { voyage } = defineProps<{
  voyage: SailingScheduleVoyages;
  color: string;
  tagVariant: BaseTagContrastType;
}>();

const fullScheduleView = ref(false);

const voyagePorts = computed(() =>
  getSailingSchedulePreviewPorts(voyage.ports, fullScheduleView.value),
);

const hasSelectedPorts = computed(
  () => !voyage.ports.every((port) => port.visible),
);

const toggleFullScheduleView = () => {
  fullScheduleView.value = !fullScheduleView.value;
};
</script>

<template>
  <div class="w-full rounded bg-white drop-shadow">
    <BaseCollapsible>
      <template #trigger="{ toggle, isOpen }">
        <SailingScheduleCard :isOpen="isOpen" @click="toggle">
          <template #heading>
            <SailingScheduleVoyageDescription
              :vvn="voyage.vvn"
              :vessel="voyage.vessel"
              :tagVariant="tagVariant"
              :tradeLane="voyage.tradelane"
            />
          </template>

          <template #actions>
            <SailingScheduleTradeLaneActions v-if="isOpen" idle>
              <InfoTooltip
                title="Trade Desk email"
                :description="getTradeDeskEmail(voyage.tradeEmail)"
              />
            </SailingScheduleTradeLaneActions>
          </template>

          <SailingScheduleCardContent :isOpen="isOpen">
            <SailingScheduleContentPort
              label="Departure"
              :port="voyage.loadingPort"
              :date="voyage.loadingDate"
            />

            <SailingScheduleTradeLaneShipSVG
              class="hidden xs:block"
              :color="color"
            />

            <SailingScheduleContentPort
              label="Arrival"
              :port="voyage.dischargePort"
              :date="voyage.dischargeDate"
            />
          </SailingScheduleCardContent>

          <SailingScheduleCardFooter :isOpen="isOpen" />
        </SailingScheduleCard>
      </template>

      <div class="grid grid-cols-2 border-t border-charcoal-2">
        <SailingScheduleVoyageScheduleView
          :voyagePorts="voyagePorts"
          :hasSelectedPorts="hasSelectedPorts"
          :fullScheduleView="fullScheduleView"
          @toggleFullScheduleView="toggleFullScheduleView"
        />
      </div>
    </BaseCollapsible>
  </div>
</template>
