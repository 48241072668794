import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

const base = `${baseEndpoint}/api/customer/organisation/1/charts/waiting_for_berth`;

export default [
  http.get(base, async () => {
    return HttpResponse.json({
      data: {
        widgets: [
          {
            type: 'total_waiting_days',
            value: 64,
          },
          {
            type: 'avg_waiting_days',
            value: 53,
          },
          {
            type: 'current_waiting_days',
            vessels: ['Medi Egeo 202201', 'Medi asd 202202'],
            value: 42, // days
          },
        ],
        totalWaitingDaysByPort: {
          series: {
            '': {
              'Port of Tangaa': {
                value: 0,
              },
              Santos: {
                value: 4,
              },
            },
          },
          categories: ['Port of Tangaa', 'Santos'],
        },
        avgWaitingDaysByPort: {
          series: {
            '': {
              Santos: {
                value: 3.5,
              },
              Napoli: {
                value: 2.8,
              },
              Vancouver: {
                value: 2.5,
              },
              Xiamen: {
                value: 2.4,
              },
              'Port of Tanga': {
                value: 1,
              },
            },
          },
          categories: [
            'Santos',
            'Napoli',
            'Vancouver',
            'Xiamen',
            'Port of Tanga',
          ],
        },
        avgWaitingDays: {
          categories: [2020, 2021, 2022, 2023],
          series: {
            '': {
              2020: {
                value: 0.1,
              },
              2021: {
                value: 100,
              },
              2022: {
                value: 1,
              },
              2023: {
                value: null,
              },
            },
          },
        },
      },
    });
  }),
] satisfies HttpHandler[];
