import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT as string;

export const homeWidgets =
  `${baseEndpoint}/api/customer/organisation/1/home/statistics` as const;

export default [
  http.get(homeWidgets, async () => {
    return HttpResponse.json({
      data: [
        {
          name: 'total_laycan_performance',
          quarter: 'Q3, 2022',
          value: 75,
          hasData: true,
        },
      ],
    });
  }),
] satisfies HttpHandler[];
