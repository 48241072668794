import type { InjectionKey, Ref } from 'vue';
import { computed, provide, ref } from 'vue';

import { useStrictInject } from '~/features/useStrictInject';

const STATES = {
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  NO_DATA: 'NO_DATA',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  NO_RESULTS: 'NO_RESULTS',
} as const;

type State = (typeof STATES)[keyof typeof STATES];

export const provideBaseCardState = (injectKey = 'DEFAULT_CARD_STATE') => {
  const state = ref<State>(STATES.LOADING);

  const setState = (value: State) => {
    state.value = value;
  };

  const setStateLoading = () => {
    setState(STATES.LOADING);
  };

  const setStateIdle = () => {
    setState(STATES.IDLE);
  };

  const setStateError = () => {
    setState(STATES.ERROR);
  };

  const setStateNoData = () => {
    setState(STATES.NO_DATA);
  };

  const setStateNotAuthorized = () => {
    setState(STATES.NOT_AUTHORIZED);
  };

  const setStateNoResults = () => {
    setState(STATES.NO_RESULTS);
  };

  provide(injectKey, state);

  return {
    setStateLoading,
    setStateIdle,
    setStateError,
    setStateNoData,
    setStateNoResults,
    setStateNotAuthorized,
    stateIsIdle: computed(() => state.value === STATES.IDLE),
    stateIsLoading: computed(() => state.value === STATES.LOADING),
    stateIsNoData: computed(() => state.value === STATES.NO_DATA),
    stateIsNoResults: computed(() => state.value === STATES.NO_RESULTS),
    stateIsError: computed(() => state.value === STATES.ERROR),
    stateHasErrors: computed(
      () =>
        state.value === STATES.ERROR ||
        state.value === STATES.NOT_AUTHORIZED ||
        state.value === STATES.NO_DATA,
    ),
  };
};

export const injectBaseCardState = (injectKey = 'DEFAULT_CARD_STATE') => {
  const key = injectKey as unknown as InjectionKey<Ref<State>>;
  const state = useStrictInject(key);

  return {
    stateIsError: computed(() => state.value === STATES.ERROR),
    stateIsIdle: computed(() => state.value === STATES.IDLE),
    stateIsLoading: computed(() => state.value === STATES.LOADING),
    stateIsNoData: computed(() => state.value === STATES.NO_DATA),
    stateIsNoResults: computed(() => state.value === STATES.NO_RESULTS),
    stateIsNotAuthorized: computed(() => state.value === STATES.NOT_AUTHORIZED),
  };
};
