import type { RouteRecordRaw } from 'vue-router';

import { PERMISSION } from '~/constants';

import { makeOrganisationRoute } from '~/features/useRouteMeta';

const OrganisationKpisIndex = () => import('~/pages/OrganisationKpisIndex.vue');
const OrganisationKpisInvoices = () =>
  import('~/pages/OrganisationKpisInvoices.vue');
const OrganisationKpisPerShipment = () =>
  import('~/pages/OrganisationKpisPerShipment.vue');
const OrganisationKpisShippedVolume = () =>
  import('~/pages/OrganisationKpisShippedVolume.vue');
const OrganisationKpisTransitTimes = () =>
  import('~/pages/OrganisationKpisTransitTimes.vue');
const OrganisationSettingsKpisInvoicesEdit = () =>
  import('~/pages/OrganisationSettingsKpisInvoicesEdit.vue');
const OrganisationSettingsKpisInvoicesIndex = () =>
  import('~/pages/OrganisationSettingsKpisInvoicesIndex.vue');
const OrganisationSettingsKpisInvoicesNew = () =>
  import('~/pages/OrganisationSettingsKpisInvoicesNew.vue');
const OrganisationSettingsKpisShippedVolumesEdit = () =>
  import('~/pages/OrganisationSettingsKpisShippedVolumesEdit.vue');
const OrganisationSettingsKpisShippedVolumesIndex = () =>
  import('~/pages/OrganisationSettingsKpisShippedVolumesIndex.vue');
const OrganisationSettingsKpisShippedVolumesNew = () =>
  import('~/pages/OrganisationSettingsKpisShippedVolumesNew.vue');
const OrganisationSettingsKpisShippedVolumesView = () =>
  import('~/pages/OrganisationSettingsKpisShippedVolumesView.vue');
const OrganisationSettingsKpisTransitTimesEdit = () =>
  import('~/pages/OrganisationSettingsKpisTransitTimesEdit.vue');
const OrganisationSettingsKpisTransitTimesIndex = () =>
  import('~/pages/OrganisationSettingsKpisTransitTimesIndex.vue');
const OrganisationSettingsKpisTransitTimesNew = () =>
  import('~/pages/OrganisationSettingsKpisTransitTimesNew.vue');
const OrganisationSettingsKpisTransitTimesView = () =>
  import('~/pages/OrganisationSettingsKpisTransitTimesView.vue');

export const organisationKpisRoutes: Readonly<RouteRecordRaw[]> = [
  makeOrganisationRoute({
    name: 'OrganisationKpisIndex',
    path: '/kpis',
    component: OrganisationKpisIndex,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationKpisShippedVolume',
    path: '/kpis/shipped-volume',
    component: OrganisationKpisShippedVolume,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationKpisPerShipment',
    path: '/kpis/per-shipment',
    component: OrganisationKpisPerShipment,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationKpisTransitTimes',
    path: '/kpis/transit-times',
    component: OrganisationKpisTransitTimes,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationKpisInvoices',
    path: '/kpis/invoices',
    component: OrganisationKpisInvoices,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  // KPI SETTINGS
  // Invoices
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisInvoicesIndex',
    path: '/settings/kpis/invoices',
    component: OrganisationSettingsKpisInvoicesIndex,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisInvoicesNew',
    path: '/settings/kpis/invoices/new',
    component: OrganisationSettingsKpisInvoicesNew,
    permission: PERMISSION.KPI_CREATE_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisInvoicesEdit',
    path: '/settings/kpis/invoices/:id/edit',
    component: OrganisationSettingsKpisInvoicesEdit,
    permission: PERMISSION.KPI_UPDATE_OWN,
    props: (route) => ({
      id: route.params.id as string,
    }),
  }),
  // Shipped Volumes
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisShippedVolumesIndex',
    path: '/settings/kpis/shipped-volumes',
    component: OrganisationSettingsKpisShippedVolumesIndex,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisShippedVolumesNew',
    path: '/settings/kpis/shipped-volumes/new',
    component: OrganisationSettingsKpisShippedVolumesNew,
    permission: PERMISSION.KPI_CREATE_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisShippedVolumesView',
    path: '/settings/kpis/shipped-volumes/:id',
    component: OrganisationSettingsKpisShippedVolumesView,
    permission: PERMISSION.KPI_VIEW_OWN,
    props: (route) => ({
      id: route.params.id as string,
    }),
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisShippedVolumesEdit',
    path: '/settings/kpis/shipped-volumes/:id/edit',
    component: OrganisationSettingsKpisShippedVolumesEdit,
    permission: PERMISSION.KPI_UPDATE_OWN,
    props: (route) => ({
      id: route.params.id as string,
    }),
  }),
  // Transit Times
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisTransitTimesIndex',
    path: '/settings/kpis/transit-times',
    component: OrganisationSettingsKpisTransitTimesIndex,
    permission: PERMISSION.KPI_VIEW_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisTransitTimesNew',
    path: '/settings/kpis/transit-times/new',
    component: OrganisationSettingsKpisTransitTimesNew,
    permission: PERMISSION.KPI_CREATE_OWN,
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisTransitTimesView',
    path: '/settings/kpis/transit-times/:id/contract/:contract',
    component: OrganisationSettingsKpisTransitTimesView,
    permission: PERMISSION.KPI_VIEW_OWN,
    props: (route) => ({
      id: route.params.id as string,
      contract: route.params.contract as string,
    }),
  }),
  makeOrganisationRoute({
    name: 'OrganisationSettingsKpisTransitTimesEdit',
    path: '/settings/kpis/transit-times/:id/edit',
    component: OrganisationSettingsKpisTransitTimesEdit,
    permission: PERMISSION.KPI_VIEW_OWN,
    props: (route) => ({
      id: route.params.id as string,
    }),
  }),
];
