import { http, type HttpHandler, HttpResponse } from 'msw';

const baseEndpoint = import.meta.env.VITE_API_ENDPOINT;

export const viewUser = `${baseEndpoint}/api/admin/users/1`;
const userPath = `${baseEndpoint}/api/admin/user-requests/1`;

export default [
  http.get(userPath, async () => {
    return HttpResponse.json({
      data: {
        id: 'd0e1eb4c-d553-4241-9cc4-52624a4ab84b',
        first_name: 'Public',
        last_name: 'Stage',
        email: 'public@stage.us',
        organisations: ['User'],
        createdAt: '21 Jan 2023',
        type: 'Cargo owner',
        trades: [
          {
            id: 'ENA-ESA',
            name: 'ENA-ESA',
          },
          {
            id: 'ESA-ENA',
            name: 'ESA-ENA',
          },
          {
            id: 'ESA-EU',
            name: 'ESA-EU',
          },
          {
            id: 'ESA-MED',
            name: 'ESA-MED',
          },
        ],
      },
    });
  }),
  http.get(viewUser, async () => {
    return HttpResponse.json({
      data: {
        internal: false,
        first_name: 'John',
        last_name: 'Doe',
        email: 'johndoe@email.com',
        role: { id: '1', name: 'role' },
        organisations: [],
      },
    });
  }),
] satisfies HttpHandler[];
