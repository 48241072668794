<script setup lang="ts" name="PublicSailingScheduleTradeModal">
import { computed, watch } from 'vue';

import { usePublicSailingScheduleAddTrade } from '~/features/usePublicSailingScheduleAddTrade';
import { useService } from '~/features/useService';

import Service from '~/services/Service';

import BaseModal from '~/components/BaseModal.vue';
import BaseModalActions from '~/components/BaseModalActions.vue';
import PublicSailingScheduleAddTradeDropdown from '~/components/PublicSailingScheduleAddTradeDropdown.vue';
import PublicSailingScheduleTradesList from '~/components/PublicSailingScheduleTradesList.vue';

import type { ITradeLane, PromiseResolvePayload } from '~/types';

const { closeModal } = defineProps<{
  closeModal: (
    data: PromiseResolvePayload<'CLOSE'> | PromiseResolvePayload<'UPDATE'>,
  ) => void;
}>();

const emit = defineEmits<{
  (e: 'actionChange', action: any): void;
}>();

const {
  search,
  initialTrades,
  searchActive,
  isTradelanesEmpty,
  searchTradesStatusText,
  selectedTradelanes,
  filteredTradeOptions,
  hasReachedMaximumTrades,
  hasAllSameTrades,

  setTradelanes,
  setSearchStatus,
  setInitialTradelanes,
  setSelectedTradelanes,
} = usePublicSailingScheduleAddTrade();

const modalActionState = computed(() => {
  if (hasAllSameTrades.value) {
    return { action: 'CLOSE' };
  }

  return { action: 'UPDATE' };
});

const onClose = () => {
  closeModal(modalActionState.value);
};

const selectedTradesServiceState = useService(
  Service.publicSailingSchedule().customize().index(),
  ({ data }: { data: ITradeLane[] }) => {
    setInitialTradelanes(data);
    setSelectedTradelanes(data);
    return true;
  },
);

watch(
  () => modalActionState.value,
  (currState) => {
    emit('actionChange', currState);
  },
);
</script>

<template>
  <BaseModal
    title="Add Trades to The Sailing Schedule"
    max-width="500px"
    @close="onClose"
  >
    <PublicSailingScheduleAddTradeDropdown
      v-model="search"
      :searchActive="searchActive"
      :trades-empty="isTradelanesEmpty"
      :filteredTradeOptions="filteredTradeOptions"
      :searchTradesStatusText="searchTradesStatusText"
      :hasReachedMaximumTrades="hasReachedMaximumTrades"
      :selectedTradesServiceState="selectedTradesServiceState"
      @setTradelanes="setTradelanes"
      @setSearchStatus="setSearchStatus"
      @setSelectedTradelanes="setSelectedTradelanes"
    />

    <PublicSailingScheduleTradesList
      class="overflow-y-auto"
      :selectedTradelanes="selectedTradelanes"
      :loading="selectedTradesServiceState.stateIsLoading"
      @setSearchStatus="setSearchStatus"
      @setSelectedTradelanes="setSelectedTradelanes"
    />

    <template #actions>
      <BaseModalActions submit="Add" @submit="onClose" />
    </template>
  </BaseModal>
</template>
