<script lang="ts" setup name="ModalCloseButton">
import Icon from '~/components/Icon.vue';

const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>

<template>
  <button
    data-test="close-modal"
    class="group flex h-8 w-8 items-center justify-center rounded-full hover:bg-charcoal-1"
    @click="emit('close')"
  >
    <Icon
      icon="ic:round-close"
      class="text-charcoal-5 group-hover:text-primary-6"
    />
  </button>
</template>
