import { onBeforeUnmount, ref } from 'vue';
import mitt from 'mitt';

import type { IEvent } from '~/types';

const emit = mitt();

export const useEvents = () => {
  const listeners = ref<
    {
      event: IEvent;
      callback: () => void;
    }[]
  >([]);

  const fire = (event: IEvent, payload?: unknown) => {
    emit.emit(event, payload);
  };

  const listen = (event: IEvent, callback: () => void) => {
    listeners.value.push({ event, callback });
    emit.on(event, callback);
  };

  onBeforeUnmount(() => {
    listeners.value.forEach(({ event, callback }) => {
      emit.off(event, callback);
    });
  });

  return {
    fire,
    listen,
  };
};
