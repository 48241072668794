<script setup lang="ts" name="ArchiveNotificationsIndex">
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useUnreadNotificationsStore } from '~/store';

import { getOrgRoute } from '~/features/useRouteHelpers';
import { useService } from '~/features/useService';

import Service from '~/services/Service.js';

import BaseAutoPagination from '~/components/BaseAutoPagination.vue';
import BaseButton from '~/components/BaseButton.vue';
import BasePage from '~/components/BasePage.vue';
import NotificationsRows from '~/components/NotificationsRows.vue';
import ServiceCard from '~/components/ServiceCard.vue';

const router = useRouter();

const page = computed(() => ({
  title: 'Notifications',
  backButton: {
    label: 'back',
    to:
      router.options.history.state.back || getOrgRoute('OrganisationHomeIndex'),
  },
}));

const notifications = ref([]);

const serviceState = useService(
  Service.notifications().index(),
  ({ data }: any) => {
    notifications.value = data;

    return true;
  },
  {
    cancelOnNewRequest: true,
    paginated: true,
  },
);

const markAllAsRead = () => {
  Service.notifications()
    .markAllAsRead()
    .onStart(() => {
      serviceState.setStateLoading();
    })
    .onSuccess(() => {
      serviceState.fetchDataDebounced();
    })
    .execute();
};

onMounted(() => {
  useUnreadNotificationsStore().reset();
});
</script>

<template>
  <BasePage v-bind="page">
    <div class="col-span-full w-auto space-y-2 lg:w-[895px]">
      <div class="flex w-full justify-end rounded-t-md bg-white py-1 shadow">
        <BaseButton variant="btn-tertiary" @click="markAllAsRead">
          mark all as read
        </BaseButton>
      </div>

      <ServiceCard v-bind="serviceState">
        <NotificationsRows :notifications="notifications" large />
      </ServiceCard>

      <div class="mt-2 flex justify-end rounded-b bg-white p-4 drop-shadow">
        <BaseAutoPagination />
      </div>
    </div>
  </BasePage>
</template>
