<script setup lang="ts" name="SailingScheduleTradeLaneDraggable">
import Draggable from 'vuedraggable';
import { useVModel } from '@vueuse/core';

import SailingScheduleTradeLaneSettingsVessel from '~/components/SailingScheduleTradeLaneSettingsVessel.vue';

import type { IVesselOption } from '~/types';

const props = defineProps<{
  modelValue: IVesselOption[];
  search: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: IVesselOption[]): () => void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <Draggable
    v-model="inputValue"
    group="vessels"
    class="mt-4 space-y-2 overflow-y-auto px-6"
    item-key="value"
    ghost-class="vessel-drag-ghost"
    :disabled="disabled"
  >
    <template #item="{ element }">
      <SailingScheduleTradeLaneSettingsVessel
        v-model="element.visible"
        :key="element.value"
        :label="element.label"
        :show-drag-handle="!search"
      />
    </template>
    <template #footer>
      <div class="mt-4" />
    </template>
  </Draggable>
</template>
