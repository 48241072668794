<script setup lang="ts" name="PublicUserProfile">
import { RouterView } from 'vue-router';

import { useProfileStore } from '~/store';

import BasePage from '~/components/BasePage.vue';
import ProfileHeader from '~/components/ProfileHeader.vue';
import RouterTabs from '~/components/RouterTabs.vue';

import type { IRouterTabLink } from '~/types';

const profileStore = useProfileStore();

const tabs: IRouterTabLink[] = [
  {
    icon: 'mdi:account-box',
    label: 'My details',
    to: {
      name: 'PublicUserMyDetails',
    },
  },
  {
    icon: 'ic:baseline-lock',
    label: 'Password',
    to: {
      name: 'PublicUserChangePassword',
    },
  },
];
</script>

<template>
  <BasePage>
    <template #header>
      <div class="col-span-full">
        <ProfileHeader :fullName="profileStore.fullName" :profileRole="null" />
      </div>
    </template>

    <div class="col-span-full space-y-2">
      <RouterTabs :tabs="tabs" />
      <RouterView />
    </div>
  </BasePage>
</template>
