<script setup lang="ts" name="OrganisationHomeWidgetsSettingsExpandedRadioList">
import { useVModel } from '@vueuse/core';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import BaseRadio from '~/components/BaseRadio.vue';
import BaseRadioGroup from '~/components/BaseRadioGroup.vue';
import Icon from '~/components/Icon.vue';

import type { ICustomizationWidgetTransitTime } from '~/types';

const props = defineProps<{
  modelValue: string;
  label: string;
  tradeLanes: ICustomizationWidgetTransitTime;
  isDialogOpen?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): () => void;
}>();

const NONE = 'None';

const tradeLane = useVModel(props, 'modelValue', emit);
</script>

<template>
  <BaseCollapsible :closeOnLeave="!isDialogOpen">
    <template #trigger="{ toggle, isOpen }">
      <button
        type="button"
        class="flex w-full items-center gap-x-2 px-7 py-3"
        :class="{ 'hover:bg-charcoal-1': !isOpen }"
        @click="toggle"
      >
        <Icon
          icon="mdi:chevron-right"
          class="text-primary-8"
          :class="{
            '-rotate-90': isOpen,
            'rotate-90': !isOpen,
          }"
        />
        <p class="text-secondary-9">
          {{ label }}
        </p>
      </button>
    </template>
    <div class="space-y-3 pl-[60px] pr-7">
      <BaseRadio
        v-model="tradeLane"
        name="trade"
        nativeValue="None"
        :label="NONE"
      />
      <div class="max-h-60 overflow-y-auto">
        <BaseRadioGroup>
          <BaseRadio
            v-for="(_trade, key) in tradeLanes"
            :key="key"
            v-model="tradeLane"
            name="trade"
            :label="key"
            :nativeValue="key"
          >
          </BaseRadio>
        </BaseRadioGroup>
      </div>
    </div>
  </BaseCollapsible>
</template>
