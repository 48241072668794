import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { useOrganisationsStore, useProfileStore } from '~/store';

import LayoutAuthenticated from '~/components/LayoutAuthenticated.vue';
import LayoutPublic from '~/components/LayoutPublic.vue';
import LayoutPublicUser from '~/components/LayoutPublicUser.vue';

import type {
  IFallbackRouteParams,
  IPrivateRouteMeta,
  IPrivateRouteMetaParams,
  IPrivateRouteRawProps,
  OrganisationRouteParams,
} from '~/types';

export const makeKeyBuilder =
  (key: string) => (route: RouteLocationNormalized) => {
    const params = Object.values(route.params).join('-');
    return `${key}-${params}`;
  };

const getPrivateRouteMeta = ({
  permission,
  orgFeature,
  devFeature,
  keyBuilder,
  layout = LayoutAuthenticated,
}: IPrivateRouteMetaParams): IPrivateRouteMeta => ({
  permission,
  devFeature,
  keyBuilder,
  layout,
  feature: orgFeature,
  requiresAuth: true,
  fail: '/404',
});

export const makePublicRoute = ({
  name,
  path,
  props,
  component,
  beforeEnter,
  devFeature,
}: OrganisationRouteParams): RouteRecordRaw => ({
  name,
  component,
  path,
  beforeEnter,
  props,
  meta: {
    layout: LayoutPublic,
    devFeature,
    fail: '/404',
    requiresAuth: false,
  },
});

export const makePrivateRoute = ({
  name,
  path,
  props,
  children,
  component,
  beforeEnter,
  // Meta params
  permission,
  orgFeature,
  devFeature,
  keyBuilder,
  layout,
}: OrganisationRouteParams): RouteRecordRaw => ({
  name,
  path,
  children,
  component,
  beforeEnter,
  props,
  meta: getPrivateRouteMeta({
    permission,
    orgFeature,
    devFeature,
    keyBuilder,
    layout,
  }),
});

export const makeOrganisationRoute = ({
  name,
  path,
  props,
  children,
  component,
  beforeEnter,
  // Meta params
  permission,
  orgFeature,
  devFeature,
  keyBuilder,
  layout,
}: OrganisationRouteParams): RouteRecordRaw =>
  makePrivateRoute({
    name,
    children,
    component,
    beforeEnter,
    permission,
    orgFeature,
    devFeature,
    keyBuilder,
    layout,
    path: `/organisation/:organisationId${path}`,
    props: (route: RouteLocationNormalized): IPrivateRouteRawProps => ({
      organisationId: route.params.organisationId as string,
      organisation: useOrganisationsStore().getById(
        route.params.organisationId as string,
      ),
      ...(props?.(route) ?? {}),
    }),
  });

const getFallbackRouteMeta = () => {
  const profileStore = useProfileStore();

  if (!profileStore.isAuthenticated) {
    return {};
  }

  const meta = {
    layout: profileStore.isPublic ? LayoutPublicUser : LayoutAuthenticated,
  };

  return meta;
};

export const makeFallbackRoute = ({
  name,
  path,
  component,
}: IFallbackRouteParams): RouteRecordRaw => ({
  name,
  path,
  component,
  beforeEnter: (to, _, next) => {
    to.meta = {
      ...to.meta,
      ...getFallbackRouteMeta(),
    };

    next();
  },
});
